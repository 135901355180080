import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import {
    SelectChangeEvent
} from '@mui/material';
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ValidResponseType {
    success: boolean,
    statusCode: number,
    message: string,
    data: CampaignData,
    errors: Array<string>,
    error: {
        code: string,
        message: string,
        details: string
    }
}
export interface InvalidResponseType {
    errors: Array<string>;
}

export interface CampaignData {
    campaigns: Array<Campaigns>   
}
export interface Campaigns {
    id: string,
    name: string,
    account_id: string,
    created_at: string,
    updated_at: string,
    workspace_id: string
}

export interface CampaignWorkspace {
    success: boolean,
    statusCode: number,
    message: string,
    data: WorkspaceList,
    errors: Array<string>,
    error: {
        code: string,
        message: string,
        details: string
    }
}

export interface CampaignsList {
    id: string,
    name: string,
    created_at: string
}

export interface WorkspaceList {
    workspace: {
        id: string,
        name: string,
        account_id: number,
        campaigns: Array<CampaignsList>,
        communitys: Array<object>,
        employees: Array<object>
    }
}

export interface WorkspaceType {
    success: boolean,
    statusCode: number,
    message: string,
    data: Workspace;
    errors: Array<string>;
    error: {
        code: string,
        message: string,
        details: string
    }
}

export interface Workspace {
    workspaces: [ WorkspaceList ]   
}

export interface MoveCampaignType {
    success: boolean,
    statusCode: number,
    message: string,
    data: MoveCampaign;
    errors: Array<string>;
    error: {
        code: string,
        message: string,
        details: string
    }
}

export interface MoveCampaign {
    campaign: Campaigns 
}

export interface CampaignsList {
    data : Array<CampaignsData>;
}

export interface CampaignsData {
    id: string,
    type: string,
    attributes: {
        id: string,
        name: string,
        created_at: string,
        updated_at: string,
    },
    relationships: {
        account: {
            data: {
                id: string,
                type: string
            }
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    campaignData: Array<Campaigns>;
    addCampaignData: Array<CampaignsData>;
    openMenuIndex: number | boolean;
    openRemoveModal: boolean;
    openMoveModal: boolean;
    deleteFileName: string;
    sortCampaign: string;
    searchCampaign: string;
    moveWorkspaceId: string;
    isLoading: boolean;
    openRemoveSuccessModal: boolean;
    openMoveSuccessModal: boolean;
    openWorkspaceName: boolean;
    openAddCampaign: boolean;
    campaignId: string;
    campaignName: string;
    workspaceId: string;
    workspaceName: string;
    workspaceData: Array<WorkspaceList>;
    selectedIds: Array<string>;
    openCampaignsAdded: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CampaignWorkspaceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    authToken: string = "";
    userId: string = "";
    workspaceId: string = "";
    workspaceName: string = "";
    getCampaignWorkspaceApiCallId: string = "";
    removeCampaignApiCallId: string = "";
    getWorkspacesApiCallId: string = "";
    moveCampaignApiCallId: string = "";
    getCampaignsApiCallId: string = "";
    addCampaignsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            campaignData: [],
            addCampaignData: [],
            openMenuIndex: false,
            openRemoveModal: false,
            openMoveModal: false,
            deleteFileName: "",
            sortCampaign: "",
            searchCampaign: "",
            moveWorkspaceId: "",
            isLoading: false,
            openRemoveSuccessModal: false,
            openMoveSuccessModal: false,
            openWorkspaceName: false,
            openAddCampaign: false,
            campaignId: "",
            campaignName: "",
            workspaceId: "",
            workspaceName: "",
            workspaceData: [],
            selectedIds: [],
            openCampaignsAdded: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
         if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
             let apiRequestCallId = message.getData(
                 getName(MessageEnum.RestAPIResponceDataMessage)
             );
             const responseJson = message.getData(
                 getName(MessageEnum.RestAPIResponceSuccessMessage)
             );
             if (this.isValidCampaignResponseController(responseJson)) {
                 this.apiSuccessCallBacksController(apiRequestCallId, responseJson );
             }
             if (this.isInValidCampaignResponseController(responseJson)) {
                 this.apiFailureCallBacksController(apiRequestCallId, responseJson );
             }
         }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
         this.userId = await getStorageData('userId');
         this.authToken = await getStorageData('authToken');
         this.workspaceName = await getStorageData('workName');
         this.workspaceId = await getStorageData('workId');
         this.handleGetWorkspaces();
         if(this.workspaceId){
             this.setState({workspaceId: this.workspaceId, workspaceName: this.workspaceName })
             this.handleGetCampaignData();
         }
     }

    campaignWorkspaceApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
      }) => {
          const token = this.authToken;
          let { contentType, endPoint, method, body } = valueData;
          let header = {
              "Content-Type" : contentType,
               token,
           };
          let requestMessage = new Message(
             getName(MessageEnum.RestAPIRequestMessage)
          );
           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               endPoint
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
                method
           );
            body &&
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    body
                );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return requestMessage.messageId;
        };

    isValidCampaignResponseController = (responseJson: ValidResponseType) => {
         return responseJson && !responseJson.errors;
     };

    isInValidCampaignResponseController = (responseJson: InvalidResponseType) => {
         return responseJson && responseJson.errors;
     };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: ValidResponseType & CampaignWorkspace & WorkspaceType & MoveCampaignType & CampaignsList) => {
        if (apiRequestCallId === this.getCampaignWorkspaceApiCallId) {
            this.getCampaignDataSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.removeCampaignApiCallId) {
            this.removeCampaignSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getWorkspacesApiCallId) {
            this.getWorkspacesSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.moveCampaignApiCallId) {
            this.moveCampaignSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getCampaignsApiCallId) {
            this.getCampaignsSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.addCampaignsApiCallId) {
            this.addCampaignsSuccessCallBack(responseJson)
        }
    };

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getCampaignWorkspaceApiCallId) {
            this.getCampaignDataFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.removeCampaignApiCallId) {
            this.removeCampaignFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.getWorkspacesApiCallId) {
            this.getWorkspacesFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.moveCampaignApiCallId) {
            this.moveCampaignFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.getCampaignsApiCallId) {
            this.getCampaignsFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.addCampaignsApiCallId) {
            this.addCampaignsFailurCallBack(responseJson)
        }
    };

    handleCampaignMenu = (index: number) => {
        if (typeof this.state.openMenuIndex === "number") {
            this.setState({ openMenuIndex: false });
        } else {
            this.setState({ openMenuIndex: index });
        }
    };

    openRemoveCampaign = ( campaign : Campaigns) => {
        this.setState({ openRemoveModal: true, campaignId: campaign.id, campaignName: campaign.name});
    };

    openMoveCampaign = ( campaign : Campaigns) => {
        this.setState({ openMoveModal: true, campaignId: campaign.id, campaignName: campaign.name });
    };

    closeRemoveCampaign = () => {
        this.setState({ openRemoveModal: false, openMenuIndex: false });
    };

    closeMoveCampaign = () => {
        this.setState({ openMoveModal: false, openMenuIndex: false });
    };

    closeSuccessRemove = () => {
        this.setState({ openRemoveSuccessModal: false });
    };

    closeSuccessMove = () => {
        this.setState({ openMoveSuccessModal: false });
    };

    handleCampaignSort = (event: SelectChangeEvent<string>) => {
        this.setState({ sortCampaign: event.target.value }, ()=>{
            this.handleGetCampaignData();
        })
    };

    handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchCampaign: event.target.value }, ()=>{
            this.handleGetCampaignData();
        })
    };

    handleCampaignMove = (event: SelectChangeEvent<string>) => {
        this.setState({ moveWorkspaceId: event.target.value})
    };

    openAddCampaigns = () => {
        this.setState({ openAddCampaign: true },()=>{
            this.handleGetCampaignsList();
        });
    };

    closeAddCampaigns = () => {
        this.setState({ openAddCampaign: false });
    };

    closeCampaignSuccess = () => {
        this.setState({ openCampaignsAdded: false });
    };

    goToWorkspaceName = () => {
        const contentResult = new Message(getName(MessageEnum.NavigationMessage));
        contentResult.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "WorkspaceName"
        );
        contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(contentResult);
    };

    handleGetCampaignData = async () => {
        const { searchCampaign, sortCampaign, workspaceId } = this.state;
        this.setState({ isLoading : true})
        this.getCampaignWorkspaceApiCallId = await this.campaignWorkspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${workspaceId}/list_campaigns?query=${searchCampaign}&sort_by=${sortCampaign}`,
        });
    };

    getCampaignDataSuccessCallBack = (responseJson: ValidResponseType) => {
        const workspaces = responseJson.data.campaigns
        if (workspaces) {
        this.setState({ 
            campaignData : workspaces,
            isLoading: false
        })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
            this.setState({ isLoading: false })
        }
    }

    getCampaignDataFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleRemoveCampaignData = async () => {
        const { campaignId, workspaceId } = this.state;
        let apiBody = {
            campaign_id: campaignId
        }

        this.removeCampaignApiCallId = await this.campaignWorkspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${workspaceId}/remove_campaign`,
            body: JSON.stringify(apiBody)
        });
    };

    removeCampaignSuccessCallBack = (responseJson: CampaignWorkspace) => {
        if (responseJson.data.workspace) {
            this.setState({ openRemoveSuccessModal: true, openRemoveModal: false, openMenuIndex: false },()=>{
                this.handleGetCampaignData();
            })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
    }

    removeCampaignFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0]);
        }
    }

    handleGetWorkspaces = async () => {
        this.getWorkspacesApiCallId = await this.campaignWorkspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces`,
        });
    };

    getWorkspacesSuccessCallBack = (responseJson: WorkspaceType) => {
        const workspaces = responseJson.data.workspaces
        if (workspaces) {
        this.setState({ 
            workspaceData : workspaces,
        })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
    }

    getWorkspacesFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
        }
    }

    handleMoveCampaignData = async () => {
        const { campaignId, moveWorkspaceId } = this.state;
        let apiBody = {
            campaign_id: campaignId,
            new_workspace_id: moveWorkspaceId
        }

        this.moveCampaignApiCallId = await this.campaignWorkspaceApiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.patchApiMethod,
          endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/move_campaign`,
          body: JSON.stringify(apiBody)
        });
      };
    
    moveCampaignSuccessCallBack = (responseJson: MoveCampaignType) => {
        if (responseJson.data.campaign) {
            this.setState({ openMoveSuccessModal: true , openMoveModal : false, openMenuIndex: false }, ()=>{
                this.handleGetCampaignData();
            })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
      }
    
    moveCampaignFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }

    handleGetCampaignsList = async () => {
        this.setState({ isLoading : true})
        this.getCampaignsApiCallId = await this.campaignWorkspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.campaignEndPoint}${this.userId}/campaigns`,
        });
    };

    getCampaignsSuccessCallBack = (responseJson: CampaignsList) => {
        if (responseJson.data) {
        this.setState({ addCampaignData : responseJson.data, isLoading: false})
        }
    }

    getCampaignsFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleCampaignsSelect = (campaign : CampaignsData ) => {
        const { selectedIds } = this.state;
        const campaignId = campaign.attributes.id
        if (selectedIds.includes(campaignId)) {
          this.setState({
            selectedIds: selectedIds.filter(selectedId => selectedId !== campaignId)
          });
        } else {
          this.setState({
            selectedIds: [...selectedIds, campaignId]
          });
        }
    }
    
    handleCampaignsId = ( campaign : CampaignsData ) =>{
        const { selectedIds } = this.state;
        return selectedIds.includes(campaign.attributes.id)
    }

    addCampaignsToWorkspace = async () => {
        const { selectedIds, workspaceId } = this.state;
        let apiBody = {
          campaign_ids : selectedIds
        }

        this.addCampaignsApiCallId = await this.campaignWorkspaceApiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.patchApiMethod,
          endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${workspaceId}/add_campaign`,
          body: JSON.stringify(apiBody)
        });
      };
    
    addCampaignsSuccessCallBack = (responseJson:  CampaignWorkspace) => {
        if (responseJson.data.workspace) {
            this.setState({ openCampaignsAdded: true, openAddCampaign: false },()=>{
                this.handleGetCampaignData();
            })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
      }
    
    addCampaignsFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }
    // Customizable Area End
}