// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import Footerbar from "../../../components/src/Footerbar";
import { styled , createTheme, ThemeProvider,} from '@mui/material/styles';
import { policy, same } from './assets';
import { BackToMainPageButton, StyledContainerWithLeftMargin, StyledContainerWithScrollBar, } from "../../../components/src/StyledComponents";
const themes = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
            fontSize: '24px',
            color: '#4D4D4D',
        },
        subtitle1: {
            margin: "20px 0px",
        },
        body1: {
            fontSize: '18px',
            color: '#4D4D4D',
        },
    },
});

import PrivacyPolicyController, {
    Props,
    configJSON
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>

<ThemeProvider theme={themes}>
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                <StyledContainer>
                    <img src={policy} className="bannerImage" alt="banner" />
                </StyledContainer>
            </Box>

            <Box sx={webStyles.policyListMain}>
                <Box>
                    <Typography sx={webStyles.policyTxt}>
                        {configJSON.policyTxt}
                    </Typography>
                </Box>
                
                <Box sx={webStyles.policyBox}>
                    <Box sx={{ ...webStyles, flex: 1 }}>
                        <Typography sx={webStyles.rightTitle}>
                            {configJSON.IntroductionTitle}
                        </Typography>
                        <Typography sx={webStyles.policyDescOpen}>
                            {configJSON.contentCreationDesc}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={webStyles.policyBox}>
                    <Box sx={webStyles}>
                        <Typography sx={webStyles.rightTitle}>
                            {configJSON.UserResponsibilitiesTitle}
                        </Typography>
                        <Typography sx={webStyles.policyDesc}>
                            {configJSON.ToolDesc}
                        </Typography>
                        <Box component="ul" sx={{ paddingLeft: 2, listStyleType: 'disc' }}>
                            {[configJSON.listfirst, configJSON.listsecond, configJSON.listthird].map((item, index) => (
                                <Box component="li" key={index} sx={{ color: '#4D4D4D', marginBottom: 1 }}>
                                    <Typography sx={webStyles.policyDescOpen}>{item}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <img style={webStyles.policyImg} src={same} alt="Policy" />
                </Box>

                <Box sx={webStyles}>
                    <Typography sx={webStyles.rightTitle}>
                        {configJSON.yourrightsTitle}
                    </Typography>
                    <Typography sx={webStyles.policyDescOpen}>
                        {configJSON.contentCreationDesc}
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
        <Footerbar isActive={false} />
            </>
        );
    }
}
const StyledContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: '24px',
    "& .bannerImage": {
        marginLeft: '30px',
        marginRight: "30px"
    },
    "& .heading": {
        fontSize: "36px",
        fontFamily: "Open Sans",
        color: "#364AC6",
        '@media (max-width: 700px)': {
            fontSize: "25px",
        }
    },
    "& .content": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 10vw 0px 10vw"
    },
});
const webStyles = {
    policyListMain: {
        margin: { xs: '0 16px', sm: '0 32px', md: '0 100px', lg: '0 200px 32px 200px' },
    },
    policyTxt: {
        fontFamily: 'Open Sans',
        fontSize: { xs: '24px', sm: '28px', md: '36px' },
        fontWeight: 600,
        color: '#364AC6',
        margin: { xs: '24px 0', md: '48px 0' },
    },
    policyBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: "30px",
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },
    },
    rightTitle: {
        fontFamily: 'Open Sans',
        fontSize: { xs: '20px', md: '24px' },
        fontWeight: 600,
        color: '#4D4D4D',
        lineHeight: { xs: '28px', md: '32.68px' },
    },
    policyDesc: {
        fontFamily: 'Open Sans',
        fontSize: { xs: '16px', md: '18px' },
        fontWeight: 400,
        color: '#4D4D4D',
        marginTop: '36px',
        width: { xs: '100%', md: '50%' },
    },
    policyDescOpen: {
        width: '100%',
        marginTop: '36px',
        fontWeight: 400,
        color: '#4D4D4D',
        fontSize: { xs: '16px', md: '18px' },
        fontFamily: 'Open Sans',
        lineHeight: '24.51px',
    },
    policyImg: {
        width: '100%',
        marginTop: '24px',
    },
};

// Customizable Area End
