import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Modal,
  Paper
} from '@mui/material';
import { successIcon, backArrowIcon, facebookIcon, subcribeIcon, instaIcon, blogIcon, removeIcon, moveIcon, selectIcon } from './assets';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import CampaignWorkspaceController, { Props, configJSON } from './CampaignWorkspaceController.web';
import moment from "moment";
import Loader from '../../../components/src/Loader.web';
// Customizable Area End

export default class CampaignWorkspace extends CampaignWorkspaceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCampaignWorkspaceHeader = () => {
    const { searchCampaign, sortCampaign , campaignData} = this.state;
    return (
      <CampaignHeaderBox>
        <CampaignHeading>
          <CampaignHeadingBox>
             <img src={backArrowIcon} data-testId="campaignBackBtnTestId" onClick={this.goToWorkspaceName} className="Name_img" />
            <Typography className="pageHeading">{"Costa Coffee Campaigns"}</Typography>
            <Box className="HeadingTotal campaignBg">{campaignData.length}</Box>
          </CampaignHeadingBox>
          <Button className="newCampaignWorkspaceButton" data-testId="addCampaignBtnTestId" onClick={this.openAddCampaigns} endIcon={<AddIcon className='heading_icon' />}>
            {configJSON.addCampaign}
          </Button>
        </CampaignHeading>
        <CampaignSearchContainer>
          <SearchCampaignField
            placeholder="Search"
            fullWidth
            data-testId="searchCamapignTestID"
            onChange={this.handleCampaignSearch}
            value={searchCampaign}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <CampaignFormControl>
            <Typography className='sort_heading'>{configJSON.shortBy}</Typography>
            <CampaignWorkspaceSelect>
              <Select
                fullWidth
                displayEmpty
                IconComponent={KeyboardArrowDownTwoToneIcon}
                data-testId="sortCamapignTestID"
                onChange={this.handleCampaignSort}
                placeholder='Select'
                value={sortCampaign}
                className='campaign_sort_select'
                MenuProps= {{
                   PaperProps: {
                      sx:  {
                       backgroundColor: "#F0F0F0 !important",
                       "& .Mui-selected": {
                         backgroundColor: "#D9D9D9 !important",
                       }
                     },
                   },
                  }}
                renderValue={(selected: string) =>  {
                   if (selected === "") {
                     return <Box className="place_workspaces_text">{"Select"}</Box>;
                   }
                   return configJSON.sortKey[selected];
                 }}
               >
                <MenuItem className='sort_Item' value="name_asc" >{configJSON.nameAZ}</MenuItem>
                <MenuItem className='sort_Item' value="name_desc">{configJSON.nameZA}</MenuItem>
                <MenuItem className='sort_Item' value="created_at_asc">{configJSON.dateLH}</MenuItem>
                <MenuItem className='sort_Item' value="created_at_desc">{configJSON.dateHL}</MenuItem>
              </Select>
            </CampaignWorkspaceSelect>
          </CampaignFormControl>
        </CampaignSearchContainer>
      </CampaignHeaderBox>)
  };

  renderCampaignWorkspace = () => {
    const { campaignData, openMenuIndex } = this.state;
    return (
      <CampaignCardBox>
        <Box className="cardsBox" >
          {campaignData.map((item, index) => (
            <Box className="workspaceCard" key={index}
            >
              <Box className="campaigntitleBox">
                <Typography className='campaign_date'>{"Created on: " + moment(item.created_at).format("MMM DD")}</Typography>
                <MoreVertIcon
                  className={openMenuIndex === index ? "moreButtonActive" : "moreButton"}
                  data-testId="moreCampaignTestID"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCampaignMenu(index);
                  }}
                />
                {openMenuIndex === index && (
                  <Box className="moreCampaignMenu" >
                    <Button className="deleteBtn" data-testId="removeBtnTestID"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.openRemoveCampaign(item)
                      }}
                    >
                      <img src={removeIcon} alt=" " />
                      <Typography data-testId="removeBtnTextTestID">{configJSON.removeText}</Typography>
                    </Button>
                    <Button className="renameBtn" data-testId="moveBtnTestID"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.openMoveCampaign(item)
                      }} >
                      <img src={moveIcon} alt=" " />
                      <Typography data-testId="moveBtnTextTestID">{configJSON.moveText}</Typography>
                    </Button>
                  </Box>)}
              </Box>
              <CampaignMainBox>
                <Typography className='campaign_name'>{item.name}</Typography>
                <SocialIconBox>
                  <IconBox>
                    <img src={facebookIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </IconBox>
                  <IconBox>
                    <img src={subcribeIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </IconBox>
                  <IconBox>
                    <img src={instaIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </IconBox>
                  <IconBox>
                    <img src={blogIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </IconBox>
                </SocialIconBox>
              </CampaignMainBox>
            </Box>
          ))}
        </Box>
      </CampaignCardBox>
    )
  };

  renderRemoveCampaign = () => {
    const { openRemoveModal, campaignName, workspaceName } = this.state;
    return (
      <CampaignModalContainer
        open={openRemoveModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper">
          <IconButton
            onClick={this.closeRemoveCampaign}
            data-testId="removeCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" data-testId="removeCloseLabelTestID">
            {configJSON.removeCampaignText}
          </Typography>
          <Typography variant="body2">{configJSON.removeBodyText+campaignName+" from "+workspaceName+"?"}</Typography>
          <Box className="campaignButtonBox">
            <Button className="campaignCancelBtn" onClick={this.closeRemoveCampaign}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="campaignDeleteBtn" data-testId="removeCamapignBtnTestID" onClick={this.handleRemoveCampaignData}>{configJSON.yesRemoveText}</Button>
          </Box>
        </Paper>
      </CampaignModalContainer>
    )
  };

  renderMoveCampaign = () => {
    const { openMoveModal, moveWorkspaceId , workspaceName ,workspaceData} = this.state;
    return (
      <CampaignModalContainer
        open={openMoveModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper">
          <IconButton
            onClick={this.closeMoveCampaign}
            data-testId="moveCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" data-testId="moveCloseLabelTestID">
            {configJSON.moveText}
          </Typography>
          <MoveCampaignBox>
          <Box className="moveheading">{"Current location: "}<span className='move_workspace'>{workspaceName}</span></Box>
          <CampaignWorkspaceSelect>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownTwoToneIcon}
                data-testId="selectCamapignWSTestID"
                onChange={this.handleCampaignMove}
                placeholder='Select'
                value={moveWorkspaceId}
                className='campaign_sort_select move_select_width'
                displayEmpty
                renderValue={(selected: string) => {
                  if (selected === "") {
                    return <Box className="place_workspaces_text">{"Select"}</Box>;
                  }
                  const selectedItem = workspaceData.find(item => item.workspace.id === selected);
                  return selectedItem ? selectedItem.workspace.name : selected;
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#F0F0F0 !important",
                      "& .Mui-selected": {
                        backgroundColor: "#D9D9D9 !important",
                      }
                    },
                  },
                }}
              >
                {workspaceData.map((item)=>{
                return(
                  <MenuItem className='sort_Campaign_Item' value={item.workspace.id} key={item.workspace.id} >{item.workspace.name}</MenuItem>
                 )
                })}
               </Select>
             </CampaignWorkspaceSelect>
           </MoveCampaignBox>
           <Box className="campaignButtonBox" >
              <Button className="campaignCancelBtn" onClick={this.closeMoveCampaign}>{configJSON.cancelDeleteText}</Button>
             <Button className="campaignDeleteBtn" data-testId="moveCamapignBtnTestID" onClick={this.handleMoveCampaignData}>{configJSON.moveBtnText}</Button>
            </Box>
          </Paper>
        </CampaignModalContainer>
      )
    };

  renderSuccessRemoveCampaign = () => {
    const { openRemoveSuccessModal } = this.state;
    return (
      <CampaignModalContainer
        open={openRemoveSuccessModal}
        onClose={this.closeSuccessRemove}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_campaign_modal paper_success">
          <IconButton
            onClick={this.closeSuccessRemove}
            data-testId="removeSCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <CampaignHeadBox>
            <CampaignHeadTypo data-testId="removeSCloseLabelTestID">{configJSON.removeSuccessText}</CampaignHeadTypo>
            <img src={successIcon} className="succes_image" />
          </CampaignHeadBox>
        </Paper>
      </CampaignModalContainer>
    )
  };

  renderSuccessMoveCampaign = () => {
    const { openMoveSuccessModal } = this.state;
    return (
      <CampaignModalContainer
        open={openMoveSuccessModal}
        onClose={this.closeSuccessMove}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_campaign_modal paper_success">
          <IconButton
            onClick={this.closeSuccessMove}
            data-testId="moveSCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <CampaignHeadBox>
            <CampaignHeadTypo data-testId="moveSCloseLabelTestID">{configJSON.moveSuccessText}</CampaignHeadTypo>
            <img src={successIcon} className="succes_image" />
          </CampaignHeadBox>
        </Paper>
      </CampaignModalContainer>
    )
  };

  renderAddCampaigns = () => {
    const { openAddCampaign , addCampaignData} = this.state;
    return (
      <CampaignModalContainer
        open={openAddCampaign}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper paper_addCampaign">
          <IconButton
            onClick={this.closeAddCampaigns}
            data-testId="addCampaignCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <MoveCampaignBox>
          <Typography variant="body1" data-testId="closeCampaignsLabelTestID">
            {configJSON.addCampaignText}
          </Typography>
          <Box className="add_body_text">{configJSON.addCampaignBodyText}</Box>
          </MoveCampaignBox>
        <AddCampaignCardBox>
        <Box className="addCardsBox" >
          {addCampaignData.map((item, index) => (
            <Box className={this.handleCampaignsId(item) ? "addCampaignCard add_opacity" : "addCampaignCard"} key={index}
            data-testId="selectCampaignsBoxTestID"
            onClick={()=>this.handleCampaignsSelect(item)}
            >
              <Box className="addCampaigntitleBox">
                <Typography className='addCampaign_date'>{"Created on: " + moment(item.attributes.created_at).format("MMM DD")}</Typography>
                {this.handleCampaignsId(item) && <img src={selectIcon} className='addCampaign_img' alt="image" />}
              </Box>
              <AddCampaignMainBox>
                <Typography className='campaign_name'>{item.attributes.name}</Typography>
                <AddSocialIconBox>
                  <AddIconBox>
                    <img src={facebookIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddIconBox>
                  <AddIconBox>
                    <img src={subcribeIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddIconBox>
                  <AddIconBox>
                    <img src={instaIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddIconBox>
                  <AddIconBox>
                    <img src={blogIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddIconBox>
                </AddSocialIconBox>
              </AddCampaignMainBox>
            </Box>
          ))}
        </Box>
      </AddCampaignCardBox>
          <Box className="AddButtonBox">
            <Button className="AddCancelBtn" onClick={this.closeAddCampaigns}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="AddDeleteBtn" data-testId="addToWorkspaceTestID" onClick={this.addCampaignsToWorkspace} >{configJSON.addWorkspaceBtn}</Button>
          </Box>
        </Paper>
      </CampaignModalContainer>
    )
  };

  renderCampaignsAdded = () => {
    const { openCampaignsAdded } = this.state;
    return (
      <CampaignModalContainer
        open={openCampaignsAdded}
        onClose={this.closeCampaignSuccess}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_campaign_modal paper_success">
          <IconButton
            onClick={this.closeCampaignSuccess}
            data-testId="closeCampaignsAddedID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <CampaignHeadBox>
            <CampaignHeadTypo data-testId="closeCampaignsAddedLabelID">{configJSON.campaignsAddedText}</CampaignHeadTypo>
            <img src={successIcon} className="succes_image"/>
          </CampaignHeadBox>
        </Paper>
      </CampaignModalContainer>
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    return (
      <CampaignMainContainer>
        <Loader loading={isLoading}/>
        {this.renderCampaignWorkspaceHeader()}
        {this.renderAddCampaigns()}
        {this.renderCampaignWorkspace()}
        {this.renderRemoveCampaign()}
        {this.renderMoveCampaign()}
        {this.renderSuccessRemoveCampaign()}
        {this.renderSuccessMoveCampaign()}
        {this.renderCampaignsAdded()}
      </CampaignMainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CampaignMainContainer = styled(Box)({
  padding: "50px 24px",
  background: "#F6F6F6",
  minHeight: "100vh",
  '@media (max-width: 600px)': {
    padding: '15px',
  },
})

const CampaignHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const CampaignHeadingBox = styled(Box)({
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

const CampaignHeading = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "& .heading_icon": {
    fontSize: "16px"
  },
  "& .pageHeading": {
    fontSize: "28px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  },
  "& .HeadingTotal": {
    background: "#FFFDD8",
    color: "#4D4D4D",
    fontSize: "28px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center"
  },
  "& .Name_img": {
    width: "24px",
    hight: "24px"
  },
  "& .newCampaignWorkspaceButton": {
    cursor: "pointer",
    textTransform: 'none',
    color: "#FFFFFF",
    fontWeight: 600,
    ontSize: "16px",
    padding: '10px 40px',
    fontFamily: "Open Sans",
    borderRadius: "8px",
    background: '#000000',
    '@media (max-width: 600px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 400px)': {
      width: "100%"
    },
    "&:hover": {
      background: '#000000',
    },
  },
  '@media (max-width: 646px)': {
    gap: "12px",
    alignItems: "start",
    justifyContent: "space-between",
  },
  '@media (max-width: 400px)': {
    gap: "12px",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  '@media (max-width: 600px)': {
    justifyContent: "space-between",
    alignItems: "center",
  }
})

const CampaignSearchContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  width: "100%",
  '@media (max-width: 754px)': {
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
  },
  '@media (max-width: 500px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const SearchCampaignField = styled(TextField)({
   width: "calc(100% - 220px)",
   "& .Mui-focused": {
     "& fieldset": {
       border: "1px solid #DDDDDD !important",
       "&:hover": {
         border: "1px solid #DDDDDD !important"
       }
     }
   },
   "& fieldset": {
     border: "1px solid #DDDDDD !important"
   },
   "&:hover": {
     border: "none"
   },
   '& .MuiOutlinedInput-root': {
     fontFamily: "Open Sans",
     height: "48px",
     background: "white",
     '& fieldset': {
       border: "1px solid #DDDDDD",
       borderRadius: "8px",
     },
     '&.Mui-focused fieldset': {
       border: "1px solid #000000",
     },
     '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
     },
    },
     '&:hover fieldset': {
       borderColor: '#797979',
     },
    '& .MuiInputBase-input': {
     height: '100%',
     width: "100%"
   },
   '@media (max-width: 500px)': {
    width: "100%"
   },
   '@media (max-width: 854px)': {
     width: "calc(100% - 170px)"
   },
});

const CampaignWorkspaceSelect = styled(Box)({
   "& .campaign_sort_select": {
     width: '202px',
     height: "48px",
     background:"#FFFFFF",
     borderRadius: "8px",
     "&:hover": {
      border: "none"
     },
     "& fieldset": {
      border: "1px solid #DDDDDD !important"
     },
     "& .Mui-focused": {
       "& fieldset": {
         border: "1px solid #DDDDDD !important",
         "&:hover": {
           border: "1px solid #DDDDDD !important"
          }
       }
     },
     '& .place_workspaces_text': {
       fontFamily: 'Open Sans',
       fontSize: '14px',
       fontWeight: 400,
       color: '#B3B3B3',
     },
     '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
     },
     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
       borderColor: '#000000',
       borderSize: "1px",
     },
     '@media (max-width: 854px)': {
       width: 'clamp(150px, 10vw, 202px)'
     },
     '@media (max-width: 754px)': {
       width: '220px'
     },
     '@media (max-width: 600px)': {
       width: "150px"
     },
     '@media (max-width: 500px)': {
       width: "94vw"
     },
     '@media (max-width: 450px)': {
       width: "92vw"
     },
     '@media (max-width: 400px)': {
       width: "91vw"
     },
     "& .sort_Campaign_Item": {
       fontSize: "14px",
       fontWeight: 400,
       fontFamily: "Open Sans",
       color: "#4D4D4D"
     }
  },
  "& .move_select_width":{
    width: "100% !important",
    marginTop: "16px", 
  }
})

const CampaignFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: "column",
  gap: "4px",
  '& .MuiFormHelperText-root': {
    marginLeft: "0px",
    fontWight: 600
  },
  "& .sort_heading": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  }
});

const CampaignCardBox = styled(Box)({
  padding: "24px 16px",
  border: "1px solid #DDDDDDDD",
  background: "#FFFFFF",
  borderRadius: "8px",
  marginTop: "24px",
  "& .cardsBox": {
    width: "100%",
    gap: "24px",
    display: "flex",
    flexWrap: "wrap"
  },
  "& .workspaceCard": {
    background: "#FFFDD8",
    width: "260px",
    height: "218px",
    borderRadius: "8px",
    boxSizing: "border-box",
    border: "1px solid #DDDDDDDD",
    position: "relative",
     '@media (max-width: 580px)' : {
       width: "260px",
     },
    },
    "& .campaigntitleBox": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      padding: "24px 16px",
      boxSizing: "border-box",
     "& .MuiTypography-body1":{
       fontSize: "14px",
       color: "#4D4D4D",
       fontFamily: "Open Sans",
       fontWeight: 400,
       lineHeight: "21px",
     },
     "& .campaign_date":{
       color: "#4D4D4D",
       fontFamily: "Open Sans",
       fontWeight: 400,
       lineHeight: "16.34px",
       fontSize: "12px",
       padding: "2px 8px",
       borderRadius: "100px",
       background: "#FFFBAA"
     }
   },
  "& .moreButton": {
    cursor: "pointer"
  },
  "& .moreButtonActive": {
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: "50%"
  },
  "& .moreCampaignMenu": {
     position: "absolute",
     display: "flex",
     zIndex: 1,
     right: 16,
     top: 50,
     flexDirection: "column",
     justifyContent: "center",
     "& .MuiButton-root": {
       textTransform: "none",
       backgroundColor: "#fff",
       fontFamily: "Open Sans",
       lineHeight: "21px",
       color: "#4D4D4D",
       gap: "10px",
       fontWeight: 400,
       width: "116px",
       fontSize: "14px",
       boxSizing: "border-box",
       height: "36px",
       padding: "0px 16px",
       justifyContent: "flex-start",
       "& img": {
         height: "18px",
         width: "18px",
       }
     }
   },
   "& .deleteBtn": {
     borderTopLeftRadius: "8px",
     borderTopRightRadius: "8px",
     border: "1px solid #DDDDDD",
     borderBottomLeftRadius: "0px",
     borderBottomRightRadius: "0px",
   },
   "& .renameBtn": {
     borderBottomLeftRadius: "8px",
     borderBottomRightRadius: "8px",
     borderTopLeftRadius: "0px",
     borderTopRightRadius: "0px",
     borderTop: "none",
     border: "1px solid #DDDDDD",
   },
});

const AddCampaignCardBox = styled(Box)({
  "& .addCardsBox": {
    width: "100%",
    height: "350px",
    gap: "12px",
    display: "flex",
    flexWrap: "wrap",
    overflow: "scroll",
    scrollbarWidth: "0.5px !important"
    },
   "& .addCampaignCard": {
     height: "168px",
     background: "#FFFDD8",
     width: "200px",
     boxSizing: "border-box",
     borderRadius: "8px",
     position: "relative",
     border: "1px solid #DDDDDDDD",
     '@media (max-width: 580px)': {
       width: "260px",
     },
    },
    "& .add_opacity":{
       opacity : "50%"
     },
   "& .addCampaigntitleBox":{
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
     position: "relative",
     padding: "25px 8px",
     boxSizing: "border-box",
     height: "25px",
     width: "100%",
     "& .MuiTypography-body1": {
       color: "#4D4D4D",
       fontWeight: 400,
       fontFamily: "Open Sans",
       fontSize: "14px",
       lineHeight: "21px",
     }, 
     "& .addCampaign_date": {
       fontFamily: "Open Sans",
       color: "#4D4D4D",
       lineHeight: "16.34px",
       fontWeight: 400,
       padding: "2px 8px",
       fontSize: "12px",
       background: "#FFFBAA",
       borderRadius: "100px",
      },
      "& .addCampaign_img":{
        height: "32px",
        width: "32px",
        opacity: 1
      }
    },
 });

const CampaignModalContainer = styled(Modal)({
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   margin: "20px 0px",
   padding: "20px",
   "& .paper": {
     width: "fit-content",
     maxHeight: 'fit-content',
     flexDirection: "column",
     display: "flex",
     padding: "40px",
     alignItems: "center",
     gap: "20px 0px",
     borderRadius: "24px",
     boxSizing: "border-box",
     position: "relative",
     "& .MuiTypography-body1": {
       lineHeight: "27px",
       color: "#4D4D4D",
       fontSize: "20px",
       fontWeight: 600,
     },
     "& .MuiTypography-body2": {
       lineHeight: "21px",
       color: "#4D4D4D",
       fontSize: "16px",
       fontWeight: 400,
       textAlign: "center",
       maxWidth: "500px",
     },
     '@media (max-width: 375px)': {
       padding: "20px",
     },
    },
   "& .paper_success": {
     padding: "40px !important",
   },
   "& .paper_addCampaign":{
     width: "65% !important",
     padding: "30px 20px !important",
   },
   "& .paper_campaign_modal": {
     maxHeight: 'fit-content',
     width: "480px",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: "40px",
     borderRadius: "24px",
     gap: "20px 0px",
     position: "relative",
     boxSizing: "border-box",
     '@media (max-width: 675px)': {
       width: "420px"
     },
     '@media (max-width: 775px)': {
       width: "450px"
     },
     '@media (max-width: 475px)': {
       width: "350px"
     },
     '@media (max-width: 575px)': {
       width: "380px"
     },
     '@media (max-width: 375px)': {
       padding: "20px",
       width: "280px",
     },
   },
   "& .closeIconBtn": {
     color: "black",
     position: "absolute",
     right: "10px",
     top: "10px",
    },
    "& .campaignButtonBox": {
     display: "flex",
     padding: "20px 0px",
     gap: "20px",
     "& .MuiButton-root": {
       borderRadius: "8px",
       textTransform: "none",
       lineHeight: "24px",
       padding: "10px 16px",
       fontSize: "16px",
       fontWeight: 600,
       height: "48px",
       width: "212px",
       '@media (max-width: 575px)': {
         width: "180px !important"
       },
       '@media (max-width: 675px)': {
         width: "200px !important"
       },
       '@media (max-width: 375px)': {
         width: "100px !important",
         padding: "10px 5px"
       },
       '@media (max-width: 475px)': {
         width: "130px !important"
       },
     },
    "& .campaignCancelBtn": {
      color: "#000000",
      border: "1px solid #000000"
    },
    "& .campaignDeleteBtn": {
      color: "white",
      backgroundColor: "#000000"
    }
  },
  "& .AddButtonBox": {
    display: "flex",
    gap: "20px",
    width: "100%",
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "50%",
      height: "48px",
    },
    "& .AddCancelBtn": {
      color: "#000000",
      border: "1px solid #000000",
    },
    "& .AddDeleteBtn": {
      color: "white",
      backgroundColor: "#000000",
    }
  }
});

const CampaignHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign: "center",
  fontSize: '20px',
  color: '#4D4D4D',
});

const CampaignHeadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: '100%',
  flexDirection: "column",
  alignItems: "center",
  "& .succes_image": {
    width: "80px",
    height: "80px",
    marginTop: "35px"
  },
  "& .workspaceDeleteWarn": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '16px',
    color: '#4D4D4D',
    marginTop: "16px"
  }
});

const CampaignMainBox = styled(Box)({
  padding: "0 16px",
  "& .campaign_name": {
      color: "#000000",
      fontFamily: "Open Sans",
      fontWeight: 600,
      lineHeight: "32.68px",
      fontSize: "24px",
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
});

const SocialIconBox = styled(Box)({
   width: "calc(100% - 65px)",
   padding: "4px 16px",
   display: "flex",
   justifyContent: "space-between",
   background: "#FFFBAA",
   position: "absolute",
   bottom: "24px",
   borderRadius: "100px",
   height: "36px"
})

const IconBox = styled(Box)({
  display: 'flex',
  gap: "8px",
  alignItems: "center",
  "& .social_icon_img":{
    width: "16px",
    height: "16px"
  },
  "& .socialCount":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "16px",
    fontSize: "12px",
  }
});

const AddCampaignMainBox = styled(Box)({
  padding: "0 8px",
  "& .campaign_name": {
      color: "#000000",
      fontFamily: "Open Sans",
      fontWeight: 600,
      lineHeight: "28px",
      fontSize: "18px !important",
      padding: "0px 4px !important",
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
});

const AddSocialIconBox = styled(Box)({
   width: "calc(100% - 41px)",
   padding: "4px 12px",
   display: "flex",
   justifyContent: "space-between",
   background: "#FFFBAA",
   position: "absolute",
   bottom: "14px",
   borderRadius: "100px",
   height: "26px"
});

const AddIconBox = styled(Box)({
  display: 'flex',
  gap: "8px",
  alignItems: "center",
  "& .social_icon_img":{
    width: "14px",
    height: "14px"
  },
  "& .socialCount":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "16px",
    fontSize: "12px",
  }
});

const MoveCampaignBox = styled(Box)({
   width: "100%",
  "& .moveheading":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "21.79px",
    fontSize: "16px",
  },
  "& .move_workspace":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
  },
  "& .add_body_text":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "21px",
    fontSize: "14px",
    marginTop: "10px",
  }
});
// Customizable Area End 