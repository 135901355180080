import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
    SelectChangeEvent
  } from '@mui/material';
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export interface ValidResponseType {
    data: object;
    errors: Array<string>;
    error: string;
}
export interface InvalidResponseType {
    errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation : any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    contentData : Array<string>;
    imageVideoData : Array<string>;
    postContent : string;
    imageVideoText : string;
    nextPage : boolean;
    contentText : string;
    isMedia : boolean;
    isLoading : boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CampaignAddContentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postAddContentApiCallId: string = "";
    authToken: string = "";
    userId: string = "";
    campaignId: string = "";
    campaignName: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
        ];

        this.state = {
            contentData : ["Instagram Post", "Instagram Reel", "Instagram carousel"],
            imageVideoData : ["Yes, generate images and/or video", "No, I’m using my content"],
            postContent : "",
            imageVideoText : "",
            nextPage : false,
            contentText : "",
            isMedia: false,
            isLoading: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponseController(responseJson)) {
                this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
            }
            if (this.isInValidResponseController(responseJson)) {
                this.apiFailureCallBacksController(apiRequestCallId, responseJson);
            }
        }
        runEngine.debugLog("Message Recived", message);  
    }
    // Customizable Area End
    
    // Customizable Area Start

    async componentDidMount() {
        this.authToken = await getStorageData('authToken');
        this.userId = await getStorageData('userId');

        const url = window.location.href;
        const params = new URLSearchParams(new URL(url).search);
        const id = params.get('id');
        const name = params.get('name');
        this.campaignId = id || "";
        this.campaignName = name || "";
    }

    isValidResponseController = (responseJson: ValidResponseType) => {
        return responseJson && !responseJson.errors;
    };

    isInValidResponseController = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.postAddContentApiCallId) {
            this.postAddContentSuccessCallBack(responseJson)
        }
    };

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.postAddContentApiCallId) {
            this.postAddContentFailurCallBack(responseJson)
        }
    };
    handleNextPage = () =>{
        this.setState({ nextPage : true })
    }

    handleBackPage = () =>{
        this.setState({ nextPage : false })
    }

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
      }) => {
        let { contentType, method, endPoint, body } = valueData;
        const token = this.authToken;
        let header = {
          "Content-Type": contentType,
          token,
        };
        let requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
             body
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };

    handlePostContentData = (event: SelectChangeEvent<string>) => {
        this.setState({ postContent: event.target.value })
    }

    handleImageVideoData = (event: SelectChangeEvent<string>) => {
        const mediaAdd = event.target.value
        this.setState({ imageVideoText : event.target.value })
        if (mediaAdd === "Yes, generate images and/or video") {
            this.setState({ isMedia: true });
        } else if (mediaAdd === "No, I’m using my content") {
            this.setState({ isMedia: false });
        }
    }

    handleContentTextData = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ contentText: event.target.value })
    }

    handleNextAddContent = async () => {
        this.setState({isLoading: true})
        const postContent = this.state.postContent.split(" ");
        const firstWord = postContent[0].toLowerCase();
        const remainingPart = postContent.slice(1).join(" ").toLowerCase();

        let apiBody = {
            post: {
                campaign_id: this.campaignId,
                social_media_platform_name: firstWord,
                post_type: remainingPart,
                content: this.state.contentText,
                generate_media: this.state.isMedia
              }
        };
        this.postAddContentApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.exampleAPiMethod,
            endPoint: `${configJSON.postAddContentEndPoint}${this.userId}/posts`,
            body: JSON.stringify(apiBody),
        });
    };

    postAddContentSuccessCallBack = (responseJson : ValidResponseType ) =>{
        if(responseJson.data){
            toast.success("Content Created Successfully!")
            this.goToCampaignsResults()
            this.setState({isLoading: false})
        }
        if(responseJson.error){
            toast.error(responseJson.error)
            this.setState({isLoading: false})
        }
      }

    postAddContentFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({isLoading: false})
        }
      }

    goToCampaignsResults = () => {
        this.props.navigation.navigate("CampaignsResults", { id: this.campaignId, name: this.campaignName });
    }
    // Customizable Area End
}