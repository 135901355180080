Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "/account_block/accounts/";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.myWorkspaceEndPoint= "bx_block_workspaces/accounts/";
exports.getApiMethod="GET";
exports.postApiMethod="POST";
exports.deleteApiMethod="DELETE";
exports.patchApiMethod="PATCH";
exports.campaignEndPoint= "bx_block_campaigns/accounts/";

exports.myWorkspaces = "My Workspaces";
exports.NewWorkspace = "New Workspace";
exports.shortBy = "Sort by";
exports.nameAZ = "Name: (A - Z)";
exports.nameZA = "Name: (Z - A)";
exports.dateLH = "Date Created: Low > High";
exports.dateHL = "Date Created: High > Low";
exports.deleteText = "Delete";
exports.renameText = "Rename";
exports.renameWorkspaceText = "Rename Workspace";
exports.deleteWorkspaceText = "Delete Workspace";
exports.workspaceDeletefirst = "Are you sure you want to delete ";
exports.workspaceDeletesecond = "All associated assets, including campaigns, reports, and community managers, will be moved to the Default Workspace.";
exports.deleteSuccessText = "Workspace Successfully deleted";
exports.cancelDeleteText = "Cancel";
exports.yesDeleteText = "Yes, delete";
exports.saveText = "Save";
exports.cancelWorkspace = "Cancel";
exports.createWorkspace = "Create Workspace";
exports.inputWorkspace = "Workspacename";
exports.campaign = "Campaigns";
exports.communityManager = "Community managers";
exports.report = "Reports";
exports.team = "Team";
exports.campaignButton = "Select existing Campaigns";
exports.communityManagerButton = "Select existing Community Managers";
exports.reportButton = "Select existing Reports";
exports.teamButton = "Add Employees";
exports.createWorkspaceText = "Workspace created";
exports.addEmployeeText = "Add Employee";
exports.addEmployeeNext = "Fill in all the fields to add a new employee.";
exports.sendInvitation = "Send invitation";
exports.employeeAddText= "Employee added";
exports.campaignsAddedText= "Campaigns added";
exports.seeAllText = "See All";
exports.addCampaign = "Add campaign";
exports.removeText = "Remove";
exports.moveText = "Move to";
exports.removeCampaignText = "Remove from workspace";
exports.removeBodyText = "Are you sure do you want to remove ";
exports.yesRemoveText = "Yes, remove";
exports.removeSuccessText = "Campaign Successfully Removed";
exports.moveBtnText = "Move";
exports.moveSuccessText = "Campaign Successfully Moved";
exports.addCampaignText = "Add Campaigns";
exports.addCampaignBodyText = "You can select more than one campaign at a time.";
exports.addWorkspaceBtn = "Add to the workspace";


exports.sortKey = {
  name_asc: "Name: (A - Z)",
  name_desc: "Name: (Z - A)",
  created_at_asc: "Date Created: Low > High",
  created_at_desc: "Date Created: High > Low",
}
// Customizable Area End
