// Customizable Area Start

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { ChangeEvent } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  onClose:()=>void
  goToLoginForm:()=>void
  open:boolean
}

export interface  SendOtpResponse{
  data:string | undefined,
  error:string | undefined
}

export interface  VerifyOtpResponse{
  data: {
      message: string,
      token: string
  },
  error:string
}
interface S {
  emailError:boolean;
  email:string;
  emailErrorMessage:string;
  showEmailForm:boolean;
  showOtpForm:boolean;
  showNewPasswordForm:boolean;
  showSuccessResetForm:boolean;
  oneTimePassword:string;
  timeLeft:number;
  newPassword:string;
  confirmNewPassword:string;
  isValidPassword:boolean;
  newPasswordError:boolean;
  confirmNewPasswordError:boolean;
  confirmNewPasswordErrorMessage:string
  verifyOtpError:string,
  otpVerifiedToken:string,
}

interface SS {
  navigation: any;
}


export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  emailOtpCApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  setNewPasswordApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      emailError:false,
      email:"",
      emailErrorMessage:"",
      showEmailForm:true,
      showOtpForm:false,
      showNewPasswordForm:false,
      showSuccessResetForm:false,
      oneTimePassword:"",
      timeLeft:30,
      newPassword:"",
      confirmNewPassword:"",
      isValidPassword:false,
      newPasswordError:false,
      confirmNewPasswordError:false,
      confirmNewPasswordErrorMessage:"",
      verifyOtpError:"",
      otpVerifiedToken:"",
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,){
    if (this.props.open !== prevProps.open) {
     this.setState({ 
        emailError:false,
        email:"",
        emailErrorMessage:"",
        showEmailForm:true,
        showOtpForm:false,
        showNewPasswordForm:false,
        showSuccessResetForm:false,
        oneTimePassword:"",
        timeLeft:30,
        newPassword:"",
        confirmNewPassword:"",
        isValidPassword:false,
        newPasswordError:false,
        confirmNewPasswordError:false,
        confirmNewPasswordErrorMessage:"",
        verifyOtpError:"",
        otpVerifiedToken:"",
      })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.emailOtpCApiCallId){
        this.handleSendOtpOnEmailResponse(responseJson)
      }else if(apiRequestCallId === this.verifyOtpApiCallId){
        this.handleVerifyOtpResponse(responseJson)
      }else if(apiRequestCallId === this.setNewPasswordApiCallId){
        this.handleSetNewPasswordResponse()
      }
    }
  }

  handleChangeEmail(event:ChangeEvent<HTMLInputElement>){
    this.setState({email:event.target.value})
  }
  handleChangeNewPassword(event:ChangeEvent<HTMLInputElement>){
    this.setState({newPassword:event.target.value})
  }
  handleChangeConfirmNewPassword(event:ChangeEvent<HTMLInputElement>){
    this.setState({confirmNewPassword:event.target.value})
  }
  
  onValidatePassword(isValid:boolean){
    this.setState({isValidPassword:isValid})
  }

  timerRef: ReturnType<typeof setInterval> | null = null;

  startTimer = () => {
    this.timerRef = setInterval(() => {
      if (this.state.timeLeft <= 1) {
        if (this.timerRef){
          clearInterval(this.timerRef);
        }
        this.setState({ timeLeft: 0 });
      }else{
        this.setState( { timeLeft: this.state.timeLeft - 1 });
      }
    }, 1000);
  };
  handleBackToForgetPassword(){ 
    this.setState({ showEmailForm: true, showOtpForm: false })
    if(this.timerRef){
      clearInterval(this.timerRef)
    }
  }
  validateEmail(){
    if(!this.state.email){
      this.setState({emailError:true,emailErrorMessage:"Email Required"})
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(this.state.email)){
      this.setState({emailError:true,emailErrorMessage:"Invalid email"})
      return false;
    };
    return true
  }

  handleForgetPassword(){
    if(this.validateEmail()){
      this.senOptOnEmail()
    }
  }
  validatePassword(){
    const isNotMatch = this.state.newPassword !== this.state.confirmNewPassword;
    const errorMessage = isNotMatch ? "Password not matched" : ""
    this.setState({newPasswordError:isNotMatch, confirmNewPasswordError:isNotMatch, confirmNewPasswordErrorMessage:errorMessage})
    return !isNotMatch
  }

 
  senOptOnEmail(){
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestBody={
      email: this.state.email
    }
    const sendOtpRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailOtpCApiCallId = sendOtpRequest.messageId;

    sendOtpRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPasswordEndPoint
    );

    sendOtpRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    sendOtpRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    sendOtpRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(sendOtpRequest.id, sendOtpRequest);
  }

  handleSendOtpOnEmailResponse(response:SendOtpResponse){
    if(response.data){
      this.setState({showEmailForm:false, showOtpForm:true, oneTimePassword:"", timeLeft: 30,emailError:false, emailErrorMessage:"", verifyOtpError:""})
      this.startTimer();
    }else{
      this.setState({oneTimePassword:"",emailError:true, emailErrorMessage:"Account not found"})
    }
  }

  handleVerifyOtp(){
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestBody={
      email: this.state.email,
      otp:this.state.oneTimePassword
    }
    const sendOtpVerifyRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpApiCallId = sendOtpVerifyRequest.messageId;

    sendOtpVerifyRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpEndPoint
    );

    sendOtpVerifyRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    sendOtpVerifyRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    sendOtpVerifyRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(sendOtpVerifyRequest.id, sendOtpVerifyRequest);
  }

  handleVerifyOtpResponse(response:VerifyOtpResponse){
    if(response.data){
      this.setState({showOtpForm:false, showNewPasswordForm:true, otpVerifiedToken:response.data.token})
    }else{
      this.setState({verifyOtpError:response.error})
    }
  }

  handleSetNewPassword(){
    if(this.validatePassword()){
      const headers = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        token:this.state.otpVerifiedToken
      };
      const requestBody={
        password:this.state.newPassword,
        confirm_password:this.state.confirmNewPassword,
      }
      const setNewPasswordRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setNewPasswordApiCallId = setNewPasswordRequest.messageId;
  
      setNewPasswordRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.setNewPasswordEndPoint
      );
      setNewPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      setNewPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      setNewPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(setNewPasswordRequest.id, setNewPasswordRequest);      
    }
  }

  handleSetNewPasswordResponse(){
    this.setState({showNewPasswordForm:false, showSuccessResetForm:true})
  }

  handleOpenLoginForm(){
    this.props.onClose()
    this.props.goToLoginForm()
  }
}

// Customizable Area End