Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod="GET";
exports.getCustomizableProfileEndPoint="/account_block/accounts/";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getMessageResponseFromJarvysEndPont="/bx_block_chatgpt3/chatgpt";
exports.jarvysHeadMsg = "JARVYS AI";
exports.platformMessgae = "To continue the conversation with Jarvys you will need to log in or sign up to the DatAI platform";
exports.signupText = "Sign up";
exports.loginText = "Log in";
exports.servicesText = "Services"
exports.userEnagementToolTitle = "User Engagement Tools"
exports.userEnagementToolDesc = "At DataAI, our mission is to empower businesses, researchers, and innovators with cutting-edge AI-driven tools that transform raw data into actionable insights. We believe in the power of data to drive informed decisions, and we’re committed to making advanced analytics accessible to everyone."
exports.realTimeTitle = "Real Time Analytics"
exports.realTimeDesc = "At DataAI, our mission is to empower businesses, researchers, and innovators with cutting-edge AI-driven tools that transform raw data into actionable insights. We believe in the power of data to drive informed decisions, and we’re committed to making advanced analytics accessible to everyone."
exports.tailormadeTitle = "Tailormade";
exports.tailormadeDesc = "At DataAI, our mission is to empower businesses, researchers, and innovators with cutting-edge AI-driven tools that transform raw data into actionable insights. We believe in the power of data to drive informed decisions, and we’re committed to making advanced analytics accessible to everyone.";
exports.contentCreationTitle = "Content Creation ";
exports.contentCreationDesc = "At DataAI, our mission is to empower businesses, researchers, and innovators with cutting-edge AI-driven tools that transform raw data into actionable insights. We believe in the power of data to drive informed decisions, and we’re committed to making advanced analytics accessible to everyone.";

exports.getServicesEndPoint = "bx_block_content_management/services";
exports.getMethod = "GET";

// Customizable Area End