export const groupVideo = require('../assets/group_video.png').default
export const topIcon1 = require('../assets/icon_1.png').default
export const topIcon2 = require('../assets/icon_2.png').default
export const topIcon3 = require('../assets/icon_3.png').default
export const plusIcon = require('../assets/plus_icon.png').default
export const deleteIcon = require('../assets/delete_icon.png').default
export const editIcon = require('../assets/edit_icon.png').default
export const facebookIcon = require('../assets/facebook_icon.png').default
export const instagramIcon = require('../assets/instagram_icon.png').default
export const tiktokIcon = require('../assets/tiktok_icon.png').default
export const xIcon = require('../assets/x_icon.png').default
export const linkedinIcon = require('../assets/linkedin_icon.png').default
export const deletedTickIcon = require('../assets/tick_icon.png').default
export const questionMarkIcon = require('../assets/question_mark.png').default
export const uploadFileIcon = require('../assets/upload_file.png').default
export const pinterestIcon = require('../assets/pinterest_icon.png').default
export const pdfIcon = require('../assets/pdf_icon.png').default
export const pdfCrossIcon = require('../assets/pdf_cros_icon.png').default
export const pdfDownloadIcon = require('../assets/pdf_download_icon.png').default
export const cPlusIcon = require('../assets/c_plus_icon.png').default
export const pineIcon = require('../assets/pineterest_ic.png').default
export const winkingEmoji = require('../assets/winking_emoji.png').default
export const tailormadeIcon = require('../assets/tailormade.svg').default
export const contentCreationIcon = require("../assets/ContentCreationIcon.svg").default;
export const realTimeAnalyticsIcon = require("../assets/realTimeAnalyticsIcon.svg").default;
export const userEngagementToolsIcon = require("../assets/UserEngagementToolsIcon.svg").default;
export const lockIcon = require("../assets/lockIcon.svg").default;
export const quickStartTutorialIcon = require("../assets/quickStartTutorialIcon.svg").default;
export const datAiCommunityIcon = require("../assets/datAiCommunityIcon.svg").default;
export const additionalHelpIcon = require("../assets/additionalHelpIcon.svg").default;
export const faqIcon = require("../assets/faqIcon.svg").default;
export const rightIcon = require("../assets/rightIcon.svg").default;