// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { facebookIcon, instagramIcon, linkedinIcon, pinterestIcon, tiktokIcon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;

}

interface CommunityForumAttributes {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      serious_mode: string;
      mode: string;
      fun_mode: string;
      web_link: string;
      status: string;
      created_at: string;
      updated_at: string;
      brand_guideline: File[];
      product_price: File[];
    },
  }
}

interface S {
  name: string;

  dashboardData: {
    type: string;
    quantity: string;
  }[];
  socialMediaData: {
    id: number;
    name: string;
    icon: string;
    connected: boolean;
  }[];
  linkedAccountNumber: string,
  isModalOpen: boolean;
  web_link: string;
  custom_instrution: string;
  sliderValue: number | number[];
  guidelineFiles: File[];
  productPriceFiles: File[];
  authToken: string;
  current_cm_id: string;
  isEditPage: boolean;
  isNameError: boolean;
  isSocialAccountUsed: boolean;
  inputValue: string;
  isInstaConnected: boolean,
  instagramId: number,
}
interface SS { }

export default class NewCommunityManagerController extends BlockComponent<Props, S, SS> {
  createCommunityApiCallId: string = '';
  getEditDataApiCallId: string = '';
  getConnectedAccDetailApiCallId: string = '';
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.inputRef = React.createRef();

    this.subScribedMessages = [
     getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      dashboardData: [],
      socialMediaData: [
        { id: 37, name: "Instagram", icon: instagramIcon, connected: false },
      ],
      linkedAccountNumber: '',
      isModalOpen: false,
      name: '',
      web_link: '',
      custom_instrution: '',
      sliderValue: 0,
      guidelineFiles: [],
      productPriceFiles: [],
      authToken: '',
      current_cm_id: '',
      isEditPage: false,
      isNameError: false,
      isSocialAccountUsed: false,
      inputValue: '',
      isInstaConnected: false,
      instagramId: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    let authToken = await getStorageData('authToken')
    this.setState({ authToken: authToken });

    let currentPath = window.location.pathname;
    const segments = currentPath.split('/');
    const current_cm_id = segments[segments.length - 1];

    this.setState({ current_cm_id: current_cm_id })
    if (currentPath.includes('EditCommunityManager')) {
      this.setState({ isEditPage: true })
      this.handleGetEditData();
    }

    this.handleConnectedAccDetail();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)

    );

    runEngine.debugLog("API Message Recived", message);

    if (apiRequestCallId === this.createCommunityApiCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          name: '',
          web_link: '',
          custom_instrution: '',
          sliderValue: 0,
        });

        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashbored");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }else if(responseJson && (responseJson.message === 'social account already used in community')){
        this.setState({
          isSocialAccountUsed: true,
        })
      }
    }
    if (apiRequestCallId === this.getEditDataApiCallId) {
      if (responseJson && responseJson.data) {
        this.handleGetData(responseJson)
      }
    }
    if(apiRequestCallId === this.getConnectedAccDetailApiCallId){
      if(responseJson && responseJson.data){
        
        const instagramPlatform = responseJson.data.platform_statuses.find(
          (platform: { id: number; active: boolean, name: string }) => platform.name === 'instagram'
        );

        if(instagramPlatform){
          this.setState({
            isInstaConnected: instagramPlatform.active,
            instagramId: instagramPlatform.id,
          })
        }
      }
    }
  }

  handleGetData = (responseJson: CommunityForumAttributes) => {
    let attributes = responseJson.data && responseJson.data.attributes
    this.setState({
      name: attributes.name,
      sliderValue: this.getModeNumber(attributes.mode),
      web_link: attributes.web_link,
      guidelineFiles: attributes.brand_guideline,
      productPriceFiles: attributes.product_price,
      custom_instrution: attributes.description
      ,
    })
  }

  handleNameChange = (newName: string) => {
    this.setState({ name: newName, isNameError: false });
  }

  handlewebsiteChange = (text: string) => {
    this.setState({ web_link: text });
  }

  handleDescChange = (text: string) => {
    this.setState({ custom_instrution: text });
  }

  handleSliderChange = (event: Event, newValue: number | number[]) => {
    this.setState({ sliderValue: newValue });
  };

  handleToggle = (id: number): void => {
    if(!this.state.isInstaConnected)
    this.setState((prevState) => ({
      socialMediaData: prevState.socialMediaData.map((item) =>
        item.id === id ? { ...item, connected: !item.connected } : item
      ),
    }), () => {
      const linkedAccountNumber = this.state.socialMediaData
        .filter((item) => item.connected)
        .map((item) => item.id)
        .join(',');

      if(this.state.isSocialAccountUsed && !this.state.socialMediaData[0].connected){
        this.setState({
          isSocialAccountUsed: false,
        })
      }

      this.setState({
        linkedAccountNumber: linkedAccountNumber
      });
    });
  };

  handleCancelChanges = () => {
    this.removeAllSelectedData();
    this.handleCloseModal();
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true })
  }

  removeAllSelectedData = () => {
    this.setState({
      name: '',
      sliderValue: 0,
      web_link: '',
      guidelineFiles: [],
      productPriceFiles: [],
      custom_instrution: '',
      linkedAccountNumber: '',
      socialMediaData: [
        { id: 37, name: "Instagram", icon: instagramIcon, connected: false },
      ],
    })

  }

  getModeText = (num: number | number[]) => {
    const levels = [ "level_1", "level_2", "level_3", "level_4", "level_5"];

    if (typeof num === "number") {
      return levels[num];
    }

    return undefined;
  };

  getModeNumber = (text: string) => {
    const levels = [ "level_1", "level_2", "level_3", "level_4", "level_5"];

    const index = levels.indexOf(text);
    return index;
  };
   checkIsValidData = () => {
    const { name } = this.state;
    if (name) {
      return true;
    } else {
      this.setState({
        isNameError: true,
      });
  
      if (this.inputRef.current) {
        this.inputRef.current.focus();
        this.inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  
      return false;
    }
  };

  handleCreateCommunity = () => {
    if (!this.checkIsValidData()) {
      return;
    }

    const { name, web_link, custom_instrution, sliderValue, guidelineFiles, productPriceFiles } = this.state;

    const body = {
      name: name,
      mode: this.getModeText(sliderValue),
      web_link: web_link,
      brand_guideline: guidelineFiles,
      product_price: productPriceFiles,
      description: custom_instrution,
      link_account: [this.state.instagramId]
    }

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommunityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isEditPage ? `${webConfigJSON.CommunityEndPoint}/${this.state.current_cm_id}` : webConfigJSON.CommunityEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isEditPage ? webConfigJSON.updateCommunityMethod : webConfigJSON.createCommunityMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetEditData = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.CommunityEndPoint}/${this.state.current_cm_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGuidelineFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      this.setState((prevState) => ({
        guidelineFiles: [...prevState.guidelineFiles, ...Array.from(newFiles)]
      }));
    }
  };

  removeGuidelineFile = (index: number) => {
    this.setState((prevState) => {
      const newFiles = prevState.guidelineFiles.filter((_, i) => i !== index);
      return { guidelineFiles: newFiles };
    });
  };

  handleProductPriceFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      this.setState((prevState) => ({
        productPriceFiles: [...prevState.productPriceFiles, ...Array.from(newFiles)]
      }));
    }
  };

  removeProductPriceFile = (index: number) => {
    this.setState((prevState) => {
      const newFiles = prevState.productPriceFiles.filter((_, i) => i !== index);
      return { productPriceFiles: newFiles };
    });
  };

  handleConnectedAccDetail = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getConnectedAccDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getConnectedAccDetailEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End