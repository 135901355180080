import React, { useState } from 'react';
import { Box, Typography, Button, Modal, keyframes, } from '@mui/material'
import { styled } from '@mui/material/styles';
import { regenerateIcon, saveToLibIcon, fullScreenIcon, deleteIcon, galleryIcon, paintIcon, loadingIcon, playIcon } from '../assets';
import { Image, Video } from '../ContentManagementEditController';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
interface Props {
    onRegenerate: (id: string) => void;
    onSaveToLibrary: (id: number) => void;
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onChooseFromGallery: (id: number) => void;
    image: Image | Video;
    isRegenerating: boolean
}
const ImageGenerator: React.FC<Props> = ({ image, onRegenerate, onSaveToLibrary, onDelete, onEdit, onChooseFromGallery, isRegenerating }) => {
    const [isOpenFullImageModal, setIsOpenFullImageModal] = useState<boolean>(false)
    const openFullImage = () => setIsOpenFullImageModal(true)
    const closeFullImage = () => setIsOpenFullImageModal(false)
    const rotateAnimation = keyframes`from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }`;

    const renderMediaContent = () => {
        if (isRegenerating) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                        width: "200px",
                        borderRadius: "20px",
                        background: "#DDDDDDDD",
                    }}
                >
                    <Box
                        component="img"
                        src={loadingIcon}
                        sx={{
                            borderRadius: "50%",
                            width: "36px",
                            height: "36px",
                            animation: `${rotateAnimation} 2s linear infinite`,
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: "Open Sans",
                            color: "#767676",
                            fontWeight: 400,
                            fontSize: "16px",
                            textAlign: "center",
                        }}
                    >
                        Regenerating
                    </Typography>
                </Box>
            );
        } else if ("image_url" in image) {
            return <Box component="img" src={image.image_url} sx={styles.mainImage} />;
        } else {
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "fit-content",
                        width: "fit-content",
                        position: "relative",
                    }}
                >
                    <video src={image.video_url} style={styles.video} />
                    <img
                        src={playIcon}
                        style={{ top: "36%", left: "39%", position: "absolute" }}
                    />
                </Box>
            );
        }
    };

    return (
        <>
            <StyledWrapper>
                {renderMediaContent()}
                <StyledButtonsWrapper>
                    <StyledSMButtonContainer>
                        <button style={styles.buttonStyle} onClick={() => onRegenerate(image.id.toString())} data-testid="btn-regenerate">
                            <img src={regenerateIcon} alt="" />
                            <Typography sx={styles.f12}>Regenerate</Typography>
                        </button>
                        <button style={{ ...styles.buttonStyle, }} onClick={() => onSaveToLibrary(image.id)} data-testid="btn-saveToLib">
                            <img src={saveToLibIcon} alt="" />
                            <Typography sx={styles.f12}>Save to library</Typography>
                        </button>
                        <button style={{ ...styles.buttonStyle, }} onClick={openFullImage} data-testid="btn-fullImage">
                            <img src={fullScreenIcon} alt="" />
                            <Typography sx={styles.f12}>Full image</Typography>
                        </button>
                        <button style={styles.buttonStyle} onClick={() => onDelete(image.id)} data-testid="btn-delete">
                            <img src={deleteIcon} alt="" />
                            <Typography sx={styles.f12}>Delete</Typography>
                        </button>
                    </StyledSMButtonContainer>
                    <StylesLGButtonContainer>
                        {'image_url' in image && <Button sx={styles.editBtn} fullWidth onClick={() => onEdit(image.id)} data-testid="btn-edit">
                            <img src={paintIcon} alt="" />
                            Edit this image
                        </Button>}
                        <Button sx={styles.galleryBtn} fullWidth onClick={() => onChooseFromGallery(image.id)} data-testid="btn-chooseFromLib">
                            <img src={galleryIcon} alt="" />
                            <Typography sx={styles.galleryBtnTxt}>Choose from library</Typography>
                        </Button>
                    </StylesLGButtonContainer>
                </StyledButtonsWrapper>
            </StyledWrapper>
            <Modal
                data-testid='fullImage'
                open={isOpenFullImageModal}
                onClose={closeFullImage}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <StyledFullImageContainer>
                    {'image_url' in image ? <img src={image.image_url} style={styles.fullImage} /> : <video src={image.video_url} controls autoPlay loop style={styles.fullImage} />}
                    <CloseOutlinedIcon sx={styles.closeIconStyle} onClick={closeFullImage} data-testid='closeFullViewButton' />
                </StyledFullImageContainer>
            </Modal>

        </>
    );
}

export default ImageGenerator;

const StyledWrapper = styled(Box)({
    display: 'flex',
    gap: '16px',
    width: "100%",
    justifyContent: 'start',
    '@media (max-width:645px)': {
        flexDirection: 'column'
    },
})
const StyledButtonsWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    '@media (max-width: 1398px)': {
        flexDirection: 'row',
        alignItems: "end"
    },
    '@media (max-width: 1327px)': {
        flexDirection: 'column',
        alignItems: "start"
    },
    '@media (max-width: 941px)': {
        flexDirection: 'row',
        alignItems: "end"
    },
    '@media (max-width: 899px)': {
        flexDirection: 'column',
        alignItems: "start"
    },
    '@media (max-width: 744px)': {
        flexDirection: 'row',
        alignItems: "end"
    },
    '@media (max-width: 645px)': {
        justifyContent: "start",
        alignItems: "end",
        gap: "30px"
    },
    '@media (max-width: 485px)': {
        flexDirection: 'row',
        alignItems: "end"
    },
    '@media (max-width: 429px)': {
        flexDirection: 'column',
        alignItems: "start",
    },
})

const StyledFullImageContainer = styled(Box)({
    position: "relative",
    width: 'clamp(250px, 80vw, 1106px)',
    display: 'flex',
    height: 'clamp(295px, 80vh, 731px)',
    background: "#4D4D4D",
    borderRadius: '20px',
    padding: "48px 24px 24px 24px",
    alignItems: "center",
    justifyContent: 'center',
})



const StyledSMButtonContainer = styled(Box)({
    display: 'flex',
    width: "fit-content",
    flexDirection: 'column',
    gap: '12px',
    '@media (max-width: 1327px)': {
        gap: "3px"
    },
    '@media (max-width: 1190px)': {
        gap: "12px"
    },
})

const StylesLGButtonContainer = styled(Box)({
    display: 'flex',
    gap: '10px',
    width: "100%",
    '@media (max-width: 1398px)': {
        flexDirection: 'column',
        width: "fit-content",
        gap: "12px"
    },
    '@media (max-width: 1327px)': {
        gap: "6px"
    },
    '@media (max-width: 1190px)': {
        flexDirection: 'row',
        width: "100%",
        gap: "24px"
    },
    '@media (max-width: 941px)': {
        flexDirection: 'column',
        width: "fit-content",
        gap: "12px"
    },
    '@media (max-width: 899px)': {
        flexDirection: 'row',
        width: "100%",
        gap: "24px"
    },
    '@media (max-width: 744px)': {
        flexDirection: 'column',
        width: "fit-content",
        gap: "12px"
    },
})

const styles = {
    mainImage: {
        height: '200px',
        width: '200px',
        borderRadius: '20px',
        objectFit: "cover"
    },
    video: {
        height: '200px',
        width: '200px',
        borderRadius: '20px',
        objectFit: "cover" as "cover"
    },

    fullImage: {
        height: "100%",
        width: "100%",
        objectFit: "contain" as "contain",
    },
    closeIconStyle: {
        position: "absolute",
        color: "#FFFFFF",
        fontSize: "24px",
        cursor: "pointer",
        zIndex: 5,
        top: 19,
        right: 19,
    },
    buttonStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        backgroundColor: '#fff',
        color: '#fff',
        outline: 'none',
        border: 'none',
        fontFamily: 'Open sans',
    },
    f12: {
        fontSize: '12px',
        color: '#000',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Open sans',
        '&:hover': {
            color: "#000000",
            fontWeight: 600,
        }
    },
    editBtn: {
        background: "#ffffff",
        textTransform: "none",
        color: 'black',
        border: "2px solid black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "8px",
        height: '36px',
        "&:hover": {
            background: "#ffffff"
        },
        maxWidth: "218px"
    },

    galleryBtn: {
        background: "#000000",
        textTransform: "none",
        color: '#ffffff',
        border: "2px solid black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "8px",
        height: '36px',
        "&:hover": {
            background: "#000000"
        },
        maxWidth: "218px"
    },
    editBtnTxt: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#000',
        fontFamily: 'Open sans',
    },
    galleryBtnTxt: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#fff',
        fontFamily: 'Open sans',
        lineHeight: '24px'
    }
}