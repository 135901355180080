import React from "react";
// Customizable Area Start
import { Box, styled, Typography } from "@mui/material";
import { additionalHelpIcon, contentCreationIcon, datAiCommunityIcon, faqIcon, lockIcon, quickStartTutorialIcon, realTimeAnalyticsIcon, rightIcon, tailormadeIcon, userEngagementToolsIcon } from './assets';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderExploreDashboard = () => {
    return (
      <ExploreMainBox>
        <ExploreHeading>{webConfigJSON.exploreHeadingText}</ExploreHeading>
        <ExploreBox>
          <ExploreInnerBox className="tailormade" data-testId="tailormade">
            <Box className="dashboard_img">
              <img className="dashboard_img tailormade_img" src={tailormadeIcon}/>
              <img src={lockIcon} alt="" />
            </Box>
           <Box>
           <Typography className="dashboard_heading">{webConfigJSON.TailormadeHeading}</Typography>
           <Typography className="dashboard_content">{webConfigJSON.TailormadeContent}</Typography>
           </Box>
          </ExploreInnerBox>

          <ExploreInnerBox className="content_creation" onClick={() => {this.navigateTo("GenerateContent")}} data-testId="contentCreation">
            <Box className="dashboard_img">
              <img className="" src={contentCreationIcon}/>
            </Box>
           <Box>
           <Typography className="dashboard_heading">{webConfigJSON.ContentCreationHeading}</Typography>
           <Typography className="dashboard_content">{webConfigJSON.ContentCreationContent}</Typography>
           </Box>
          </ExploreInnerBox>

          <ExploreInnerBox className="real_time_analytics" data-testId="realTimeAnalytics">
            <Box className="dashboard_img">
              <img className="dashboard_img" src={realTimeAnalyticsIcon}/>
              <img src={lockIcon} alt="" />
            </Box>
           <Box>
           <Typography className="dashboard_heading">{webConfigJSON.RealTimeAnalyticsHeading}</Typography>
           <Typography className="dashboard_content">{webConfigJSON.RealTimeAnalyticsContent}</Typography>
           </Box>
          </ExploreInnerBox>

          <ExploreInnerBox className="user_engagement_tools" onClick={() => {this.navigateTo("Dashbored")}} data-testId="userEngagement">
            <Box className="dashboard_img">
              <img className="dashboard_img" src={userEngagementToolsIcon}/>
            </Box>
           <Box>
           <Typography className="dashboard_heading">{webConfigJSON.UserEngagementToolsHeading}</Typography>
           <Typography className="dashboard_content">{webConfigJSON.UserEngagementToolsContent}</Typography>
           </Box>
          </ExploreInnerBox>
        </ExploreBox>
      </ExploreMainBox>
    )
  }

  renderHelpSection = () => {
    return (
      <HelpMainBox>
        <Typography className="heading">{webConfigJSON.helpHeadingText}</Typography>
        <Box className="help_cards">
          <Box className="help_card" onClick={() => {this.navigateTo("Onboardingguide")}} data-testId="tutorialHelp">
            <Box className="images">
              <img src={quickStartTutorialIcon} alt="" />
              <img src={rightIcon} alt="" className="right_img"/>
            </Box>
            <Box>
              <Typography className="header_text">{webConfigJSON.QuickStartTutorialHeader}</Typography>
              <Typography className="content_text">{webConfigJSON.QuickStartTutorialContent}</Typography>
            </Box>
            <img src={rightIcon} alt="" className="right_img"/>
          </Box>

          <Box className="help_card" data-testId="communityHelp">
            <Box className="images">
              <img src={datAiCommunityIcon} alt="" />
              <img src={rightIcon} alt="" className="right_img"/>
            </Box>
            <Box>
              <Typography className="header_text">{webConfigJSON.DatAICommunityHeader}</Typography>
              <Typography className="content_text">{webConfigJSON.DatAICommunityContent}</Typography>
            </Box>
            <img src={rightIcon} alt="" className="right_img"/>
          </Box>

          <Box className="help_card" onClick={() => {this.navigateTo("Contactus")}} data-testId="additionlHelp">
            <Box className="images">
              <img src={additionalHelpIcon} alt="" />
              <img src={rightIcon} alt="" className="right_img"/>
            </Box>
            <Box>
              <Typography className="header_text">{webConfigJSON.NeedAdditionalhelp}</Typography>
              <Typography className="content_text">{webConfigJSON.NeedAdditionalhelpContent}</Typography>
            </Box>
            <img src={rightIcon} alt="" className="right_img"/>
          </Box>

          <Box className="help_card" onClick={() => {this.navigateTo("Interactivefaqs")}} data-testId="faqHelp">
            <Box className="images">
              <img src={faqIcon} alt="" />
              <img src={rightIcon} alt="" className="right_img"/>
            </Box>
            <Box>
              <Typography className="header_text">{webConfigJSON.FAQsHeader}</Typography>
              <Typography className="content_text">{webConfigJSON.FAQsContent}</Typography>
            </Box>
            <img src={rightIcon} alt="" className="right_img"/>
          </Box>
        </Box>
      </HelpMainBox>
    )
  }

  latestUpdateSection = () => {
    return (
      <LatestUpdateSection>
        <Box>
          <Typography className="header_text">{webConfigJSON.latestUpdatesHeading}</Typography>
          <Typography className="content_text">{webConfigJSON.latestUpdatesContent}</Typography>
        </Box>
        <Box>
        <video src="https://www.w3schools.com/html/mov_bbb.mp4" className="video" controls></video>
        </Box>
      </LatestUpdateSection>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <DashboardMainContainer>
       <DashboardHeading>{webConfigJSON.dashboardHeadingText}</DashboardHeading>
       {this.renderExploreDashboard()}
       {this.renderHelpSection()}
       {this.latestUpdateSection()}
      </DashboardMainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const DashboardMainContainer = styled(Box)({
  padding: "32px 24px",
  display: "flex",
  justifyContent: "start",
  alignItems: 'start',
  flexDirection: 'column',
  gap: '40px',
  '@media (max-width: 600px)': {
    padding: '25px',
  },
  '@media (max-width: 500px)': {
    padding: '0px',
  },
});

const DashboardHeading = styled(Box)({
  paddingLeft: "24px",
  color: "#364AC6",
  fontSize: "48px",
  lineHeight: "65.37px",
  fontWeight: 600,
  fontFamily: "Open Sans",
  "@media (max-width: 850px)": {
    fontSize: '40px',
  lineHeight: "40px",

  },
  "@media (max-width: 650px)": {
    fontSize: '36px',
  lineHeight: "36px",

  },
  "@media (max-width: 450px)": {
    fontSize: '32px',
    lineHeight: "32px",
  }
});

const ExploreMainBox = styled(Box)({
  padding: "24px",
  display: 'flex',
  flexDirection: "column",
  gap: "32px",
  "@media (max-width: 650px)": {
    gap: "16px",
  }
});

const ExploreHeading = styled(Typography)({
  color: "#4D4D4D",
  fontSize: "32px",
  lineHeight: "43.58px",
  fontWeight: 600,
  fontFamily: "Open Sans",
  "@media (max-width: 850px)": {
    fontSize: '28px',
  },
  "@media (max-width: 650px)": {
    fontSize: '24px',
  },
});

const ExploreBox = styled(Box)({
  display:'flex',
  alignItems:'center',
  justifyContent:'start',
  gap: "16px",
  flexWrap: 'wrap',
  "& .tailormade": {
    opacity: '.5'
  },
  "& .content_creation": {
    backgroundColor:'#FFFDD8'
  },
  "& .real_time_analytics": {
    backgroundColor:'#F2F5FF',
    opacity: '.5'
  },
  "& .user_engagement_tools": {
    backgroundColor:'#D8FFD5'
  },
  "@media (max-width: 658px)": {
    justifyContent: "center",
    alignItems:"center",
  }
});

const ExploreInnerBox = styled(Box)({
  cursor: "pointer",
  width: "240px",
  height: "229px",
  border: "1px solid #DDDDDD",
  padding: "16px",
  borderRadius: "20px",
  background: "#FBFBFB",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  "& .dashboard_img":{
    height: "80px",
    display:'flex',
    alignItems:'start',
    justifyContent:'space-between',
  },
  "& .dashboard_heading":{
    color: "#4D4D4D",
  fontSize: "18px",
  lineHeight: "24.51px",
  fontWeight: 600,
  fontFamily: "Open Sans",
  },
  "& .dashboard_content":{
    color: "#4D4D4D",
    fontSize: "12px",
    lineHeight: "16.34px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    marginTop: "6px"
  }
});

const HelpMainBox = styled(Box)({
  padding: '24px',
  display: "flex",
  flexDirection:'column',
  justifyContent:"start",
  alignItems:'start',
  gap: '32px',
  cursor: "pointer",
  "& .heading": {
    fontSize: "32px",
    color: "#364AC6",
    fontWeight: 600,
    fontFamily: 'Open sans'
  },
  "& .help_cards": {
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridColumnGap: "24px",
    gridRowGap: "24px",
    "& .help_card": {
      backgroundColor: "#F2F5FF",
      padding: "16px",
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      gap: '24px',
      borderRadius: '20px',
      "& .images": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        "& .right_img": {
          display: "none"
        }
      },
      "& .header_text": {
        fontSize: '18px',
        color: '#364AC6',
        fontWeight: 600,
        fontFamily: 'Open sans'
      },
      "& .content_text":{
        fontSize: '14px',
        color: '#364AC6',
        fontWeight: 400,
        fontFamily: 'Open sans'
      },
    },
    "@media (max-width: 1000px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
      gridTemplateRows: "repeat(1, 1fr)",
    },
    "@media (max-width: 500px)": {
      "& .help_card": {
        flexDirection: "column",
        alignItems: "start",
        "& .images": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          width: "100%",
          "& .right_img": {
            display: "block"
          }
        },
        "& .right_img": {
          display: "none"
        }
      },
    },
  }
})

const LatestUpdateSection = styled(Box)({
  padding:'24px',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "48px",
  "& .header_text": {
    color: '#4D4D4D',
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: 'Open sans',
    marginBottom: '12px',
  },
  "& .content_text": {
    color: '#4D4D4D',
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: 'Open sans'
  },
  "& .video": {
    maxWidth: "550px",
    height: '310px',
    borderRadius: '4px',
    border: '1px solid #4d4d4d'
  },
  "@media (max-width: 1150px)": {
    flexDirection: 'column',
    "& .video": {
      width: '100%'
    }
  },
  "@media (max-width: 850px)": {
    "& .header_text": {
      fontSize: '28px',
    },
    "& .content_text": {
      fontSize: '16px'
    }
  },
  "@media (max-width: 650px)": {
    "& .header_text": {
      fontSize: '24px',
    },
    "& .content_text": {
      fontSize: '14px'
    }
  }
})
// Customizable Area End
