// Customizable Area Start
import React from "react";

import { Box, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
import DashboardCommunityManagerController, {
    Props,

} from "./DashboardCommunityManagerController.web";
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, IconButton, Link, Tooltip, styled } from "@mui/material";
import { cPlusIcon, deleteIcon, deletedTickIcon, editIcon, facebookIcon, instagramIcon, linkedinIcon, pineIcon, tiktokIcon } from "./assets";
import { closeIcon } from "../../chatgpt/src/assets";
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DashboardCommunityManager extends DashboardCommunityManagerController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
                <Box style={webStyles.mainContainer}>
                    <CreateCommunityBtnMainBox>
                        <ComminutyMainTitle>Community Manager</ComminutyMainTitle>
                        <CreateCommunityBtnBox data-test-id='redirectToCreate' onClick={this.redirectToCreate}>
                            <Typography style={webStyles.createCommunityText}>Create Community Manager</Typography>
                            <img style={webStyles.cPlusIcon} src={cPlusIcon} alt="" />
                        </CreateCommunityBtnBox>
                    </CreateCommunityBtnMainBox>

                    <CustomAccordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{color: '#059669', height: '24px', width: '24px',}} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <HowToCreateTitle>How to create your own Community Manager</HowToCreateTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box style={webStyles.topContainer}>
                                <TopContTopBox>
                                    <Box style={webStyles.topContTopLeft}>
                                        <TopConTypo>Our AI-powered community manager allows you to build and customize a virtual assistant to enhance your engagement and support. Tailor the bot to fit your needs, automate interactions, personalize user experiences, and gather valuable insights.</TopConTypo>
                                    </Box>
                                    <Box style={webStyles.topContTopRight}>

                                        <Box
                                            sx={{
                                                width: "560px",
                                                height: "315px",
                                                borderRadius: "15px",
                                                overflow: "hidden",
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            }}
                                        >
                                            <iframe
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "0",
                                                }}
                                                src={this.state.communityVideoUrl}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen
                                            ></iframe>
                                        </Box>
                                    </Box>
                                </TopContTopBox>
                                <BenefitsBox>
                                    {
                                        this.state.topHeadings.map((heading) => {
                                            return (
                                                <Box key={heading.title}>
                                                    <Box style={webStyles.benefitsBox2}>
                                                        <img style={webStyles.benefitsIcon} src={heading.icon} alt="" />
                                                        <BenefitsTitle>{heading.title}</BenefitsTitle>
                                                    </Box>
                                                    <Typography style={webStyles.benefitsDesc}>{heading.desc}</Typography>
                                                </Box>
                                            )
                                        })
                                    }
                                </BenefitsBox>
                            </Box>
                        </AccordionDetails>
                    </CustomAccordion>

                    <BottomContainer >
                        {
                            this.state.current_CM_list && this.state.current_CM_list.map((myCm) => {
                                return (
                                    <Box key={myCm.id}>
                                        <Box style={webStyles.bottomBoxes}>
                                            <Typography style={webStyles.bottomBoxTitle}>{myCm.attributes.name}</Typography>
                                            <Box style={webStyles.bottomBoxSocialBox}>
                                                
                                                {
                                                    myCm.attributes.link_account[0] == this.state.instagramId && <BlueOnGreenTooltip  placement="bottom-start" title="Connected to Instagram" arrow>
                                                    <img src={instagramIcon} style={webStyles.bottomBoxSocialIcon} />
                                                </BlueOnGreenTooltip>
                                                }
                                            </Box>
                                            <Typography style={webStyles.bottomBoxDivider}></Typography>
                                            <Box style={webStyles.bottomActionBox}>
                                                <Typography style={webStyles.activeTxt}>Active</Typography>
                                                <TableCellTB> <Switch  className='switch_account' data-test-id='handleSwitchChange' onChange={(event) => this.handleSwitchChange(myCm.id)} checked={myCm.attributes.status === 'active' ? true : false} /></TableCellTB>
                                                <Link href={`EditCommunityManager/${myCm.id}`}>
                                                    <img src={editIcon} alt="" />
                                                </Link>
                                                <img src={deleteIcon} alt="" data-test-id='btnOpenDelteModal' onClick={() => this.handleOpenDeleteModal(myCm.attributes.name, myCm.id)} />
                                            </Box>
                                            <Box style={webStyles.bottomBoxDateBox}>
                                                <Typography style={webStyles.bottomBoxDate}>Last interaction</Typography>
                                                <Typography style={webStyles.bottomBoxDate}>{this.formatDate(myCm.attributes.updated_at)}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                    </BottomContainer>

                    <StyledDialog
                        onClose={this.handleCloseDeleteModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isModalOpen}
                    >
                        {
                            !this.state.isDeleteClicked && <IconBtn
                                aria-label="close"
                                onClick={this.handleCloseDeleteModal}
                            >
                                <img src={closeIcon} alt="close_icon" />
                            </IconBtn>
                        }

                        <DialogContent dividers>
                            {
                                this.state.isDeleteClicked
                                    ? <Box>
                                        <Typography style={webStyles.deleteTitle}>“{this.state.currentCmName}” Community Manger was deleted</Typography>
                                        <img style={webStyles.deletedTickIconStyle} src={deletedTickIcon} alt="" />
                                        <button style={webStyles.closeBtn} onClick={this.handleCloseDeleteModal}>Close</button>
                                    </Box>
                                    : <>
                                        <Typography style={webStyles.deleteTitle}>Delete “{this.state.currentCmName}” Community Manger</Typography>
                                        <Typography style={webStyles.deleteWarningText}>You will not be able to restore it</Typography>
                                        <Box style={webStyles.deleteButtonBox}>
                                            <button data-test-id='btnCloseDelteModal' onClick={this.handleCloseDeleteModal} style={webStyles.cancelBtn}>Cancel</button>
                                            <button data-test-id='handleDelete' onClick={this.deleteCommunity} style={webStyles.deleteBtn}>Delete</button>
                                        </Box>
                                    </>
                            }

                        </DialogContent>
                    </StyledDialog>
                </Box>
        );

    }
}

const webStyles = {
      bottomBoxes: {
        padding: "24px 24px 24px 24px",
        '@media (max-width: 768px)': {
          padding: '16px',
        },
        '@media (max-width: 480px)': {
          padding: '12px',
        },
      },
      bottomBoxTitle: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#4D4D4D',
        '@media (max-width: 768px)': {
          fontSize: '18px',
        },
        '@media (max-width: 480px)': {
          fontSize: '16px',
        },
      },
      bottomBoxSocialBox: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px',
        marginTop: '16px',
        '@media (max-width: 768px)': {
          marginTop: '12px',
          columnGap: '3px',
        },
        '@media (max-width: 480px)': {
          marginTop: '10px',
          columnGap: '2px',
        },
      },
      bottomBoxDivider: {
        border: '1px solid #EFEFEF',
        margin: '16px 0',
        '@media (max-width: 768px)': {
          margin: '12px 0',
        },
        '@media (max-width: 480px)': {
          margin: '8px 0',
        },
      },
      bottomActionBox: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px',
        '@media (max-width: 768px)': {
          columnGap: '12px',
        },
        '@media (max-width: 480px)': {
          columnGap: '8px',
        },
      },
      activeTxt: {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 600,
        color: '#059669',
        '@media (max-width: 768px)': {
          fontSize: '11px',
        },
        '@media (max-width: 480px)': {
          fontSize: '10px',
        },
      },
      bottomBoxDateBox: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 768px)': {
          marginTop: '12px',
        },
        '@media (max-width: 480px)': {
          marginTop: '8px',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      bottomBoxDate: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#B3B3B3',
        '@media (max-width: 768px)': {
          fontSize: '13px',
        },
        '@media (max-width: 480px)': {
          fontSize: '12px',
        },
      },
    mainContainer: {
        margin: "32px 24px 42px 24px",
        paddingBottom: '90px',
    },
    createCommunityText: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        marginRight: '8px',
    },
    cPlusIcon: {

    },
    topContainer: {
    },
    topContTopLeft: {
        width: '100%',
    },
    topContTopRight: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
    },

    topContRightImg: {
        width: "80%",
    },
    benefitsBox2: {
        display: 'flex',
        alignItems: 'center',
    },
    benefitsIcon: {},
    benefitsDesc: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        color: '#4D4D4D',
        marginTop: '16px',
    },
    topContainerHeading: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontSeight: 700,
        color: '#059669',
    },
    bottomCreateBoxes: {
        boxSizing: 'border-box' as 'border-box',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '60px 0 60px 0',
        cursor: 'pointer',
        border: '1px dashed #059669',
        borderRadius: '12px',
    },
    createText: {
        width: "204px",
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#059669',
        textAlign: "center" as "center",
        marginBottom: '16px',
    },
    bottomBoxSocialIcon: {
        height: '36px',
        width: '36px',
    },
    bottomBoxSocialIconPinterest: {
        height: '26px',
        width: '26px',
    },
    
    deleteTitle: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27.24px',
        color: '#059669',
        textAlign: 'center' as 'center',
    },
    deleteWarningText: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '21.79px',
        color: '#4D4D4D',
        marginTop: '16px',
        textAlign: 'center' as 'center',
    },
    deleteButtonBox: {
        marginTop: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cancelBtn: {
        width: '187.5px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: '#059669',
        border: 'none',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    deleteBtn: {
        width: '187.5px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: 'none',
        border: '1px solid #059669',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#059669',
        cursor: 'pointer',
    },
    deletedTickIconStyle: {
        display: 'block',
        margin: '40px auto 40px auto',
    },
    closeBtn: {
        width: '100%',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: '#059669',
        color: '#fff',
    },
}

export const IconBtn = styled(IconButton)({
    position: 'absolute',
    top: 8,
    right: 8,
});

export const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        width: '479px',
        borderRadius: "24px",
        boxSizing: 'border-box',
    },
    '& .MuiDialogContent-root': {
        padding: '40px 40px 40px 40px',
    },
});


const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "#FFFFFF",
        backgroundColor: "#9A9A9A",
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,

    },
    arrow: {
        color: "#9A9A9A",
        "&::before": {
            backgroundColor: "#9A9A9A",
        }
    }
})(Tooltip);

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
        boxShadow: 'none',
        border: `1px solid #059669`,
        borderRadius: '8px',
        '.MuiAccordionDetails-root': {},
        '.MuiAccordionSummary-root': {},
    };
});

const TopContTopBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
        display: 'block',
        rowGap: '20px',
    },
})

const TopConTypo = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    '@media (max-width: 1024px)': {
        marginBottom: '20px',
    },
})

const BenefitsBox = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: '48px',
    gap: '16px',
    '@media (max-width: 1024px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 800px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
})

const BenefitsTitle = styled(Typography)({
    fontFamily: ' Open Sans',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '27.24px',
    color: '#059669',
    '@media (max-width: 1024px)': {
        fontSize: '18px',
    },
    '@media (max-width: 700px)': {
        fontSize: '16px',
    },
})

const ComminutyMainTitle = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '32px',
    fontWeight: 600,
    color: '#059669',
    '@media (max-width: 1024px)': {
        fontSize: '24px',
    },
    '@media (max-width: 700px)': {
        fontSize: '22px',
    },
})

const HowToCreateTitle = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontSeight: 700,
    color: '#059669',
    '@media (max-width: 1024px)': {
        fontSize: '18px',
    },
    '@media (max-width: 700px)': {
        fontSize: '16px',
    },
})

const CreateCommunityBtnMainBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
    '@media (max-width: 700px)': {
        display: 'block',
        
    },
})

const CreateCommunityBtnBox = styled(Box)({
    backgroundColor: '#059669',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: "12px 14.5px 12px 14.5px",
    cursor: 'pointer',
    '@media (max-width: 700px)': {
        padding: "6px 8px 6px 8px",
        marginTop: '10px',
    },
})

const BottomContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '16px',
    boxSizing: 'border-box',
    '@media (max-width: 1200px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@media (max-width: 480px)': {
      gridTemplateColumns: '1fr',
      gap: '8px', 
    },
})
const TableCellTB = styled(Box)({
    "& .switch_account":{
     gap:"20px",
     "&.MuiSwitch-root":{
       height:"28px !important",
       width:"48px !important",
       borderRadius: "20px !important",
       padding: "0px",
       backgroundColor: "#E2E8F0",
       opacity:1,
       alignItem: "center",
       justifyContent: "center",
       margin: "0px 5px"
     },
     "& .MuiButtonBase-root":{
       padding: "0px",
       width: "28px",
       height: "28px"
     },
     "& .MuiButtonBase-root.Mui-checked":{
        color: "#FFFFFF"
     },
     "& .MuiSwitch-thumb":{
       width: "24px",
       height: "24px"
     },
     "& .MuiButtonBase-root.Mui-checked + .MuiSwitch-track":{
       backgroundColor: "#059669",
       opacity: 1
     }
    }
  })

// Customizable Area End
