// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  requestOptions: string[];
  name:string,
  email:string,
	selectedOption: string,
  additionalInformation: string,
  termsAndConditionCheckbox: boolean,
  isSuccessModalOpen: boolean,
}
  
interface SS {
	id: any;
}

export default class ContactusPageController extends BlockComponent<Props, S, SS> {
  apiIdEmailPost: string = "";
  apiIdRequestSubject: string = "";
	constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      requestOptions: [],
      name:"",
      email:"",
			selectedOption: "",
      additionalInformation: "",
      termsAndConditionCheckbox: false,
      isSuccessModalOpen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(apiRequestCallId === this.apiIdEmailPost){
        this.setState({isSuccessModalOpen: true});
      }else if(apiRequestCallId === this.apiIdRequestSubject){
       const names = responseJson.data.map((option: { attributes: { name: string; }; }) => option.attributes.name);
       this.setState({requestOptions: names});
      }
    }
}

apiCall = (data: ApiData) => {
  const { contentType, method, endPoint, body } = data;
  const token = localStorage.getItem("authToken") || "";

  const header: Record<string, string> = {
    token: token,
  };

  if (contentType !== "multipart/form-data") {
    header["Content-Type"] = contentType;
  }

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

  if (body) {
    if (body instanceof FormData) {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    } else {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    }
  }

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

  async componentDidMount() {
    super.componentDidMount();
    this.getRequestSubject();
	}
  
	handleSelections = (name: string) => {
		this.setState({selectedOption: name});
	}

  handleQuerySend = () => {
    const isValidEmail = /^[a-zA-Z\s]+$/.test(this.state.email);
    
    if(isValidEmail){
      toast.error("Enter valid email");
      return;
    }

    this.apiIdEmailPost = this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_contact_us/contacts/form",
      body: {
        data: {
          name: this.state.name,
          email: this.state.email,
          request_subject: this.state.selectedOption,
          description: this.state.additionalInformation,
          terms_and_condition_checkbox: this.state.termsAndConditionCheckbox
        }
      }
    })
  }

  getRequestSubject(){
    this.apiIdRequestSubject = this.apiCall({
      contentType: 'application/json',
      method:'GET',
      endPoint:'bx_block_contact_us/contacts/request_subjects',
    })
  }

  handleModalClose(){
    this.setState({isSuccessModalOpen:false, name: '', email: "", selectedOption: "", additionalInformation: "", termsAndConditionCheckbox: false});
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({termsAndConditionCheckbox:  event.target.checked});
  };
}

// Customizable Area End
