import React from "react";
// Customizable Area Start
import {
    Button,
    Modal,
    Typography,
    Box,
    Grid,
    CircularProgress
} from "@mui/material";
import {
    createTheme,
    Theme,
    styled
} from "@mui/material/styles";
import { WriteArrow, userDefaultImg,cloudUploadIcon, profileImageCover } from "./assets"
import EditIcon from '@mui/icons-material/Edit';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { 
    EditableContainer,
    StyledProfileTextField,
    StyledProfileFieldLabel,
    StyledProfileSectionHeader,
    StyledProfileImageContainer,
    StyledProfilePageHeading,
    ProfileUserNameTypo,
    ProfileStyledEditButton,
} from "../../../components/src/StyledComponents";
import DragAndDropImageSelector from "../../../components/src/DragAndDropImageSelector"

// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

import CustomisableUserProfilesController, {
    Props,
} from "./CustomisableUserProfilesController";


// Customizable Area End

export default class CustomisableUserProfileEDit extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
                <StyleduserProfileConatainer>
                    {this.state.isLoading ? 
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",width:"100%", height:"100vh"}}>
                        <CircularProgress sx={{color:'#0E0E0E', display:"flex", justifyContent:"center", alignItems:"center"}}/>
                    </Box> 
                    :
                    <StyledInnerConatiner data-testid="profileInnerCOntainer">
                        <Box>
                            <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={'1vh'}>
                                <UserProfileTypo>User Profile</UserProfileTypo>
                                <Box display={'flex'} >
                                    <HomeRouteTypo>Home Page</HomeRouteTypo>
                                    <img src={WriteArrow} alt="writteArrow"/>
                                    <UserRouteTypo>User Profile</UserRouteTypo>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex', gap:"24px", alignItems:"center", maxHeight:"140px", position:"relative"}} >
                            <StyledProfileImageContainer isActive={this.state.uploadImage}>
                                {this.state.profileImageUrl?<img src={this.state.profileImageUrl} data-testid="userProfileImage" alt="deefault" style={{height:"100%", width:"100%",borderRadius:"50%", objectFit:"fill"}} />:
                                <img src={userDefaultImg} style={{height:"100%", width:"100%",borderRadius:"50%", objectFit:"cover"}} /> }
                            </StyledProfileImageContainer>
                            <StyledProfilePageHeading isActive={this.state.uploadImage}>
                                <ProfileUserNameTypo isActive={this.state.uploadImage}>{this.state.fullName}</ProfileUserNameTypo>
                                <ProfileStyledEditButton
                                    data-testid='editProfileImageButton'
                                    isActive={this.state.uploadImage}
                                    size="small"
                                    startIcon={<EditIcon style={{ color: 'inherit', }} />}                                    
                                    onClick={this.handleOpenProfileModel.bind(this)}
                                >
                                    Edit
                                </ProfileStyledEditButton>
                            </StyledProfilePageHeading>
                        </Box>
                        <EditableContainer  isActive={this.state.isPersonalInfoEdit} data-testid ='personalInfoContainer'>
                            {!this.state.isPersonalInfoEdit && <Box sx={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                                <StyledProfileSectionHeader isActive={this.state.isPersonalInfoEdit}>
                                    Personal Information
                                </StyledProfileSectionHeader>
                                <Button
                                    data-testid="personalInfoIEditButton"
                                    variant="outlined"
                                    startIcon={<EditIcon style={{ color: '#000000', fontSize:"18px" }} />}
                                    size="small"
                                    sx={{ 
                                        textTransform: 'none', 
                                        border: '1px solid #000000',
                                        color: '#000000',
                                        padding:"0px",
                                        textAlign:"center"                                        
                                    }}
                                    onClick={this.editProfileInfo.bind(this)}
                                >
                                    Edit
                                </Button>                               
                            </Box>}
                            <Box 
                                sx={{
                                    background:this.state.isPersonalInfoEdit?"transparent":"#F4F4F4",
                                    padding:"24px",
                                    gap:"16px",
                                    display:"flex",
                                    flexDirection:"column",
                                    borderRadius:"12px",
                                }}
                            >
                            {this.state.isPersonalInfoEdit && <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",}} >
                                <StyledProfileSectionHeader isActive={this.state.isPersonalInfoEdit}>
                                    Personal Information
                                </StyledProfileSectionHeader>
                                <Button
                                    data-testid="savePersonalInfoButton"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        textTransform: 'none',
                                        border: '1px dashed #FFFFFF',
                                        color: '#FFFFFF',
                                        fontWeight:"600",
                                        padding:'0px',
                                        "&:hover":{
                                            border: '1px dashed #FFFFFF',
                                        }
                                    }}
                                    onClick={this.saveProfileInfo.bind(this)}
                                >
                                    Save
                                </Button>
                            </Box>}
                             <GridContainer sx={{ padding:"0px"}}>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px",}}>
                                    <StyledProfileFieldLabel isActive={this.state.isPersonalInfoEdit} >First  Name </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid="fistNameInput"
                                     value={this.state.first_name}
                                     onChange={this.handleChangeFirstName.bind(this)}
                                     disabled={!this.state.isPersonalInfoEdit}
                                    />
                                </Grid>
                                 <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isPersonalInfoEdit}>Last Name </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid="lastNameInput"
                                     value={this.state.lastName}
                                     onChange={this.handleChangeLastName.bind(this)}
                                     disabled={!this.state.isPersonalInfoEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isPersonalInfoEdit}>Position</StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid="positionInput"
                                     disabled={!this.state.isPersonalInfoEdit}
                                     value={this.state.position}
                                     onChange={this.handleChangePosition.bind(this)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isPersonalInfoEdit}>E-Mail Address </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     value={this.state.email}
                                     disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel  data-error={this.state.phoneNumberError} isActive={this.state.isPersonalInfoEdit}>Phone</StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                    data-testid="phoneInput"
                                     disabled={!this.state.isPersonalInfoEdit}
                                     value={this.state.phoneNumber}
                                     onChange={this.handleChangePhone.bind(this)}
                                     helperText={this.state.phoneNumberErrorMessage}
                                     error={this.state.phoneNumberError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isPersonalInfoEdit}>Username</StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     value={this.state.user_name}
                                     disabled
                                    />
                                </Grid> 
                            </GridContainer> 
                         </Box>
                        </EditableContainer>
                        
                        <EditableContainer  isActive={this.state.isAddressEdit} data-testid ='addressContainer'>
                            {!this.state.isAddressEdit && <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",}} >
                                <StyledProfileSectionHeader isActive={this.state.isAddressEdit}>
                                    Address
                                </StyledProfileSectionHeader>
                                <Button
                                    data-testid ='addressEditButton'
                                    variant="outlined"
                                    startIcon={<EditIcon style={{ color: '#000000', fontSize:"18px" }} />}
                                    size="small"
                                    sx={{ 
                                        textTransform: 'none', 
                                        border: '1px solid #000000',
                                        color: '#000000',
                                        padding:"0px",
                                        textAlign:"center"                                        
                                    }}
                                    onClick={this.editAddress.bind(this)}
                                >
                                    Edit
                                </Button>
                            </Box>}
                            <Box 
                                sx={{
                                    background:this.state.isAddressEdit?"transparent":"#F4F4F4",
                                    padding:"24px",
                                    gap:"16px",
                                    display:"flex",
                                    flexDirection:"column",
                                    borderRadius:"12px",
                                }}
                            >
                            {this.state.isAddressEdit && <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",}} >
                                <StyledProfileSectionHeader isActive={this.state.isAddressEdit}>
                                    Address
                                </StyledProfileSectionHeader>
                                <Button
                                    data-testid ='addressSaveButton'
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        textTransform: 'none',
                                        border: '1px dashed #FFFFFF',
                                        color: '#FFFFFF',
                                        fontWeight:"600",
                                        padding:'0px',
                                        "&:hover":{
                                            border: '1px dashed #FFFFFF',
                                        }
                                    }}
                                    onClick={this.saveAddress.bind(this)}
                                >
                                    Save
                                </Button>
                            </Box>}
                            <AddressGridContainer sx={{ padding:"0px"}}>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px",}}>
                                    <StyledProfileFieldLabel isActive={this.state.isAddressEdit} >Country </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid ='countryInput'
                                     value={this.state.country}
                                     onChange={this.handleChangeCountry.bind(this)}
                                     disabled={!this.state.isAddressEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isAddressEdit}>City/State </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid ='cityInput'
                                     value={this.state.cityState}
                                     onChange={this.handleChangeCity.bind(this)}
                                     disabled={!this.state.isAddressEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isAddressEdit}>Postal Code</StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid ='postalCodeInput'
                                     value={this.state.postCode}
                                     onChange={this.handleChangePostCode.bind(this)} 
                                     disabled={!this.state.isAddressEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{display:"flex", flexDirection:"column", gap:"4px"}}>
                                    <StyledProfileFieldLabel isActive={this.state.isAddressEdit}>Tax-ID </StyledProfileFieldLabel>
                                    <StyledProfileTextField
                                     data-testid ='taxIdInput'
                                     value={this.state.taxId}
                                     onChange={this.handleChangeTaxId.bind(this)}
                                     disabled={!this.state.isAddressEdit}
                                    />
                                </Grid>
                            </AddressGridContainer>
                         </Box>
                        </EditableContainer>
                        <Modal
                            open={this.state.uploadImage}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            data-testid='uploadImageModal'
                        ><Box
                        sx={{
                            display:"flex",
                            background:"#212121",
                            maxHeight:'fit-content',
                            width:"fit-content",
                            borderRadius:"12px",
                            margin:"10px",
                            position:"relative",
                        }}
                         >{!this.state.selectedImage?
                            <StyledModelContainer>
                                <Box sx={{display:"flex", justifyContent:"center", width:'100%', flexDirection:"column" }}>
                                    <BoldTypo>Change Avatar</BoldTypo>
                                    <MediumTypo>Select and upload the files of your choice</MediumTypo>
                                </Box>
                                    <DragAndDropImageSelector onSelect={(file)=>{this.setState({selectedImage:file})}} data-testid='imageSelector'>
                                        <Box 
                                            sx={{
                                                border:'2px dashed #828282',
                                                display:"flex",
                                                flexDirection:"column",
                                                justifyContent:"center",
                                                alignItems:"center",
                                                borderRadius:'12px',
                                                padding:'clamp(19px, 3.3vw, 32px)',
                                                gap:'clamp(19px, 3.3vw, 32px)'
                                            }}>
                                            <img src={cloudUploadIcon}/>
                                            <Box
                                            sx={{
                                                display:"flex",
                                                justifyContent:"center",
                                                alignItems:"center",
                                                flexDirection:"column",
                                                gap:"6px"
                                            }}
                                            >
                                            <BoldTypo>Choose a file or drag & drop it here</BoldTypo>
                                            <MediumTypo>JPEG, PNG, PDG formats, up to 10MB</MediumTypo>
                                            </Box>
                                            <Box
                                                sx={{
                                                    border: '1px solid #B7B7B7',
                                                    color: '#FFFFFF',
                                                    padding: '6px',
                                                    borderRadius: '8px',
                                                    fontFamily: 'Open Sans',
                                                    width:"clamp(98px, 25.3vw, 196px)",
                                                    height:"clamp(29px, 6.3vw, 42px)",
                                                    display:'flex',
                                                    justifyContent:"center",
                                                    alignItems:"center"
                                                }}
                                            >
                                                Browse File
                                            </Box>
                                        </Box>
                                    </DragAndDropImageSelector>
                            </StyledModelContainer>:
                            <ImageContainer data-testid={'selectedImagePreviewContainer'}>
                                <StyledImageBox>
                                <Image src={URL.createObjectURL(this.state.selectedImage)} alt="Circular" />
                                <CircularMask src={profileImageCover}/>
                                </StyledImageBox> 
                                <StyledImageButtonContainer>
                                    <StyledImageButton onClick={this.handleCancelUpdateImage.bind(this)} data-testid='cancelUploadImage' >
                                        Cancel
                                    </StyledImageButton>
                                    <StyledImageButton
                                        sx={{border:"1px solid #B7B7B7"}}
                                        onClick={this.updateProfileImage.bind(this)}
                                        data-testid='uploadImageButton'
                                    >
                                        {'Set Photo'}
                                    </StyledImageButton>
                                </StyledImageButtonContainer>
                            </ImageContainer>}
                            {!this.state.selectedImage &&<StyledCloseIcon onClick={this.handleCloseProfileModel.bind(this)}/>}
                        </Box>
                        </Modal>
                    </StyledInnerConatiner>}
                </StyleduserProfileConatainer>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const ImageContainer = styled(Box)({
    display:"flex",
    height:"fit-content",
    width:"fit-content",
    alignItems:"center",
    gap:"32px",
    padding:"32px",
    flexDirection:"column",
    '@media (max-width: 438px)': {
        padding:'clamp(20px, 7.3vw, 32px)',
        gap:'clamp(15px, 5vw, 32px)',
    },
})
const StyledImageButton = styled(Button)({
    color:"#FFFFFF",
    textTransform:"none",
    width:"196px",
    height:"42px",
    borderRadius:"8px",
    padding:"6px",
    fontFamily:"Open Sans",
    fontSize:"14px",
    fontWeight:600,
    background:"transparent",
    '@media (max-width: 525px)': {
        width:'clamp(96px, 33vw, 196px)',
        height:'clamp(29px, 10vw, 42px)',
    },
})
const StyledImageButtonContainer= styled(Box)({
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    gap:"32px",
    '@media (max-width: 525px)': {
        width:'100%',
        gap:"20px"
    },
})
const StyledImageBox = styled(Box)({
    position: 'relative',
    width: '344px',  
    height: '344px',
    '@media (max-width: 437px)': {
        width:'clamp(300px, 65vw, 344px)',
        height:'clamp(300px, 65vw, 344px)',
    },
    '@media (max-width: 378px)': {
        width:'clamp(278px, 65vw, 344px)',
        height:'clamp(278px, 65vw, 344px)',
    },
    '@media (max-width: 365px)': {
        width:'clamp(248px, 65vw, 344px)',
        height:'clamp(248px, 65vw, 344px)',
    },
    '@media (max-width: 333px)': {
        width:'clamp(219px, 65vw, 344px)',
        height:'clamp(219px, 65vw, 344px)',
    },
});
  
const CircularMask = styled('img')({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    objectFit:"fill"
});
  
const Image = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit:"fill",
    width: '100%',  
    height: '100%', 
});

const StyledCloseIcon = styled(CloseOutlinedIcon)({
    position: "absolute",
    color: "#878787",
    fontSize: "24px",
    cursor: "pointer",
    zIndex:5,
    top:11,
    right:11,
})
const StyleduserProfileConatainer = styled(Box)({
    display: "flex",
    background: "#FFFFFF",
})
const GridContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
    gap: '24px',
    padding: theme.spacing(1),
    '@media (max-width: 1742px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        gap: 'clamp(24px, 30vw, 32px)'
    },
    '@media (max-width: 1588px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
    '@media (max-width: 1440px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
    },
    '@media (max-width: 1372px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '24px'
    },
    '@media (max-width: 409px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
    '@media (max-width: 300px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  }));

  const AddressGridContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(600px, 1fr))',
    gap: 'clamp(24px, 30vw, 32px)',
    padding: theme.spacing(1),
    '@media (max-width: 1556px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
    },
    '@media (max-width: 1352px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
        gap: '24px'
    },
     '@media (max-width: 1310px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    },
    '@media (max-width: 1144px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },  
    '@media (max-width: 409px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
    '@media (max-width: 300px)': {
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  }));

const StyledInnerConatiner = styled(Box)({
    display:"flex", 
    flexDirection:"column", 
    gap:"24px",
    width: '100%',
    height: "100%",
    padding:'30px',
    '@media (max-width: 317px)': {
        padding:'clamp(17px, 7vw, 30px)',
    },
})

const UserProfileTypo = styled(Typography)({
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '24px',
    color: '#000000',
})



const HomeRouteTypo = styled(Typography)({

    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '18px',
    color: '#909090',
})

const UserRouteTypo = styled(Typography)({

    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '18px',
    color: '#000000',
})




const BoldTypo = styled(Typography)({
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign:"center",  
    fontSize: '20px',
    color: '#FFFFFF',
    '@media (max-width: 562px)': {
        fontSize:'clamp(16px, 4vw, 20px)',
    },
})

const MediumTypo = styled(Typography)({
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '16px',
    color: '#B0B0B0',
    textAlign:"center",
    '@media (max-width: 562px)': {
        fontSize:'clamp(12px, 3vw, 16px)',
    },
})

const StyledModelContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    flexDirection:'column',
    background: "#212121",
    width:'clamp(260px, 71vw, 722px)',
    padding: "40px",
    overflowY: "auto",
    borderRadius: '12px',
    gap:'clamp(19px, 3.3vw, 32px)',
    '@media (max-width: 365px)': {
        width:'clamp(200px, 65vw, 722px)',
    },
    '@media (max-width: 313px)': {
        width:'clamp(170px, 55vw, 722px)',
    },
    '@media (max-height: 530px)': {
        maxHeight: '80vh',
    },
    '@media (max-height: 430px)': {
        maxHeight: '76vh',
    },
    '@media (max-height: 350px)': {
        maxHeight: '71vh',
    },
    '@media (max-height: 290px)': {
        maxHeight: '65vh',
    },
    '@media (max-height: 250px)': {
        maxHeight: '50vh',
    },
    '@media (max-height: 200px)': {
        maxHeight: '40vh',
    },
    '@media (max-height: 100px)': {
        maxHeight: '25vh',
    },
    "::-webkit-scrollbar": {
        display: 'none',
    },
    "-ms-overflow-style": 'none',
    "scrollbar-width": 'none',
});

// Customizable Area End
