// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  FormControl,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailAccountRegistrationController, {
    Props
  } from "./EmailAccountRegistrationController";
import { validCheck, invalidCheck, logoImage } from "./assets";
import CustomGoogleLogInButton from "../../../components/src/CustomGoogleLoginButton";
import { GoogleOAuthProvider } from '@react-oauth/google';

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
        <Box sx={outer} data-test-id='signUpFormContainer' onClick ={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {event.stopPropagation()}}>   
        <StyledContainer>
            <StyledInnerContainer>                
                <StyledLeftContainer>
                     <img src={logoImage} alt="" height={'34px'} width={'108px'} onClick ={(event) => {event.stopPropagation()}}/>
                    <Box style={{display:'flex', flexDirection:"column", gap:"8px"}}>
                   
                        <Typography style={{fontFamily:"Open Sans", fontSize:"32px" , fontWeight:600, color:"#364AC6"}}>
                        Welcome to DatAI!
                        </Typography>
                        <Typography style={{fontFamily:"Open Sans", fontSize:"18px" , fontWeight:400, color:"#4D4D4D"}}>
                        Create Your Account
                        </Typography>
                    </Box>
                    <MainBox >
                        <InputBox >
                        <StyledFormControl>
                            <StyledLabel>Username</StyledLabel>
                            <StyledTextField
                                placeholder="Username"
                                data-test-id='usernameInput'
                                onChange={this.handleChangeUsername.bind(this)}
                                value={this.state.registrationUsername}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.emailError}>
                            <StyledLabel>Email Address</StyledLabel>
                            <StyledTextField
                                data-test-id='emailInput'
                                aria-invalid={this.state.emailError}
                                placeholder="Email Address"
                                onChange={this.handleChangeEmail.bind(this)}
                                value={this.state.registrationEmail}
                                error={this.state.emailError}
                                helperText={this.state.emailErrorMessage}
                            />
                        </StyledFormControl>
                        </InputBox>
                        <InputBox >
                        <StyledFormControl>
                            <StyledLabel>First Name</StyledLabel>
                            <StyledTextField
                                data-test-id='firstNameInput'
                                placeholder="First Name"
                                onChange={this.handleChangeFirstName.bind(this)}
                                value={this.state.firstName}
                            />
                        </StyledFormControl>
                        <StyledFormControl>
                            <StyledLabel>Last Name</StyledLabel>
                            <StyledTextField
                                data-test-id='lastNameInput'
                                placeholder="Last Name"
                                onChange={this.handleChangeLastName.bind(this)}
                                value={this.state.lastName}
                            />
                        </StyledFormControl>
                        </InputBox>
                        <InputBox>
                        <StyledFormControl error={this.state.passwordError}>
                            <StyledLabel>Password</StyledLabel>
                            <StyledTextField
                                data-test-id='passwordInput'
                                type={this.state.showPassword?'text':"password"}
                                placeholder="Password"
                                onChange={this.handleChangePassword.bind(this)}
                                value={this.state.registrationPassword}
                                error={this.state.passwordError}
                                aria-invalid={this.state.passwordError}
                                hidden
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment data-testid='showPasswordContainer' data-show={this.state.showPassword} position="end">
                                        {this.state.showPassword ? 
                                            <VisibilityOutlinedIcon data-testid="hidePassword" onClick={()=>{this.setState({showPassword:false})}}/>:
                                            <VisibilityOffOutlinedIcon data-testid="showPassword" onClick={()=>{this.setState({showPassword:true})}}/>}
                                      </InputAdornment>
                                    ),
                                }}
                            />
                         
                        </StyledFormControl>
                        <StyledFormControl error={this.state.passwordError}>
                            <StyledLabel>Repeat password</StyledLabel>
                            <StyledTextField
                                data-test-id='confirmPasswordInput'
                                type={this.state.showConfirmPassword?'text':"password"}
                                placeholder="Repeat password"
                                value={this.state.registrationConfirmPassword}
                                onChange={this.handleChangeConfirmPassword.bind(this)}
                                error={this.state.passwordError}
                                aria-invalid={this.state.passwordError}
                                helperText={this.state.passwordErrorMessage}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" data-testid='showConfirmPasswordContainer' data-show={this.state.showConfirmPassword} >
                                        {this.state.showConfirmPassword ? 
                                            <VisibilityOutlinedIcon data-testid="hideConfirmPassword" onClick={()=>{this.setState({showConfirmPassword:false})}}/>:
                                            <VisibilityOffOutlinedIcon data-testid="showConfirmPassword" onClick={()=>{this.setState({showConfirmPassword:true})}}/>}
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </StyledFormControl>
                        </InputBox>
                        <BottomConditionBox>
                        <Box style={{display:"flex", flexDirection:"column", gap:"6px",width:'368px'}}>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='special-char'src={this.state.hasSpecialCharacter? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"At least one special character"}</Typography>
                            </Box>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='valid-length'src={this.state.isValidPasswordLength? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"Minimum character length is 8 characters"}</Typography>
                            </Box>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='one-number'src={this.state.hasNumber? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"At least one number"}</Typography>
                            </Box>
                        </Box>
                        <PrivacyPolicyBox >
                            <Box 
                                data-test-id='privacyCheck'
                                style={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    height:"24px",
                                    width:'24px',
                                    border:'1px solid #64748B',
                                    borderRadius:"6px"
                                }}
                                onClick={()=>{this.setState({privacyCheck:!this.state.privacyCheck})}}
                            >
                                {this.state.privacyCheck && <img src={validCheck} data-test-id='privacyImage' style={{height:'16px', width:"16px"}}/>}
                            </Box>
                            <Typography style={{fontFamily:"Open Sans",fontSize:'12px', width:'255px', fontWeight:400, color:"#000000"}}>
                                By registering you agree with DatAI’s 
                                <span data-testid='privacyPolicyLink' style={{color:"#4865FF",fontWeight:600, cursor:"pointer"}} onClick={this.handleClickOnPrivacy.bind(this)}> Privacy Policy </span> and 
                                <span data-testid='termsConditionLink'onClick={this.handleClickOnTermsAndConditions.bind(this)} style={{color:"#4865FF",fontWeight:600, cursor:"pointer"}}> Terms and Conditions </span>
                            </Typography>
                        </PrivacyPolicyBox>
                        </BottomConditionBox>
                        <RegisterGoogleButtonBox>
                       
                        <Box 
                            style={{
                                display:'flex',
                                gap:"8px",
                                justifyContent:"center",
                                alignItems:"center",
                                height:"48px",
                                minHeight:"35px",
                            }}
                            sx={{
                                width: {
                                    sm: "368px",
                                    xs: "auto"
                                }
                            }}
                        >
                            <GoogleOAuthProvider clientId='421316066014-5d53ulirpigeiqq2bddkrslrhua74rfh.apps.googleusercontent.com'>
                                <CustomGoogleLogInButton name="Sign up" data-testid="googleSignUpButton" onSuccess={this.handleGoogleLoginSuccess.bind(this)} onError={this.handleGoogleLoginFailure.bind(this)}/>    
                            </GoogleOAuthProvider>
                            
                        </Box>
                        <Button
                            data-test-id="registerButton"
                            fullWidth
                            style={{
                                color:"#ffff",
                                background:this.state.privacyCheck?"#202020":"#C2C2C2",
                                borderRadius:"8px",
                                textTransform:"capitalize",
                                minHeight:"42px",
                                height:"48px",
                                fontSize:"16px",
                                fontWeight:"600",

                            }}
                            disabled={!this.state.privacyCheck}
                            onClick={this.handleRegister.bind(this)}
                            sx={{
                                width: {
                                    sm: "368px",
                                    xs: "auto"
                                }
                            }}
                        >
                            Sign up
                        </Button>
                        </RegisterGoogleButtonBox>
                        <Typography style={{fontFamily:"Open Sans", fontSize:"12px",fontWeight:400,color:"#000000",textAlign:"center",marginTop:'24px',paddingBottom:'32px',boxSizing:'border-box'}}>
                            Already have an account? <span style={{fontWeight:700, cursor:"pointer"}} data-test-id='goToLogin' onClick={this.sendOpenLoginFormMessage}> Log In</span>
                        </Typography>
                    </MainBox>
                </StyledLeftContainer>
                          
            </StyledInnerContainer>
        </StyledContainer> 
            <StyledCloseIcon data-testid={"closeButton"}  onClick={this.sendCloseFormMessage}/>
        </Box>         
    );
  }
}
const StyledContainer = styled(Box)({
    display:"flex",
    background:"#FFFFFF",
    maxHeight:'85vh',
    width:"fit-content",
    borderRadius:"24px",
    padding:"32px 48px 32px 48px",
    '@media (max-width: 505px)': {
        flexDirection: "column",
        padding:"32px 15px 32px 15px",
        width: "320px"
    },
 
    overflowY:"auto",
      '@media (max-height: 615px)': {
        maxHeight:'82vh',
      },
      '@media (max-height: 530px)': {
        maxHeight:'80vh',
      },
      '@media (max-height: 430px)': {
        maxHeight:'76vh',
      },
      '@media (max-height: 350px)': {
        maxHeight:'71vh',
      },
      '@media (max-height: 290px)': {
        maxHeight:'65vh',
      },
      '@media (max-height: 250px)': {
        maxHeight:'50vh',
      },
      '@media (max-height: 200px)': {
        maxHeight:'40vh',
      },
      '@media (max-height: 100px)': {
        maxHeight:'25vh',
      },
    "::-webkit-scrollbar": {
        display: 'none',
      },
      "msOverflowStyle": 'none',
      "scrollbarWidth": 'none'
});
const outer ={
    display:"flex",
    background:"#FFFFFF",
    maxHeight:'fit-content',
    width:"fit-content",
    borderRadius:"24px",
    margin:"10px",
    position:"relative",
    border:"10px solid #FFFFFF",
    '@media (max-width: 500px)': {
        width:'320px',
      },
}
const StyledCloseIcon = styled(CloseOutlinedIcon)({
    position: "absolute",
    color: "#0F172A",
    fontSize: "24px",
    cursor: "pointer",
    zIndex:5,
    top:11,
    right:11,
});
const StyledInnerContainer=styled(Box)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",    
    gap:'40px',
    height:"100%",
    '@media (max-width: 858px)': {
        flexDirection: 'column-reverse',
        justifyContent:"start",
        gap:"0px",
    },
    
})

const StyledLeftContainer=styled(Box)({
    display:"flex",
    flexDirection :'column',
    gap:"36px",
    height:"100%",
    '@media (max-width: 858px)': {
        width:"100%",
    },
})
const MainBox=styled(Box)({
    display:'flex',
    flexDirection:"column",
    gap:"12px",
    '@media (max-width: 900px)': {
        gap:'32px',
    },
})
const StyledRightContainer=styled(Box)({
    display:'flex',
    flexDirection :'column',
    maxWidth:"456px",
    justifyContent:"center",
    height: '151px',
})
const buttonText={
    fontFamily:"Open Sans",
    fontSize:"14px",
    fontWeight:400,
    color:"#000000"
}

const StyledTextField = styled(TextField) ({
    '& .MuiOutlinedInput-root': {
     fontFamily: "Open Sans",
      height: "48px",
      borderRadius: "8px",
      width:'368px',
      '@media (max-width: 500px)': {
        width:'auto',
    },
      '& fieldset': {
        borderColor: '#6F6F6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F6F6F',
      },
      '&:hover fieldset': {
        borderColor: '#6F6F6F',
      },
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root':{
        '&.Mui-focused fieldset': {
            borderColor: '#E04545',
          },
          '&:hover fieldset': {
            borderColor: '#E04545',
          },
          '& fieldset': {
            borderColor:'#E04545',
          },
    },
    '& .MuiInputBase-input': {
      height: '100%', 
    },
});

const StyledFormControl = styled(FormControl)({
    display: 'flex',
    flexDirection: "column",
    gap: "4px",
    '& .MuiFormHelperText-root': {
      marginLeft:"0px",
      fontWight:600
    },
});
const InputBox =styled(Box)({
    display: 'flex',
    gap:'32px',
    '@media (max-width: 900px)': {
        flexDirection: "column",
    },
})
const BottomConditionBox=styled(Box)({
    alignItems:'flex-end',
    display: 'flex',
    gap:'32px',
    '@media (max-width: 900px)': {
        flexDirection: "column",
        alignItems:'flex-start',
    },
})
const PrivacyPolicyBox=styled(Box)({
    display: "flex",
    gap: "12px",
})
const RegisterGoogleButtonBox=styled(Box)({
    gap:'32px',
    display: 'flex',
    '@media (max-width: 900px)': {
        flexDirection: "column",
      
    },
})
const StyledLabel = styled(Typography)({
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    color:"#000000",
    '&[data-error="true"]':{
        color: '#E04545',
    },
});
// Customizable Area End
