// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  keyframes
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { StyledFormControl, StyledDateContainer } from "../../../components/src/StyledComponents"
import InstagramPreview from "./components/InstagramPreview"
import FacebookPreview from "./components/FacebookPreview"
import ImageGenerator from "./components/ImageGenerator"
import ContentManagementEditController, {
  Props,
} from "./ContentManagementEditController";
import { instagramColor, loadingIcon } from "./assets";
import DatePickerDropDown from "./components/DatePickerDropDown";
import RegenerateModal from "./components/RegenerateModal";

export default class ContentManagementEdit extends ContentManagementEditController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const rotate = keyframes`from {
      transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }`;
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", padding: "32px", }}>
          <Box sx={webStyle.pageHeadingContainer}>
            <ArrowBackIcon onClick={this.props.navigation.goBack} />
            <StyledBoostButton>Boost</StyledBoostButton>
            <Typography sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "18px" }}>{this.state.contentName}</Typography>
            <EditOutlinedIcon sx={{ display: "none" }} />
          </Box>
          <StyledContentContainer>
            <StyledContentPreviewContainer>
              <Box sx={{display:"none"}}>
                <FacebookPreview
                  images={[]}
                  videos={[]}
                  caption={"Dummy Description for fac book"} />
              </Box>
              <InstagramPreview data-testid='postPreview' videos={this.state.contentVideo} images={this.state.contentImages} caption={this.state.contentCaptions.length > 0 ? this.state.contentCaptions[this.state.activeCaptionIndex].content : ""} />
            </StyledContentPreviewContainer>
            <StyledContentCustomizationContainer>
              <StyledPosStatusContainer>
                <Box sx={{ display: 'flex', width: "fit-content", gap: "16px" }}>
                  <img src={instagramColor} height={'20px'} width={'20px'} />
                  <Typography sx={{ color: "#7153B8" }}>Instagram</Typography>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      background: "#FFFBAA",
                      borderRadius: "100px",
                      padding: "2px 8px 2px 8px",
                      fontSize: '14px',
                      fontFamily: "Open Sans",
                      color: "#4D4D4D",
                      fontWeight: 400
                    }}
                  >{this.state.contentDetails?.attributes.post_type}</Typography>
                </Box>
                <Box sx={{ display: 'flex', width: "fit-content", gap: "16px" }}>
                  <Typography sx={{ fontSize: '14px', fontFamily: "Open Sans", fontWeight: 400 }}>Status:</Typography>
                  <Typography sx={{ fontSize: '14px', fontFamily: "Open Sans", fontWeight: 600 }}>{this.state.postStatus}</Typography>
                </Box>
              </StyledPosStatusContainer>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box sx={{ display: 'flex', gap: "8px", alignItems: "center" }}>
                  <NavigateBeforeIcon onClick={this.goToPreviousCaption.bind(this)} data-testid='previousContentButton' />
                  <Typography sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "18px", textAlign: "center" }}>
                    {`Caption ${this.state.activeCaptionIndex + 1}/${this.state.contentCaptions.length}`}
                  </Typography>
                  <NavigateNextIcon onClick={this.goToNextCaption.bind(this)} data-testid='nextContentButton' />
                </Box>
                {this.state.isRegeneratingCaption ?
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: 'center',
                      alignItems: "center",
                      borderRadius: "8px",
                      border: "1px solid #B3B3B3",
                      height: "125px",
                      background: "#EFEFEF",
                      marginBottom: '18QXWQpx'
                    }}>
                    <Box
                      component={'img'}
                      src={loadingIcon}
                      sx={{
                        height: '34px',
                        width: '34px',
                        animation: `${rotate} 2s linear infinite`
                      }}
                    />
                    <Typography sx={{ fontFamily: "Open Sans", color: "#767676", fontWeight: 400, fontSize: '16px', textAlign: "center" }}>Regenerating</Typography>
                  </Box> :
                  <StyledFormControl isRightAlignText>
                    <TextField
                      helperText={"Max 300 characters"}
                      multiline
                      data-testid='contentCaptionInput'
                      rows={4}
                      value={this.state.contentCaptions.length > 0 ? this.state.contentCaptions[this.state.activeCaptionIndex].content.replace(/"/g, '') : ""}
                      disabled={!this.state.isEditContent}
                      onChange={this.handleChangeCaption.bind(this)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          '& .MuiInputBase-input': {
                            fontFamily: "Open Sans",
                            fontWeight: 400,
                            fontSize: '14px',
                            color: "#4D4D4D",
                          },
                          '& fieldset': {
                            borderColor: '',
                            border: "1px solid #DDDDDDD"
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                          '& .MuiInputBase-input.Mui-disabled': {
                            background: "transparent"
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#EFEFEF',
                          },
                        },
                      }}
                    />
                  </StyledFormControl>}
                <StyledGenerateEditButtonContainer>
                  <Button
                    data-testid='editManuallyButton'
                    startIcon={this.state.isEditContent ? <CloseOutlinedIcon /> : <EditOutlinedIcon />}
                    variant="contained"
                    fullWidth
                    onClick={this.state.isEditContent ? this.saveManuallyWrittenCaption.bind(this) : this.handleEditManually.bind(this)}
                    sx={{
                      background: "#000000",
                      textTransform: "none",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      borderRadius: "8px",
                      "&:hover": {
                        background: "#000000"
                      }
                    }}
                  >
                    {this.state.isEditContent ? "Close edit mode" : "Edit manually"}
                  </Button>
                  <Button
                    data-testid='regenerateContentButton'
                    startIcon={<RefreshIcon style={{ fontSize: 27 }} />}
                    fullWidth
                    onClick={this.regenerateCaption.bind(this)}
                    disabled={this.state.isRegeneratingCaption}
                    sx={{
                      background: "#ffffff",
                      textTransform: "none",
                      color: 'black',
                      border: "2px solid black",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      borderRadius: "8px",
                      "&:hover": {
                        background: "#ffffff"
                      }
                    }}
                  >
                    Regenerate
                  </Button>
                </StyledGenerateEditButtonContainer>
                {this.state.contentImages.length > 0 && <StyledGenerateEditButtonContainer>
                  <Typography sx={{ fontFamily: "Open Sans", fontSize: "18px", fontWeight: 600, color: "#4D4D4D" }}>
                    Images
                  </Typography>
                  <Button
                    startIcon={<EditOutlinedIcon />}
                    variant="contained"
                    sx={{
                      background: "#000000",
                      textTransform: "none",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      borderRadius: "8px",
                      "&:hover": {
                        background: "#000000"
                      },
                      display: "none",
                    }}
                  >
                    Add image
                  </Button>
                </StyledGenerateEditButtonContainer>}
              </Box>
              {this.state.contentImages.map((image, index) => (
                <ImageGenerator
                  key={index}
                  data-testid={`imageGenerator${index}`}
                  isRegenerating={this.state.regenerateImageId === image.id.toString() && this.state.isRegeneratingImage}
                  image={image}
                  onRegenerate={this.handleRegenerateImageModalOpen.bind(this)}
                  onSaveToLibrary={this.handleSaveImageToLibrary.bind(this)}
                  onDelete={this.deleteContentImage.bind(this)}
                  onEdit={this.handleEditImage.bind(this)}
                  onChooseFromGallery={this.handleChooseImageFromLibrary.bind(this)} />
              ))}
              {this.state.contentVideo.length > 0 && <StyledGenerateEditButtonContainer>
                <Typography sx={{ fontFamily: "Open Sans", fontSize: "18px", fontWeight: 600, color: "#4D4D4D" }}>
                  Videos
                </Typography>
              </StyledGenerateEditButtonContainer>}
              {this.state.contentVideo.map((video, index) => (
                <ImageGenerator
                  key={index}
                  data-testid={`videoGenerator${index}`}
                  isRegenerating={this.state.regenerateVideoId === video.id.toString() && this.state.isRegeneratingVideo}
                  image={video}
                  onRegenerate={this.handleRegenerateVideoModalOpen.bind(this)}
                  onSaveToLibrary={this.handleSaveImageToLibrary.bind(this)}
                  onDelete={this.deleteContentVideo.bind(this)}
                  onEdit={this.handleEditImage.bind(this)}
                  onChooseFromGallery={this.handleChooseImageFromLibrary.bind(this)} />
              ))}
              <Typography sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "18px", color: "#4D4D4D" }}>
                {this.state.contentDetails?.attributes.published_at ? "Posted on" : 'Scheduled to Post on'}:</Typography>
              {this.state.contentDetails?.attributes.published_at ? <>
                <StyledDateContainer data-testid='postedTimeContainer'>
                  <StyledPostDateContainer>
                    <Box sx={{ width: "fit-content" }}>
                      <Typography sx={webStyle.dropdownLabel}> Day</Typography>
                      <Box sx={webStyle.publishDate}>{new Date(this.state.contentDetails?.attributes.published_at).getDate()}</Box>
                    </Box>
                    <Box sx={{ width: "fit-content" }}>
                      <Typography sx={webStyle.dropdownLabel}> Mnth</Typography>
                      <Box sx={webStyle.publishDate}>{new Date(this.state.contentDetails?.attributes.published_at).getMonth() + 1}</Box>
                    </Box>
                    <Box sx={{ width: "fit-content" }}>
                      <Typography sx={webStyle.dropdownLabel}> Year</Typography>
                      <Box sx={webStyle.publishDate}>{new Date(this.state.contentDetails?.attributes.published_at).getFullYear()}</Box>
                    </Box>
                  </StyledPostDateContainer>
                  <Box >
                    <Typography sx={{
                      fontFamily: "Open Sans",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#4D4D4D",
                      marginBottom: "4px"
                    }}> Time </Typography>
                    <Box sx={{ display: "flex", gap: "12px", width: "fit-content" }}>
                      <Box sx={webStyle.publishDate}>{this.getTimeFromDate(new Date(this.state.contentDetails?.attributes.published_at))}</Box>
                      <Box sx={{ ...webStyle.publishDate, width: "73px" }}>{new Date(this.state.contentDetails?.attributes.published_at).getHours() > 12 ? "PM" : "AM"}</Box>
                    </Box>
                  </Box>
                </StyledDateContainer>
                <StyledPostActionButtonContainer>
                  <StyledActionButton
                    data-testid='removeContentButton'
                    variant="contained"
                    sx={{
                      background: "#000000",
                      color: "#ffffff",
                      '&:hover': {
                        background: "#000000"
                      }
                    }}
                    onClick={this.handleRemovePost.bind(this)}
                  >
                    Remove Post from channel
                  </StyledActionButton>
                </StyledPostActionButtonContainer>
              </>
                :
                <>
                  <DatePickerDropDown
                    data-testid='DatePickerDropdownTestId'
                    selectedDate={this.state.postSchedule}
                    onChange={this.handlePostScheduleChange.bind(this)}
                    meridiem={this.state.meridiem}
                  />
                  <StyledPostActionButtonContainer>
                    <Typography
                      data-testid='saveAsDraft'
                      sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "18px", color: "#000000", textDecoration: 'underline', cursor: "pointer" }}
                      onClick={this.saveToDraft.bind(this)}
                    >
                      Save as a draft
                    </Typography>
                    <StyledActionButton
                      data-testid='postContentButton'
                      onClick={this.handlePostContent.bind(this)}
                      sx={{
                        background: '#ffffff',
                        color: "#000000",
                        border: "1px solid #000000",
                      }}
                    >
                      Post now
                    </StyledActionButton>
                    <StyledActionButton
                      data-testid='scheduleContentButton'
                      onClick={this.handleSchedulePost.bind(this)}
                      variant="contained"
                      sx={{
                        background: "#000000",
                        color: "#ffffff",
                        '&:hover': {
                          background: "#000000"
                        }
                      }}
                    >
                      Save and Schedule
                    </StyledActionButton>
                  </StyledPostActionButtonContainer>
                </>
              }

            </StyledContentCustomizationContainer>
          </StyledContentContainer>
        </Box>
        <RegenerateModal
          data-testid='regenerateImageModal'
          open={this.state.isOpenRegenerateImageModal}
          description={this.state.regenerateImageDescription}
          onClose={this.handleCancelRegenerateImage.bind(this)}
          onChange={(value) => { this.setState({ regenerateImageDescription:value }) }}
          onRegenerate={this.regenerateImage.bind(this)}
          isImage
        />
        <RegenerateModal
          data-testid='regenerateVideoModal'
          open={this.state.isOpenRegenerateVideoModal}
          description={this.state.regenerateVideoDescription}
          onClose={this.handleCancelRegenerateVideo.bind(this)}
          onChange={(value: string) => { this.setState({ regenerateVideoDescription: value }) }}
          onRegenerate={this.regenerateVideo.bind(this)}
          isImage={false}
        />
      </>
    );
  }
}
const StyledPostActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
  gap: "24px",
  alignItems: "center",
  '@media (max-width: 1217px)': {
    gap: "8px",
  },
  '@media (max-width: 1190px)': {
    gap: "24px",
  },
  '@media (max-width: 534px)': {
    flexDirection: "column",
    gap: "12px",
  }
})
const StyledPostDateContainer = styled(Box)({
  display: 'flex',
  gap: '13px',
  '@media (max-width: 372px)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
  },
})
const StyledPosStatusContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'center',
  width: "100%",
  '@media (max-width: 350px)': {
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start",
    gap: "12px",
  }
})
const StyledActionButton = styled(Button)({
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: "8px",
  textTransform: "none",
  textAlign: "center",
  padding: "10px 16px 10px 16px",
  '@media (max-width: 534px)': {
    width: '100%'
  }
})

const StyledBoostButton = styled(Button)({
  borderRadius: "20px",
  background: "#364AC6",
  fontSize: "18px",
  fontFamily: "Open Sans",
  fontWeight: 600,
  height: "33px",
  color: "#ffffff",
  textTransform: "none",
  width: "74px",
  textAlign: "center",
  display: "none",
  padding: "4px 12px 4px 12px",
  "&:hover": {
    background: "#364AC6"
  }
})

const StyledGenerateEditButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "24px",
  '@media (max-width: 415px)': {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "start"
  },
})

const StyledContentContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  width: "100%",
  gap: "24px",
  '@media (max-width: 1190px)': {
    flexDirection: "column",
    gap: "24px"
  },
})

const StyledContentCustomizationContainer = styled(Box)({
  display: 'flex',
  flexDirection: "column",
  gap: "32px",
  width: "calc(100% - 410px)",
  padding: "0px 24px 12px 24px",
  '@media (max-width: 1190px)': {
    padding: "0px",
    width: '100%'
  },
})
const StyledContentPreviewContainer = styled(Box)({
  display: 'flex',
  width: '400px',
  height: "fit-content",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: "20px",
  '@media (max-width: 1190px)': {
    width: '100%'
  },
})


const webStyle = {
  pageHeadingContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  dropdownLabel: {
    fontWeight: 400,
    color: "#4D4D4D",
    marginBottom: "4px",
    fontFamily: "Open Sans",
    fontSize: "12px",
  },
  commonButtonStyle: {
    borderRadius: "8px",
    textTransform: "none",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,
  },
  publishDate: {
    display: "flex",
    width: "100px",
    height: "48px",
    borderRadius: "8px",
    background: "#DDDDDDDD",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#4D4D4D"
  }

}
// Customizable Area End
