Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = '/bx_block_terms_and_conditions/terms_and_conditions';
exports.getPrivacyPolicyApiEndPoint = '/bx_block_terms_and_conditions/privacy_policies';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';

exports.dateFormat = "MM-DD-YYYY";
exports.listfirst = "Provide accurate and complete information when creating an account and using our Services.";
exports.listsecond = "Use the Services only for lawful purposes and in accordance with these Terms.";
exports.listthird = "Not misuse or interfere with the proper functioning of our Services.";

exports.policyTxt = "Privacy policy"

exports.UserResponsibilitiesTitle = "User Responsibilities"
exports.ToolDesc = "Certain Services may be subject to fees. All fees are specified on our website or through direct communication with DatAI.Payments must be made in accordance with the payment terms specified. Failure to pay any fees may result in thetermination or suspension of your access to the Services.You agree to:"
exports.tailormadeDesc = "At DataAI, our mission is to empower businesses, researchers, and innovators with cutting-edge AI-driven tools that transform raw data into actionable insights. We believe in the power of data to drive informed decisions, and we’re committed to making advanced analytics accessible to everyone.";
exports.IntroductionTitle = "Introduction ";
exports.yourrightsTitle="Your rights"
exports.contentCreationDesc = "Welcome to DatAI. These Terms & Conditions Termsgovern your use of our web platform and services, includingconsultations for businesses collectively referred to as the Services. By accessing or using DatAI, you agree tobe bound by these Terms. If you do not agree with these Terms, please do not use our Services.the AI revolution. With a shared belief in the limitless potential of artificial intelligence, our team works tirelessly to develop innovative solutions that push the boundaries";
  // Customizable Area End