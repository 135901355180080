import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // services: {
  //   id: string,
  //   type: string,
  //   attributes: { title: string, description: string, orientation: string, created_at: string, updated_at: string, image: string, }
  // }[],
  services: []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.closeSignUpFormMessage),
      getName(MessageEnum.openLogInFormRegistrationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.hasVideoMessage),
    ];

    this.state = {
      services: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 
    // Customizable Area Start
    
    // Customizable Area End
  

  // Customizable Area Start
  getServicesApiCallId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.handleGetSerivices();

  }

  async receive(from: string, message: Message) {
    this.receiveResponseHandler(from,message)
    runEngine.debugLog("Message Recived", message);
  }

  async receiveResponseHandler(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (!apiRequestCallId || !responseJson) return;
  
    if (this.getServicesApiCallId === apiRequestCallId) {
      if (responseJson && responseJson.data && responseJson.data.data) {
        this.setState({
          services: responseJson.data.data,
        })
      }
    }
  }

  handleGetSerivices = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServicesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServicesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
