import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";

export interface ApiData{
    contentType: string;
    method: string;
    endPoint: string; 
    body?: {}
  }

export interface ValidResponseType {
  success: boolean,
  statusCode: number,
  message: string,
  user_id: string
  data: {
      access_token: string,
      expires_in: number,
  },
  error: {
      code: string,
      message: string,
      details: string
  },
  errors: Array<string>
}
export interface InvalidResponseType {
    errors: Array<string>;
}

export interface FBAuthResponse {
  accessToken: string;
  expiresIn: number;
  reauthorize_required_in: number;
  signedRequest: string;
  userID: string;
}
 
export interface FBResponse {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse?: FBAuthResponse;
}
 
declare global {
  export interface Window {
    fbAsyncInit: () => void;
    FB: typeof FB;
  }
 
  export const FB: {
    init: (config: {
      appId: string;
      cookie: boolean;
      xfbml: boolean;
      version: string;
    }) => void;
    AppEvents: {
      logPageView: () => void;
    };
    getLoginStatus: (callback: (response: FBResponse) => void) => void;
    login: (
      callback: (response: FBResponse) => void,
      options?: { scope: string }
    ) => void;
  };
}
 
interface Platform {
  id: number;
  platform_name: string;
  access_token: string;
  account_id: number;
  access_token_date: string;
  access_token_validate: boolean;
  created_at: string;
  updated_at: string;
  reciver_id: string;
}
 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accessToken: string;
    connectedPlatformsList: Platform[];
    error: string;
    accessData: Object;
    isSwitch: {
        realSwitch: boolean,
        userSwitch: boolean,
        contentSwitch: boolean
    };
    isfacebookSwitch: {
      realfacebookSwitch: boolean,
      userfacebookSwitch: boolean,
      contentfacebookSwitch: boolean
  };
    isTwitterSwitch: {
       realTwitter: boolean,
       userTwitter: boolean,
       contentTwitter: boolean
    };
    isLinkedInSwitch: {
      realLinkedIn: boolean;
      userLinkedIn: boolean;
      contentLinkedIn: boolean;
    },
    isInstagramDisconnectModalOpen: boolean;
    isFacebookDisconnectModalOpen: boolean;
    isTwitterDisconnectModalOpen: boolean;
    isLinkedInDisconnectModalOpen: boolean;
    isCanvaDisconnectModalOpen: boolean;
    isFacebookConnectButtonVisible: boolean;
    isTwitterConnectButtonVisible: boolean;
    isLinkedInConnectButtonVisible: boolean;
    isCanvaConnectButtonVisible: boolean;
    isInstagramConnectButtonVisible: boolean;
    isLinkedInBtn: boolean;
    isLinkedInDisconnect: boolean;
    isActiveConnect: string;
    canvaAccessToken: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ConnectedAccountsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    instagramRef: React.RefObject<HTMLDivElement>;
    apiGetConnectedPlatformId: string = "";
    apiSaveTokenId: string = "";
    postLinkedInApiCallId: string = "";
    apiIdDisconnectPlatform: string = "";
    apiIdFetchInstagramToken: string = "";
    apiIdFacebookTokenGen: string = "";
    apiIdTwitterOauthTokenFirst: string = "";
    apiIdTwitterOauthTokenSecond: string = "";
    apiIdCanvaOAuthToken: string = "";
    authToken: string = "";
    userId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
 
        this.state = {
            accessToken: "",
            error: "",
            accessData: {},
            connectedPlatformsList: [],
            isSwitch: {
                realSwitch: true,
                userSwitch: false,
                contentSwitch: true
            },
            isfacebookSwitch: {
              realfacebookSwitch: true,
              userfacebookSwitch: false,
              contentfacebookSwitch: true
          },
            isTwitterSwitch: {
              realTwitter: true,
              userTwitter: false,
              contentTwitter: true
            },
            isLinkedInSwitch: {
              realLinkedIn: true,
              userLinkedIn: false,
              contentLinkedIn: true
            },
            isLinkedInDisconnect: false,
            isLinkedInBtn : true,
            isActiveConnect: "",
            isInstagramDisconnectModalOpen: false,
            isFacebookDisconnectModalOpen: false,
            isTwitterDisconnectModalOpen: false,
            isLinkedInDisconnectModalOpen: false,
            isCanvaDisconnectModalOpen: false,
            isInstagramConnectButtonVisible: true,
            isFacebookConnectButtonVisible: true,
            isTwitterConnectButtonVisible: true,
            isLinkedInConnectButtonVisible: true,
            isCanvaConnectButtonVisible: true,
            canvaAccessToken: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.instagramRef = React.createRef();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
 
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

          if (!responseJson.success) return;

          const actionMap: Record<string, () => void> = {
            [this.apiGetConnectedPlatformId]: () => this.handleConnectedPlatformsResponse(responseJson.data.platforms),
            [this.apiIdFetchInstagramToken]: () => this.saveTokenForPlatforms('instagram', responseJson.data.access_token, responseJson.data.user_id.toString()),
            [this.apiIdFacebookTokenGen]: () => this.saveTokenForPlatforms('facebook', responseJson.data.access_token, "0000"),
            [this.apiIdTwitterOauthTokenFirst]: () => this.handleTwitterLogin(responseJson.data),
            [this.apiIdTwitterOauthTokenSecond]: () => this.saveTokenForPlatforms('twitter', responseJson.data.token, "0000"),
            [this.apiIdCanvaOAuthToken]: () => this.saveTokenForPlatforms("canva", responseJson.token, "0000"),
            [this.apiSaveTokenId]: this.getConnectedPlatforms,
            [this.apiIdDisconnectPlatform]: () => {
              toast.success(responseJson.message);
              this.getConnectedPlatforms();
            }
          };

          const action = actionMap[apiRequestCallId];
          if (action) action();
        }
        // Customizable Area End
    }

    // Customizable Area Start
 
  async componentDidMount() {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const code = params.get('code');
    const oauthToken = params.get('oauth_token');
    const oauthVerifier = params.get('oauth_verifier');
    if (code) {
      this.fetchInstagramToken(code);
      params.delete('code');
      const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, document.title, newUrl);
    }
    if (oauthToken && oauthVerifier) {
      this.fetchTwitterToken(oauthToken, oauthVerifier);
      params.delete('oauth_token');
      params.delete('oauth_verifier');
      const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, document.title, newUrl);
    }
 
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    this.getConnectedPlatforms();
 
    // Facebook sdk
    window.fbAsyncInit = () => {
      FB.init({
        appId      : '890725429572822',
        cookie     : true,
        xfbml      : true,
        version    : 'v20.0'
      });
 
      FB.AppEvents.logPageView();   
    };
 
    // Load the Facebook SDK script
    (function(d: Document, s: string, id: string){
      let js: HTMLScriptElement | null, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s) as HTMLScriptElement; 
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
 
  }
 
  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem("authToken") || "";
  
    const header: Record<string, string> = {
      token: token,
    };
  
    if (contentType !== "multipart/form-data") {
      header["Content-Type"] = contentType;
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  
    if (body) {
      if (body instanceof FormData) {
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
      } else {
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
      }
    }
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
 
  handleConnectedPlatformsResponse = (platforms: Platform[]) => {
    this.setState({
      connectedPlatformsList: platforms,
      isInstagramConnectButtonVisible: true,
      isFacebookConnectButtonVisible: true,
      isTwitterConnectButtonVisible: true,
      isCanvaConnectButtonVisible: true,
      canvaAccessToken: ''
    });
  
    platforms.forEach((platform: Platform) => {
      if (platform.platform_name === 'instagram') {
        this.setState({ isInstagramConnectButtonVisible: false });
      }
      if (platform.platform_name === 'facebook') {
        this.setState({ isFacebookConnectButtonVisible: false });
      }
      if(platform.platform_name === 'twitter'){
        this.setState({ isTwitterConnectButtonVisible: false });
      }
      if(platform.platform_name === "canva"){
        this.setState({ isCanvaConnectButtonVisible: false, canvaAccessToken: platform.access_token });
      }
    });
  };

  getConnectedPlatforms = () => {
    this.apiGetConnectedPlatformId = this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/platforms`
    })
  }
 
  saveTokenForPlatforms = (name: string, token: string, reciverId: string) => {
    this.apiSaveTokenId = this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_settings/platforms`,
      body: {
        "platform": {
          "platform_name": name,
          "access_token": token,
          "reciver_id": reciverId
        }
      }
    })
  }
 
  disconnectPlatform = (name: string) => {
    const id = this.state.connectedPlatformsList.find(p => p.platform_name == name)?.id;
    this.apiIdDisconnectPlatform = this.apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_settings/platforms/${id}`,
    })
  }
 
  handleCloseModal = () => {
    this.setState({ isInstagramDisconnectModalOpen: false, isFacebookDisconnectModalOpen: false, isTwitterDisconnectModalOpen: false, isCanvaDisconnectModalOpen: false });
  }
 
 
  // Instagram
 
  handleInstagramDisconnect = () => {
    this.disconnectPlatform("instagram");
    this.handleCloseModal();
  }
 
  handleAccountSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isSwitch: {
        ...this.state.isSwitch,
        [event.target.name]: event.target.checked
      }
    });
  };
 
  fetchInstagramToken = (code: string) => {
    const form = new FormData();
    form.append("code", code)
 
    this.apiIdFetchInstagramToken = this.apiCall({
      contentType: "multipart/form-data",
      method: "POST",
      endPoint: "bx_block_chatgpt3/thread_chatgpt/insta_access_token",
      body: form
    })
  }
 
  // Facebook
 
  checkLoginState = () => {
    FB.getLoginStatus((response: any) => {
      this.statusChangeCallback(response);
    });
  };
 
  statusChangeCallback = (response: any) => {
    if (response.status === 'connected') {
      this.facebookTokenGenerate(response.authResponse.accessToken)
    } else{
      FB.login((loginResponse: any) => {
        if (loginResponse.authResponse) {
          toast.success('User logged in successfully:');
          this.facebookTokenGenerate(loginResponse.authResponse.accessToken)
        } else {
          toast.error('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'public_profile, email' });
    }
  };
  
  handlefacebookDisconnect = () => {
    this.disconnectPlatform('facebook');
    this.handleCloseModal();
  }
 
  handlefacebookAccountSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isfacebookSwitch: {
        ...this.state.isfacebookSwitch,
        [event.target.name]: event.target.checked
      }
    });
  };
 
  facebookTokenGenerate(accessToken: string){
    this.apiIdFacebookTokenGen = this.apiCall({
      contentType: "application/json",
      method:'GET',
      endPoint: `bx_block_connection/facebook/exchange_token?access_token=${accessToken}`,
    })
  }
 
 
  // Twitter
 
  handleTwitterDisconnect = () => {
    this.disconnectPlatform('twitter');
    this.handleCloseModal();
  }
 
  handleConnectTwitter = () => {
    this.apiIdTwitterOauthTokenFirst = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_connection/twitters/request_token`,
    })
  };

  handleTwitterLogin = (code: string) => {
    const twitterAuthUrl = `https://api.x.com/oauth/authorize?${code}`;
    window.location.href = twitterAuthUrl;
  }

  fetchTwitterToken = (oauthToken: string, oauthVerifier: string) => {
    this.apiIdTwitterOauthTokenSecond = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_connection/twitters/access_token?oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`,
    })
  }
 
  handleTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isTwitterSwitch: {
            ...this.state.isTwitterSwitch,
          [event.target.name]: event.target.checked
      }
    });
  };
  
  // Linkedin
 
  handleLinkedInDisconnect = () => {
    this.disconnectPlatform('linkedin');
    this.handleCloseModal();
  }
 
  handleConnectLinkedIn = () => {
    const redirectUrl = window.location.href;
    this.setState({ isLinkedInBtn: false , isActiveConnect: "LinkedIn"}, () => {
      const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86k1ku5auyhciu&redirect_uri=${redirectUrl}&scope=w_member_social`;
      window.location.href = linkedInAuthUrl;
    });
  };
 
  handleLinkedIn = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
       isLinkedInSwitch: {
            ...this.state.isLinkedInSwitch,
           [event.target.name]: event.target.checked
      }
    });
  };

  //Canva

  handleConnectCanva = () => {
    this.apiIdCanvaOAuthToken = this.apiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_canva_client/get_canva_access_token`,
    })
  }
  
  handleCanvaDisconnect = () => {
    this.disconnectPlatform('canva');
    this.handleCloseModal();
  }

  handleCopyToken = () => {
    navigator.clipboard.writeText(this.state.canvaAccessToken);
    toast.success("Token copied")
  }
 
    // Customizable Area End
}