// Customizable Area Start
import React from "react";
import {
  TextField,
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Backdrop,
  Modal,
  Paper
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signatureview, VolumeIcon, ReloadIcon, DislikeIcon, sendicon, Loader } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageController, {
  Props, configJSON
} from "./LandingPageController";

import Footerbar from "../../../components/src/Footerbar"

export default class BrainsStromWithJarvis extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  renderLoginSignupModal = () => {
    return (
      <ModalContainer
        open={this.state.openLoginSignup}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className="deleteModal"
      >
        <Paper className="JarvaysPaper">
          <IconButton
            onClick={this.closeLoginSignup}
            data-testId="closeJarvysTestID"
            className="closeJarvysBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography className="jarvaysHeadMsgBox" data-testId="closelabelTestID">
            {configJSON.jarvysHeadMsg}
          </Typography>
          <Typography className="jarvys_platform_msg">{configJSON.platformMessgae}</Typography>
          <Box className="buttonJarvysBox">
            <Button className="signupBtn" onClick={this.handleOpenSignUpForm.bind(this)}>
              {configJSON.signupText}
            </Button>
            <Button className="loginBtn" onClick={this.handleOpenLogInForm.bind(this)}>{configJSON.loginText}</Button>
          </Box>
        </Paper>
      </ModalContainer>
    )
  }

  renderProcessModal = () => {
    return (
      <ModalContainer
        className="deleteModal"
        open={this.state.jarvysmodelopenlanding}
        disableAutoFocus
        disablePortal
        disableEnforceFocus
      >
        <Paper className="JarvaysPaper" >
          <IconButton
            onClick={this.handleclosejarvysmodel.bind(this)}
            className="closeJarvysBtn"
          >
            <CloseIcon />
          </IconButton>
          <StyledModelTextGradient>{'JARVYS AI'}</StyledModelTextGradient>
          <ProcessLoaderImgBox>
            <img src={Loader} alt="Loading..." className="loader_Img" />
          </ProcessLoaderImgBox>
          <ModelBottomTypo>{'JARVYS processes your question...'}</ModelBottomTypo>
        </Paper>
      </ModalContainer>
    );
  };

  render() {
    return (
      <>
        <JarvysAIBox >
          <StyledJarvisTypo >JARVYS AI</StyledJarvisTypo>
        </JarvysAIBox>
        <StyleBrainStormContainer>
          <JarvysGridBox item xs={12} >
            {
              !this.state.values.length ? (
                <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                  <Box padding={'20px'} >
                      <StyledLetsStartTypo >Lets start to talk with JARVYS</StyledLetsStartTypo>
                      <Box display={'flex'} justifyContent={"space-around"} >
                        <Box display={"flex"} justifyContent={"center"} alignItems={'center'} flexDirection={"column"}>
                          <StyledLetsStartChildTypo >You can ask about anything that will help you </StyledLetsStartChildTypo>
                          <StyledLetsStartChildTypo >improve your business, or find out where to start... </StyledLetsStartChildTypo>
                        </Box>
                        <StyledTextLogo src={signatureview} alt="signatureimg" />
                      </Box>
                  </Box>
                </Box>
              ) : (
                <MainContainerBox>
                  {
                    this.state.values.map((item, index) => {
                      return (
                        <JarvysChatBox key={index} >
                          <JarvysChatMainBox>
                            <QuestionTextBox>{"Your question"}</QuestionTextBox>
                            <UserChatBox >
                              <JarvysChatQuestion>
                                {item.question}
                              </JarvysChatQuestion>
                            </UserChatBox>
                          </JarvysChatMainBox>
                          <Box>
                            <JarvysChatText >{"JARVYS’s answer"}</JarvysChatText>
                            <JarvysAnsText
                              dangerouslySetInnerHTML={{
                                __html: item.jarvysAnswer,
                              }}
                            >
                            </JarvysAnsText>
                            <JarvysAnsIconBox >
                              <img className="JarvysIcon" src={VolumeIcon} alt="" />
                              <img className="JarvysIcon" src={ReloadIcon} alt="" />
                              <img className="JarvysIcon" src={DislikeIcon} alt="" />
                            </JarvysAnsIconBox>
                          </Box>
                        </JarvysChatBox>
                      )
                    })
                  }
                </MainContainerBox>
              )
            }
          </JarvysGridBox>
          <BrainInputBox>
            <StyledInputField
              fullWidth
              id="outlined-adornment-password"
              type={'text'}
              value={this.state.userMessage}
              onChange={this.handleUserMessageChange.bind(this)}
              placeholder='Enter your text'
              variant='outlined'
              InputProps={{
                endAdornment: <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="goToHomeButton"
                  onClick={this.askToJarvys.bind(this)}
                  disabled={this.state.userMessage === "" ? true : false}
                  edge="end"
                >
                  <img src={sendicon} alt="" height={'50px'} width={'50px'} />
                </IconButton>
              }}
            />
            <Typography style={{ textAlign: 'start', marginLeft: '15px', color: '#909090', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '12px' }}>
              JARVYS AI can make mistakes. Consider checking important information.
            </Typography>
          </BrainInputBox>
        </StyleBrainStormContainer>
        <Box sx={{ flexShrink: 0 }}>
          <Footerbar isActive={this.state.isBackgroundVideoAvailable} />
        </Box>
        {this.renderLoginSignupModal()}
        {this.renderProcessModal()}
      </>
    )
  }
}

const JarvysAIBox = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#D7DADA',
  marginTop: "10px"
})

const JarvysGridBox = styled(Grid)({
  backgroundColor: '#D7DADA',
  paddingBottom: "10px",
  height: "calc(100vh - 360px)",
  overflow: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "@media (max-width: 800px)": {
    height: "calc(100vh - 474px)",
  },
})

const JarvysChatBox = styled(Box)({
  padding: '0 5vw',
})

const JarvysChatMainBox = styled(Box)({
  marginTop: '24px'
})

const QuestionTextBox = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  color: '#4D4D4D',
  textAlign: 'right' as 'right'
})

const UserChatBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  marginTop: '8px',
})

const JarvysChatQuestion = styled(Typography)({
  maxWidth: "67.42%",
  boxSizing: 'border-box' as 'border-box',
  background: '#EFEFEF',
  padding: "8px 16px 8px 16px",
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  color: '#4D4D4D',
  borderRadius: '16px 0px 16px 16px',
  "@media (max-width: 700px)": {
    maxWidth: "80%",
  },
  "@media (max-width: 600px)": {
    maxWidth: "90%",
  },
})

const JarvysChatText = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  marginTop: '24px',
  color: '#4D4D4D',
})

const JarvysAnsText = styled(Typography)({
  maxWidth: "67.42%",
  marginTop: '8px',
  boxSizing: 'border-box' as 'border-box',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  color: '#4D4D4D',
  "@media (max-width: 700px)": {
    maxWidth: "80%",
  },
  "@media (max-width: 600px)": {
    maxWidth: "90%",
  },
})

const JarvysAnsIconBox = styled(Box)({
  marginTop: '14px',
  width: 'fit-content',
  display: 'none',
  gridTemplateColumns: 'auto auto auto',
  columnGap: '14px',
  "& .JarvysIcon": {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  }
})

const MainContainerBox = styled(Box)({
  width: "100%",
  background: "#D7DADA",
})

const BrainInputBox = styled(Box)({
  padding: '5px 5vw',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  flexDirection: 'column',
  background: "#D7DADA",
})

const StyleBrainStormContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  overflow: 'auto',
  flexDirection: "column",
  backgroundColor: '#D7DADA',
  "& .backdrop_modal": {
    zIndex: 1500
  },

});

const StyledJarvisTypo = styled(Typography)({
  fontWeight: 600,
  background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Open Sans',
  WebkitBackgroundClip: 'text',
  textAlign: 'center',
  fontSize: '2.817vw',
  marginLeft: '5vw',
  '@media (max-width: 918px)': {
    marginTop: '10px',
    marginBottom: '10px',
  },
  '@media (max-width: 768px)': {
    fontSize: '4.662vw',
  }
})


const StyledLetsStartTypo = styled(Typography)({
  fontWeight: 400, fontSize: '42px', fontFamily: 'Open Sans', color: '#000000', marginBottom: '30px', padding: '5px',

  '@media (max-width: 1034px)': {
    fontSize: '40px',
  },
  '@media (max-width: 918px)': {
    fontSize: '35px',
  },
  '@media (max-width: 858px)': {
    fontSize: '22px',
  },
  '@media (max-width: 768px)': {
    fontSize: '20px',
  },
  '@media (max-width: 626px)': {
    fontSize: '15px',
  },
  '@media (max-width: 468px)': {
    fontSize: '13px',
  },
  '@media (max-width: 386px)': {
    fontSize: '12px'
  },
})

const StyledLetsStartChildTypo = styled(Typography)({
  fontWeight: 400,
  fontSize: '20px',
  color: '#000000',
  fontFamily: 'Open Sans',
  marginBottom: '5px',
  '@media (max-width: 1034px)': {
    fontSize: '15px',
  },
  '@media (max-width: 918px)': {
    fontSize: '14px',
  },
  '@media (max-width: 858px)': {
    fontSize: '13px',
  },
  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
  '@media (max-width: 562px)': {
    fontSize: '11px',
  },
  '@media (max-width: 626px)': {
    fontSize: '10px',
  },
  '@media (max-width: 468px)': {
    fontSize: '9px'
  },
  '@media (max-width: 386px)': {
    fontSize: '8px',
  },

})

const StyledTextLogo = styled.img({

  cursor: 'pointer',
  width: '50px',

  '@media (max-width: 1018px)': {
    width: '70px',
  }, '@media (max-width: 1034px)': {
    width: '50px',

  },
  '@media (max-width: 974px)': {
    width: '40px',
  },
  '@media (max-width: 858px)': {
    height: '40px'
  },
  '@media (max-width: 468px)': {
    width: '30px',
  }, '@media (max-width: 386px)': {
    width: '20px',
  },
})

const StyledInputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '100px',
    backgroundColor: "#FFFFFF",
    '& .MuiInputBase-input': {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: '16px',
      color: "#4D4D4D",
    },
    '& fieldset': {
      borderColor: '',
      border: "1px solid #B3B3B3"
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B3B3B3',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      background: "transparent"
    },
    '&.Mui-disabled': {
      backgroundColor: '#EFEFEF',
    },
  },
});



const StyledModelTextGradient = styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '64px',
  background: 'linear-gradient(to right, #0066FF, #E8CC17)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
})

const ModelBottomTypo = styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  color: '#515151',
})

const ModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  "& .JarvaysPaper": {
    maxHeight: 'fit-content',
    width: "520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    '@media (max-width: 564px)': {
      width: 'calc(100% -10px)',
    },
  },
  "& .closeJarvysBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .jarvaysHeadMsgBox": {
    background: 'linear-gradient(to right, #0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign: "center",
    fontSize: '48px !important',
    lineHeight: "65.37px !important",
  },
  "& .jarvys_platform_msg": {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign: "center",
    fontSize: '18px',
    color: '#4D4D4D',
    marginTop: "40px",
    '@media (max-width: 564px)': {
      marginTop: "10px",
    },
  },
  "& .buttonJarvysBox": {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    width: "100%",
    '@media (max-width: 564px)': {
      flexDirection: "column",
      marginTop: "15px",
    },
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "100%",
      height: "48px"

    },
    "& .signupBtn": {
      color: "#000000",
      border: "1px solid #000000",
      cursor: "pointer"
    },
    "& .loginBtn": {
      color: "white",
      backgroundColor: "#000000",
      cursor: "pointer"
    }
  }
});

const ProcessLoaderImgBox = styled(Box)({
  width: '51px',
  height: '50px',
  animation: 'rotate 2s linear infinite',
  "& .loader_Img": {
    width: '51px',
    height: '50px',
    animation: 'rotate 2s linear infinite',
  }
})

// Customizable Area End