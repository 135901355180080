import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  TextField,
  IconButton,
  Modal,
  Paper,
} from '@mui/material';
import { successIcon, facebookIcon, subcribeIcon, instaIcon, blogIcon, selectIcon } from './assets';
import CloseIcon from '@mui/icons-material/Close';
import NewWorkspaceController, { Props, configJSON } from './NewWorkspaceController.web';
import moment from "moment";
// Customizable Area End

export default class NewWorkspace extends NewWorkspaceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNewWorkspaceHeader = () => {
    const { newWorkspace, isShow } = this.state;
    return (
      <NewWorkspacesHeaderBox>
        <NewWorkspacesHeading>
          <Typography className="NewWorkspaceHeading">{isShow ? newWorkspace : configJSON.NewWorkspace}</Typography>
        </NewWorkspacesHeading>
       { !isShow && <Box>
        <NewWorkspaceContainer>
          <Typography className="InputHeading">{configJSON.inputWorkspace}</Typography>
          <NewWorkspaceField
            placeholder="Enter workspace name"
            fullWidth
            data-testId="createNewWorkspaceTestID"
            onChange={this.handleNewWorkspace}
            value={newWorkspace}
          />
        </NewWorkspaceContainer>
        <WorkspaceBtnBox>
          <Button className="workspaceCancelBtn" data-testId="newWorkspaceCancelTestId" onClick={this.goToMyWorkspace}>
            {configJSON.cancelWorkspace}
          </Button>
          <Button className="newCampaignButton" data-testId="createWorkspaceBtnTestId" onClick={this.handleCreateNewWorkspace}>
            {configJSON.createWorkspace}
          </Button>
        </WorkspaceBtnBox>
        </Box>}
      </NewWorkspacesHeaderBox>)
  };

  renderNewWorkspace = () => {
    const { isShow } = this.state;
    return (
      <WorkspaceContainer>
        <NewWorkspaceCardBox>
          <NewWorkspaceHeadingBox>{configJSON.campaign}</NewWorkspaceHeadingBox>
          <WorkspaceExistBtnBox>
            <WorkspaceExistingBtn className={!isShow ? "WS_opacity" : ""} data-testId="existingCampaignBtnTestID" onClick={this.openAddCampaigns}>{configJSON.campaignButton}</WorkspaceExistingBtn>
          </WorkspaceExistBtnBox>
        </NewWorkspaceCardBox>
        <NewWorkspaceCardBox>
          <NewWorkspaceHeadingBox>{configJSON.communityManager}</NewWorkspaceHeadingBox>
          <WorkspaceExistBtnBox>
            <WorkspaceExistingBtn className={!isShow ? "WS_opacity" : ""}>{configJSON.communityManagerButton}</WorkspaceExistingBtn>
          </WorkspaceExistBtnBox>
        </NewWorkspaceCardBox>
        <NewWorkspaceCardBox>
          <NewWorkspaceHeadingBox>{configJSON.report}</NewWorkspaceHeadingBox>
          <WorkspaceExistBtnBox>
            <WorkspaceExistingBtn className={!isShow ? "WS_opacity" : ""}>{configJSON.reportButton}</WorkspaceExistingBtn>
          </WorkspaceExistBtnBox>
        </NewWorkspaceCardBox>
        <NewWorkspaceCardBox>
          <NewWorkspaceHeadingBox>{configJSON.team}</NewWorkspaceHeadingBox>
          <WorkspaceExistBtnBox>
            <WorkspaceExistingBtn className={!isShow ? "WS_opacity" : ""} onClick={this.openAddEmployee} data-testId="addEmployeeBtnTestId">{configJSON.teamButton}</WorkspaceExistingBtn>
          </WorkspaceExistBtnBox>
        </NewWorkspaceCardBox>
      </WorkspaceContainer>
    )
  };

  renderCreateWorkspace = () => {
    const { openSuccessModal } = this.state;
    return (
      <NewWorkspaceModalContainer
        open={openSuccessModal}
        onClose={this.closeSuccessWorkspace}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_upload paper_success">
          <IconButton
            onClick={this.closeSuccessWorkspace}
            data-testId="newWorkspaceCloseIconTestID"
            className="closeNewWorkspaceBtn"
          >
            <CloseIcon />
          </IconButton>
          <NewWorkspaceHeadBox>
            <NewWorkspaceHeadTypo data-testId="newWorkspaceCloseLabelTestID">{configJSON.createWorkspaceText}</NewWorkspaceHeadTypo>
            <img src={successIcon} className="succes_image"/>
          </NewWorkspaceHeadBox>
        </Paper>
      </NewWorkspaceModalContainer>
    )
  };

  renderAddEmployee = () => {
    return (
      <NewWorkspaceModalContainer
        open={this.state.openAddEmployee}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className="deleteModal"
      >
        <Paper className={this.state.openAddEmployee ? "renameNewWorkspace paper" : "paper"}>
          <IconButton
            onClick={this.closeAddEmployee}
            data-testId="addEmployeesCloseIconTestID"
            className="closeNewWorkspaceBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" data-testId="addEmployeesCloseLabelTestID">
            {configJSON.addEmployeeText}
          </Typography>
          <Typography variant="body2">{configJSON.addEmployeeNext}</Typography>
          <FieldBox>
          <TypographyFieldLabel>{"Employee Name"}</TypographyFieldLabel>
          <RenameNewWorkspaceField/>

          <TypographyFieldLabel>{"Email address"}</TypographyFieldLabel>
          <RenameNewWorkspaceField/>

          <TypographyFieldLabel>{"Rol"}</TypographyFieldLabel>
          <RenameNewWorkspaceField/>
          </FieldBox>
          <Box className="NewWorkspaceButtonBox">
            <Button className="newCancelBtn" onClick={this.closeAddEmployee}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="newDeleteBtn" data-testId="employeeAddBtnTestId" onClick={this.openEmployeeAdded}>{configJSON.sendInvitation}</Button>
          </Box>
        </Paper>
      </NewWorkspaceModalContainer>
    )
  };

  renderEmployeeAdded = () => {
    const { openEmployeeAdd } = this.state;
    return (
      <NewWorkspaceModalContainer
        open={openEmployeeAdd}
        onClose={this.closeEmployeeAdded}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_upload paper_success">
          <IconButton
            onClick={this.closeEmployeeAdded}
            data-testId="employeesAddCloseIconTestID"
            className="closeNewWorkspaceBtn"
          >
            <CloseIcon />
          </IconButton>
          <NewWorkspaceHeadBox>
            <NewWorkspaceHeadTypo data-testId="employeesAddCloseLabelTestID">{configJSON.employeeAddText}</NewWorkspaceHeadTypo>
            <img src={successIcon} className="succes_image"/>
          </NewWorkspaceHeadBox>
        </Paper>
      </NewWorkspaceModalContainer>
    )
  };

  renderAddCampaigns = () => {
    const { openAddCampaign , campaignData } = this.state;
    return (
       <AddCampaignModalContainer
         open={openAddCampaign}
         disablePortal
         disableEnforceFocus
         disableAutoFocus
       >
         <Paper className="paper paper_addCampaign">
           <IconButton
             onClick={this.closeAddCampaigns}
             data-testId="closeCampaignBtnTestID"
             className="closeIconBtn"
           >
             <CloseIcon />
           </IconButton>
           <MoveAddCampaignBox>
           <Typography variant="body1" data-testId="closeCampaignLabelTestID">
             {configJSON.addCampaignText}
           </Typography>
           <Box className="add_body_text">{configJSON.addCampaignBodyText}</Box>
           </MoveAddCampaignBox>
         <NewCampaignCardBox>
         <Box className="addCardsBox" >
          {campaignData.map((item, index) => (
            <Box className={this.handleCampaignId(item) ? "addCampaignCard add_opacity" : "addCampaignCard"} key={index}
             data-testId="selectCampaignBoxTestID"
            onClick={()=>this.handleToggleSelect(item)}
            >
              <Box className="addCampaigntitleBox">
                <Typography className='addCampaign_date'>{"Created on: " + moment(item.attributes.created_at).format("MMM DD")}</Typography>
                {this.handleCampaignId(item) && <img src={selectIcon} className='addCampaign_img' alt="image" />}
              </Box>
              <NewCampaignMainBox>
                <Typography className='campaign_name'>{item.attributes.name}</Typography>
                <AddCampaignSocialIconBox>
                  <AddCampaignIconBox>
                    <img src={facebookIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddCampaignIconBox>
                  <AddCampaignIconBox>
                    <img src={subcribeIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddCampaignIconBox>
                  <AddCampaignIconBox>
                    <img src={instaIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddCampaignIconBox>
                  <AddCampaignIconBox>
                    <img src={blogIcon} className="social_icon_img" />
                    <Box className="socialCount">{"0"}</Box>
                  </AddCampaignIconBox>
                </AddCampaignSocialIconBox>
              </NewCampaignMainBox>
            </Box>
          ))}
        </Box>
      </NewCampaignCardBox>
          <Box className="AddButtonBox">
            <Button className="AddCancelBtn" onClick={this.closeAddCampaigns}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="AddDeleteBtn" data-testId="addToWorkspaceTestID" onClick={this.addCampaignToWorkspace}>{configJSON.addWorkspaceBtn}</Button>
          </Box>
        </Paper>
      </AddCampaignModalContainer>
    )
  };

  renderCampaignsAdded = () => {
    const { openCampaignAdded } = this.state;
    return (
      <NewWorkspaceModalContainer
        open={openCampaignAdded}
        onClose={this.closeCampaignsAdded}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_upload paper_success">
          <IconButton
            onClick={this.closeCampaignsAdded}
            data-testId="closeCampaignsAddedID"
            className="closeNewWorkspaceBtn"
          >
            <CloseIcon />
          </IconButton>
          <NewWorkspaceHeadBox>
            <NewWorkspaceHeadTypo data-testId="closeCampaignsAddedLabelID">{configJSON.campaignsAddedText}</NewWorkspaceHeadTypo>
            <img src={successIcon} className="succes_image"/>
          </NewWorkspaceHeadBox>
        </Paper>
      </NewWorkspaceModalContainer>
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
       <MainNewContainer>
        {this.renderNewWorkspaceHeader()}
        {this.renderNewWorkspace()}
        {this.renderCreateWorkspace()}
        {this.renderAddEmployee()}
        {this.renderEmployeeAdded()}
        {this.renderAddCampaigns()}
        {this.renderCampaignsAdded()}
       </MainNewContainer>
     );
     // Customizable Area End
   }
 }
  
 // Customizable Area Start
const MainNewContainer = styled(Box)({
   padding: "30px 24px",
   '@media (max-width: 600px)': {
     padding: '15px',
   },
 })

const NewWorkspacesHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
})

const NewWorkspacesHeading = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .NewWorkspaceHeading": {
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  },
  "& .heading_icon" : {
     fontSize: "16px"
  }
})

const WorkspaceBtnBox = styled(Box)({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  width: "100%",
  gap: "20px",
  marginTop: "30px",
  "& .workspaceCancelBtn": {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    color: "#000000",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px 20px',
    background: 'none',
    cursor: "pointer",
    "&:hover": {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  "& .newCampaignButton": {
    textTransform: 'none',
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px',
    background: '#000000',
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: '#000000',
    },
    '@media (max-width: 400px)': {
      width: "100%"
    },
  },

  '@media (max-width: 646px)': {
    alignItems: "start",
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    alignItems: "center",
  },
  '@media (max-width: 400px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const WorkspaceExistBtnBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
    "& .WS_opacity":{
      opacity: "0.3 !important"
    }
})

const WorkspaceExistingBtn = styled(Button)({
    textTransform: 'none',
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px 0px',
    width: "330px",
    background: '#000000',
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: '#000000',
    },
})

const NewWorkspaceContainer = styled(Box)({
  width: "100%",
  '@media (max-width: 754px)': {
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
  },
  '@media (max-width: 500px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
  "& .InputHeading": {
    color: "#4D4D4D",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Open Sans",
    margin: "5px 0px",
  }
})

const NewWorkspaceField = styled(TextField)({
  width: "100%",
  "&:hover": {
    border: "none"
  },
  "& .Mui-focused": {
    "& fieldset": {
      border: "1px solid #DDDDDD !important",
      "&:hover": {
        border: "1px solid #DDDDDD !important"
      }
    }
  },
   "& fieldset": {
     border: "1px solid #DDDDDD !important"
   },
   '& .MuiOutlinedInput-root': {
     background: "white",
     fontFamily: "Open Sans",
     height: "48px",
     '&.Mui-focused:hover fieldset': {
       borderColor: '#000000'
     },
     '& fieldset': {
       border: "1px solid #DDDDDD",
       borderRadius: "8px",
     },
     '&.Mui-focused fieldset': {
       border: "1px solid #000000",
     },
     '&:hover fieldset': {
       borderColor: '#797979',
     }
   },
   '& .MuiInputBase-input': {
    width: "100%",
    height: '100%',
   }
 });

const FieldBox = styled(Box)({
  width: "100%",
})

const TypographyFieldLabel = styled(Typography)({
  color: "#4D4D4D",
  fontSize: "12px !important",
  fontWeight: "400 !important",
  lineHeight: "15.6px !important",
  fontFamily: "Open Sans",
  alignSelf:"baseline",
  marginBottom: "5px"
})

const RenameNewWorkspaceField = styled(TextField)({
  width: "100%",
  fontFamily: "Open Sans",
  marginBottom: "10px",
  "& .Mui-focused": {
    "& fieldset": {
      border: "none",
      "&:hover": {
        border: "none"
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    "& fieldset": {
      border: "1px solid #DDDDDD !important",
      borderRadius: "8px",
      padding: "4px",
    },
    "&:hover": {
      border: "none"
    },
  }
})

const WorkspaceContainer = styled(Box)({
  display: "flex",
  marginTop: "36px",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "20px"
});

const NewWorkspaceCardBox = styled(Box)({
  flex: "1 0 45%",
  height: "400px",
  border: "1px solid #DDDDDDDD",
  borderRadius: "8px",
  padding: "16px",
  gap: "24px"
});

const NewWorkspaceHeadingBox = styled(Box)({
  color: "#4D4D4D",
  fontSize: "20px",
  fontWeight: 600,
  fontFamily: "Open Sans",
  borderBottom: "1px solid #DDDDDDDD",
  padding: "12px 8px"
  });

const NewWorkspaceModalContainer = styled(Modal)({
   margin: "20px 0px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   padding: "20px",
   "& .paper": {
     boxSizing: "border-box",
     maxHeight: 'fit-content',
     width: "fit-content",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: "40px",
     borderRadius: "24px",
     gap: "20px 0px",
     position: "relative",
     "& .MuiTypography-body2": {
       color: "#4D4D4D",
       lineHeight: "21px",
       fontWeight: 400,
       fontSize: "16px",
       maxWidth: "500px",
       textAlign: "center"
     },
      "& .MuiTypography-body1": {
       color: "#4D4D4D",
       lineHeight: "27px",
       fontWeight: 600,
       fontSize: "20px"
     },
     '@media (max-width: 375px)': {
       padding: "20px",
     },
   },
   "& .paper_success": {
     padding: "40px !important",
   },
   "& .paper_upload": {
     position: "relative",
     boxSizing: "border-box",
     maxHeight: 'fit-content',
     width: "480px",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: "40px",
     borderRadius: "24px",
     gap: "20px 0px",
     '@media (max-width: 775px)': {
       width: "450px"
     },
     '@media (max-width: 675px)': {
       width: "420px"
     },
     '@media (max-width: 575px)': {
       width: "380px"
     },
     '@media (max-width: 475px)': {
       width: "350px"
     },
     '@media (max-width: 375px)': {
       width: "280px",
       padding: "20px",
     },
   },
   "& .renamepaper": {
     "& .MuiOutlinedInput-input": {
       padding: "4px !important",
       borderRadius: "8px",
       border: "1px solid #DDDDDD",
       width: "100%"
     }
    },
    "& .renameNewWorkspace": {
       "& .MuiOutlinedInput-input": {
         width: "100%",
         borderRadius: "8px",
         border: "1px solid #DDDDDD",
       }
    },
    "& .closeNewWorkspaceBtn": {
       position: "absolute",
       color: "black",
       top: "10px",
       right: "10px"
     },
     "& .NewWorkspaceButtonBox": {
       padding: "20px 0px",
       display: "flex",
       gap: "20px",
      "& .MuiButton-root": {
        fontSize: "16px",
        width: "212px",
        height: "48px",
        fontWeight: 600,
        lineHeight: "24px",
        padding: "10px 16px",
        borderRadius: "8px",
        textTransform: "none",
       '@media (max-width: 675px)' : {
         width : "200px !important"
       },
       '@media (max-width: 575px)' : {
         width : "180px !important"
       },
       '@media (max-width: 475px)' : {
         width : "130px !important"
       },
       '@media (max-width: 375px)' : {
         width : "100px !important",
         padding : "10px 5px"
       },
      },
       "& .newCancelBtn" : {
       color : "#000000",
       border : "1px solid #000000"
      },
       "& .newDeleteBtn" : {
       color : "white",
       backgroundColor : "#000000"
      }
     }
  });

const NewWorkspaceHeadTypo = styled(Typography)({
   fontSize: '20px',
   textAlign: "center",
   fontWeight: 600,
   color: '#4D4D4D',
   fontFamily: 'Open Sans',
  })

const NewWorkspaceHeadBox = styled(Box)({
   width: '100%',
   display: "flex",
   justifyContent: "center",
   flexDirection: "column",
   alignItems: "center",
   "& .succes_image": {
     width: "80px",
     height: "80px",
     marginTop: "35px"
   },
 })

 const AddCampaignModalContainer = styled(Modal)({
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   padding: "20px",
   margin: "20px 0px",
   "& .paper": {
     maxHeight: 'fit-content',
     width: "fit-content",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: "40px",
     borderRadius: "24px",
     gap: "20px 0px",
     position: "relative",
     boxSizing: "border-box",
     "& .MuiTypography-body1": {
       color: "#4D4D4D",
       lineHeight: "27px",
       fontWeight: 600,
       fontSize: "20px",
     },
     "& .MuiTypography-body2": {
       color: "#4D4D4D",
       lineHeight: "21px",
       fontWeight: 400,
       fontSize: "16px",
       maxWidth: "500px",
       textAlign: "center",
     },
     '@media (max-width: 375px)': {
       padding: "20px",
     },
    },
   "& .paper_success": {
     padding: "40px !important",
   },
   "& .paper_addCampaign":{
     padding: "30px 20px !important",
     width: "65% !important",
   },
   "& .paper_campaign_modal": {
     width: "600px",
     maxHeight: 'fit-content',
     flexDirection: "column",
     display: "flex",
     padding: "40px",
     alignItems: "center",
     gap: "20px 0px",
     borderRadius: "24px",
     boxSizing: "border-box",
     position: "relative",
     '@media (max-width: 675px)': {
       width: "500px"
     },
     '@media (max-width: 775px)': {
       width: "550px"
     },
     '@media (max-width: 475px)': {
       width: "350px"
     },
     '@media (max-width: 575px)': {
       width: "450px"
     },
     '@media (max-width: 375px)': {
       width: "280px",
       padding: "20px",
     },
   },
   "& .closeIconBtn": {
     position: "absolute",
     color: "black",
     top: "10px",
     right: "10px",
    },
    "& .campaignButtonBox": {
      gap: "20px",
      display: "flex",
      padding: "20px 0px",
     "& .MuiButton-root": {
       textTransform: "none",
       borderRadius: "8px",
       padding: "10px 16px",
       lineHeight: "24px",
       fontWeight: 600,
       fontSize: "16px",
       height: "48px",
       width: "212px",
       '@media (max-width: 575px)': {
         width: "180px !important"
       },
       '@media (max-width: 675px)': {
         width: "200px !important"
       },
       '@media (max-width: 375px)': {
         padding: "10px 5px",
         width: "100px !important",
       },
       '@media (max-width: 475px)': {
         width: "130px !important"
       },
     },
    "& .campaignCancelBtn": {
      border: "1px solid #000000",
      color: "#000000",
    },
    "& .campaignDeleteBtn": {
      backgroundColor: "#000000",
      color: "white",
    } 
  },
   "& .AddButtonBox": {
     gap: "20px",
     width: "100%",
     display: "flex",
    "& .MuiButton-root": {
      borderRadius: "8px",
      textTransform: "none",
      lineHeight: "24px",
      padding: "10px 16px",
      fontSize: "16px",
      fontWeight: 600,
      height: "48px",
      width: "50%",
    },
    "& .AddCancelBtn": {
      color: "#000000",
      border: "1px solid #000000",
    },
    "& .AddDeleteBtn": {
      color: "white",
      backgroundColor: "#000000",
    }
  }
 });

 const MoveAddCampaignBox = styled(Box)({
   width: "100%",
  "& .moveheading":{
    color: "#4D4D4D",
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontSize: "16px",
    lineHeight: "21.79px",
  },
  "& .move_workspace":{
    color: "#4D4D4D",
    fontWeight: 600,
    fontFamily: "Open Sans",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .add_body_text":{
    fontFamily: "Open Sans",
    color: "#4D4D4D",
    lineHeight: "21px",
    fontWeight: 400,
    marginTop: "10px",
    fontSize: "14px",
  }
 });

const NewCampaignCardBox = styled(Box)({
   "& .addCardsBox": {
     gap: "12px",
     width: "100%",
     height: "350px",
     flexWrap: "wrap",
     display: "flex",
     scrollbarWidth: "0.5px !important",
     overflow: "scroll",
     },
    "& .addCampaignCard": {
      height: "168px",
      width: "200px",
      background: "#FFFDD8",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "1px solid #DDDDDDDD",
      position: "relative",
      '@media (max-width: 580px)': {
        width: "260px",
      },
     },
     "& .add_opacity":{
       opacity : "50%"
     },
    "& .addCampaigntitleBox":{
      alignItems: "center",
      display: "flex",
      position: "relative",
      justifyContent: "space-between",
      boxSizing: "border-box",
      padding: "25px 8px",
      height: "25px",
      width: "100%",
      "& .MuiTypography-body1": {
        color: "#4D4D4D",
        fontFamily: "Open Sans",
        fontWeight: 400,
        lineHeight: "21px",
        fontSize: "14px",
      }, 
      "& .addCampaign_date": {
        color: "#4D4D4D",
        fontFamily: "Open Sans",
        fontWeight: 400,
        lineHeight: "16.34px",
        fontSize: "12px",
        padding: "2px 8px",
        borderRadius: "100px",
        background: "#FFFBAA",
       },
       "& .addCampaign_img":{
         height: "32px",
         width: "32px",
         opacity: 1
       }
     },
  });

 const NewCampaignMainBox = styled(Box)({
   padding: "0 8px",
   "& .campaign_name": {
       color: "#000000",
       fontWeight: 600,
       fontFamily: "Open Sans",
       fontSize: "18px !important",
       lineHeight: "28px",
       display: '-webkit-box',
       padding: "0px 4px !important",
       WebkitLineClamp: 2,
       textOverflow: 'ellipsis',
       overflow: 'hidden',
       WebkitBoxOrient: 'vertical',
     }
 });

const AddCampaignSocialIconBox = styled(Box)({
    width: "calc(100% - 41px)",
    display: "flex",
    padding: "4px 12px",
    background: "#FFFBAA",
    justifyContent: "space-between",
    bottom: "14px",
    position: "absolute",
    height: "26px",
    borderRadius: "100px",
 });

const AddCampaignIconBox = styled(Box)({
   display: 'flex',
   alignItems: "center",
   gap: "8px",
   "& .social_icon_img":{
     height: "14px",
     width: "14px",
   },
   "& .socialCount":{
     color: "#4D4D4D",
     fontWeight: 400,
     fontFamily: "Open Sans",
     fontSize: "12px",
     lineHeight: "16px",
   }
 });
// Customizable Area End 