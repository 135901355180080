import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
} from "@mui/material";
import {validCheck, invalidCheck} from './assets'

interface PasswordValidatorProps {
    password: string;
    onValidate:(isValid:boolean)=>void;
  }
const PasswordValidator:React.FC<PasswordValidatorProps> =({password, onValidate})=>{
    const [hasSpecialCharacter, setHasSpecialCharacter]=useState(false );
    const [isValidPasswordLength, setIsValidPasswordLength]=useState(false );
    const [hasNumber, setHasNumber]=useState(false )
    const [hasUppercase, setHasUppercase]=useState(false)
    const [hasLowercase, setHasLowercase]=useState(false)
    useEffect(()=>{
        const validLength = password.length>=8;
        const isSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
        const isNumber= /\d/.test(password)
        // const isUppercaseCharacter = /[A-Z]/.test(password)
        // const isLowercaseCharacter = /[a-z]/.test(password)
        setIsValidPasswordLength(validLength)
        setHasNumber(isNumber);
        setHasSpecialCharacter(isSpecialCharacter)
        // setHasUppercase(isUppercaseCharacter)
        // setHasLowercase(isLowercaseCharacter)
        // && isLowercaseCharacter && isUppercaseCharacter  add to check upper and lower cae  validation 
        if(validLength && isNumber && isSpecialCharacter){
            onValidate(true)
        }else{
            onValidate(false)
        }
    },[password])
    return(
        <Box style={{display:"flex", flexDirection:"column", gap:"6px", width:"100%"}}>
            <Box style={{display:"flex", gap:"8px"}}>
                <img data-test-id='special-char'src={hasSpecialCharacter? validCheck:invalidCheck} style={webStyle.validationImage}/>
                <Typography style={webStyle.validationMessage}>
                    {"At least one special character"}
                </Typography>
            </Box>
            <Box style={{display:"flex", gap:"8px"}}>
                <img data-test-id='valid-length'src={isValidPasswordLength? validCheck:invalidCheck} style={webStyle.validationImage}/>
                <Typography style={webStyle.validationMessage}>
                    {"Minimum character length is 8 characters"}
                </Typography>
            </Box>
            <Box style={{display:"flex", gap:"8px"}}>
                <img data-test-id='one-number'src={hasNumber? validCheck:invalidCheck} style={webStyle.validationImage}/>
                <Typography style={webStyle.validationMessage}>
                    {"At least one number"}
                </Typography>
            </Box>
            {/* <Box style={{display:"flex", gap:"8px"}}>
                <img data-test-id='one-number'src={hasLowercase ? validCheck:invalidCheck} style={webStyle.validationImage}/>
                <Typography style={webStyle.validationMessage}>
                    {"At least one uppercase letter"}
                </Typography>
            </Box>
            <Box style={{display:"flex", gap:"8px"}}>
                <img data-test-id='one-number'src={hasUppercase ? validCheck:invalidCheck} style={webStyle.validationImage}/>
                <Typography style={webStyle.validationMessage}>
                    {"At least one lowercase letter"}
                </Typography>
            </Box> */}
        </Box>
    )

}

const webStyle={
    validationMessage:{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400},
    validationImage:{height:'13px', width:"14px"},
}
export default  PasswordValidator