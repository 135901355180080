import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import InteractivefaqsSharedController from "./InteractivefaqsSharedController";
import { downArrow } from "./assets";
// Customizable Area End


export default class Interactivefaqs extends InteractivefaqsSharedController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyles.mainContainer}>
                <FreqAskTitle>Frequently asked questions</FreqAskTitle>
                <Box style={webStyles.faqList}>
                    {
                        this.state.faq && this.state.faq.map((faqs, indexs) => {
                            return <FAQAccordion key={faqs.id}>

                                <AccordionSummary
                                    expandIcon={<img src={downArrow} />}
                                    aria-controls={`panel${indexs + 1}-content`}
                                    id={`panel${indexs + 1}-header`}
                                >
                                    <Typography className="question">{faqs.attributes.question}</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography className="answer">
                                        {faqs.attributes.answer}
                                    </Typography>
                                </AccordionDetails>

                            </FAQAccordion>
                        })
                    }
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const FreqAskTitle = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '32px',
    fontWeight: 600,
    color: '#4D4D4D',
    lineHeight: '43.58px',
    marginTop: '32px',
    '@media (max-width: 720px)': {
        fontSize: '28px',
        lineHeight: '40.37px',
    },
    '@media (max-width: 540px)': {
        fontSize: '24px',
        lineHeight: '32.37px',
    }
})

const webStyles = {
    mainContainer: {
        padding: '0 24px 24px 24px'
    },
    faqList: {
        marginTop: "24px",
        "& .MuiAccordion-root": {
            border: "2px solid pink"
        }
    }
}

const FAQAccordion = styled(Accordion)({
    boxShadow: 'none',
    marginBottom: "16px",
    '&:before': {
        display: 'none',
    },
    "& .question": {
        fontSize:'16px',
        color: '#4D4D4D',
        fontFamily: 'Open sans',
        fontWeight: 600,
    },
    "& .answer": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#7D7D7D",
        fontFamily: "Open sans"
    }
})
// Customizable Area End