import React from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Modal,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
    open: boolean,
    onClose: () => void,
    description: string,
    onChange: (description: string) => void,
    onRegenerate: () => void
    isImage: boolean,
}
const RegenerateModal: React.FC<Props> = ({ open, description, onChange, onClose, onRegenerate, isImage }) => {
    return (
        <Modal
            data-testid='regenerateImageModal'
            open={open}
            onClose={onClose}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <StyledRegenerateImageContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: "16px",
                        width: "100%",
                        alignItems: "center"
                    }}
                >
                    <Typography sx={{ fontFamily: "Open Sans", fontWeight: 600, fontSize: "20px", color: "#000000" }}>{isImage ? "Regenerate image" : "Regenerate video"}</Typography>
                    <Typography sx={{ fontFamily: "Open Sans", fontWeight: 400, fontSize: "16px", color: "#4D4D4D" }}>Add any specific changes you'd like to make, or simply click regenerate if none.</Typography>
                    <TextField
                        multiline
                        fullWidth
                        data-testid='regenerateInput'
                        placeholder='Enter you description'
                        rows={6}
                        value={description}
                        onChange={(event) => { onChange(event.target.value) }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                mt: 4,
                                borderRadius: '8px',
                                '& .MuiInputBase-input': {
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: "#4D4D4D",
                                },
                                '& fieldset': {
                                    borderColor: '',
                                    border: "1px solid #DDDDDDD"
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    background: "transparent"
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#EFEFEF',
                                },
                            },
                        }}
                    />
                    <StyledRegenerateImageModalButtonContainer>
                        <Button sx={webStyle.outlinedButton} fullWidth onClick={onClose} data-testid="btn-edit">
                            Cancel
                        </Button>
                        <Button sx={webStyle.darkModalButton} fullWidth onClick={onRegenerate} data-testid="regenerateImageButton">
                            {isImage ? "Regenerate image" : "Regenerate video"}
                        </Button>
                    </StyledRegenerateImageModalButtonContainer>
                </Box>
                <CloseOutlinedIcon sx={{ ...webStyle.closeIconStyle, color: "#0F172A" }} onClick={onClose} />
            </StyledRegenerateImageContainer>
        </Modal>
    )
}

export default RegenerateModal;

const StyledRegenerateImageContainer = styled(Box)({
    position: "relative",
    width: 'clamp(250px, 80vw, 529px)',
    height: 'fit-content',
    display: 'flex',
    flexDirection: "column",
    background: "#ffffff",
    borderRadius: '20px',
    padding: "48px 24px 24px 24px",
    alignItems: "center",
})

const StyledRegenerateImageModalButtonContainer = styled(Box)({
    display: "flex",
    gap: '32px',
    marginTop: "16px",
    justifyContent: "space-between",
    width: "100%",
    '@media (max-width: 459px)': {
        flexDirection: "column-reverse",
        justifyContent: "start",
        alignItems: "center",
        gap: '16px',
    }
})


const webStyle = {
    outlinedButton: {
        background: "#ffffff",
        textTransform: "none",
        color: 'black',
        border: "2px solid black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "8px",
        height: '48px',
        "&:hover": {
            background: "#ffffff"
        },
    },
    darkModalButton: {
        background: "#000000",
        textTransform: "none",
        color: '#ffffff',
        border: "2px solid black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "8px",
        height: '48px',
        "&:hover": {
            background: "#000000"
        },
    },
    closeIconStyle: {
        position: "absolute",
        color: "#FFFFFF",
        fontSize: "24px",
        cursor: "pointer",
        zIndex: 5,
        top: 19,
        right: 19,
    },

}