export const WriteArrow =require("../assets/WriteArrow.svg").default
export const userDefaultImg=require("../assets/UserdefaultImg.svg").default
export const EditImageicon=require("../assets/EditImageIcon.svg").default
export const cloudUploadIcon =require("../assets/cloud.svg").default
export const profileImageCover =require("../assets/profileImageCover.svg").default
export const deletesIcon = require("../assets/deleteIcon.png").default;
export const renameIcon = require("../assets/renameIcon.png").default;
export const successIcon = require("../assets/success_image.png").default;
export const workspaceButton = require("../assets/workspaceButton.png").default;
export const addButton = require("../assets/addButton.png").default;
export const backArrowIcon = require("../assets/backArrow.png").default;
export const facebookIcon = require("../assets/facebook.png").default;
export const subcribeIcon = require("../assets/subscribe.png").default;
export const instaIcon = require("../assets/insta.png").default;
export const blogIcon = require("../assets/blogIcon.png").default;
export const removeIcon = require("../assets/remove.png").default;
export const moveIcon = require("../assets/move.png").default;
export const selectIcon = require("../assets/campaignSelect.png").default;