import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

const DottedCircularLoader: React.FC = () => {
  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    
    const keyframes = `
      @keyframes loader {
        0% { opacity: 1; }
        50% { opacity: 0.3; }
        100% { opacity: 1; }
      }
    `;
    style.appendChild(document.createTextNode(keyframes));
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const loaderStyles = {
    container: {
      height: '90px',
      width: '90px',
      position: 'relative',
    },
    dot: {
      position: 'absolute',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#666666',
      animation: 'loader 0.8s linear infinite',
    },
  };

  const generateDotStyles = (index: number) => {
    const angle = (index / 7) * 2 * Math.PI;
    const radius = 25;
    const left = 50 + radius * Math.cos(angle);
    const top = 50 + radius * Math.sin(angle);

    return {
      transform: `translate(-50%, -50%)`,
      left: `${left}%`,
      top: `${top}%`,
      animationDelay: `${index * 0.1}s`,
    };
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Box sx={loaderStyles.container}>
        {[...Array(7)].map((_, index) => (
          <Box
            key={index}
            sx={{ ...loaderStyles.dot, ...generateDotStyles(index) }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DottedCircularLoader;
