import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ValidResponseType {
    success: boolean,
    statusCode: number,
    message: string,
    data: WorkspaceList;
    errors: Array<string>;
    error: {
        code: string,
        message: string,
        details: string
    }
}
export interface InvalidResponseType {
    errors: Array<string>;
}

export interface WorkspaceList {
    workspace: {
        id: string,
        name: string,
        account_id: number,
        campaigns: Array<object>,
        communitys: Array<object>,
        employees: Array<object>
    }
}

export interface CampaignList {
    data : Array<CampaignData>;
}

export interface CampaignData {
    id: string,
    type: string,
    attributes: {
        id: string,
        name: string,
        created_at: string,
        updated_at: string,
    },
    relationships: {
        account: {
            data: {
                id: string,
                type: string
            }
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    campaignData: Array<CampaignData>;
    openAddEmployee: boolean;
    isShow: boolean;
    newWorkspace: string;
    isLoading: boolean;
    openSuccessModal: boolean;
    openEmployeeAdd: boolean;
    openAddCampaign: boolean;
    selectedIds: Array<string>;
    workspaceId: string;
    openCampaignAdded: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class NewWorkspaceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    authToken: string = "";
    userId: string = "";
    createNewWorkspaceApiCallId: string = "";
    getCampaignApiCallId: string = "";
    addCampaignApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            campaignData: [],
            openAddEmployee: false,
            isShow: false,
            newWorkspace: "",
            isLoading: false,
            openSuccessModal: false,
            openEmployeeAdd: false,
            openAddCampaign: false,
            selectedIds: [],
            workspaceId: "",
            openCampaignAdded: false
         };
          runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
                  let apiRequestCallId = message.getData(
                   getName(MessageEnum.RestAPIResponceDataMessage)
              );
                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
             );
             if (this.isValidNewWorkspaceController(responseJson)){
                 this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
             }
             if (this.isInValidNewWorkspaceController(responseJson)){
                 this.apiFailureCallBacksController(apiRequestCallId, responseJson);
             }
         }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.userId = await getStorageData('userId');
        this.authToken = await getStorageData('authToken');
    }

    newWorkspaceApiCall = async (valueData: {
        contentType?:string;
        method?:string;
        endPoint?:string;
        body?:{};
        apiType?:string;
        type?:string;
     }) => {
        const token = this.authToken;
        let { contentType, method, endPoint, body } = valueData;
        let header = {
            "Content-Type": contentType,
            token,
        };
         let requestMessage = new Message(
             getName(MessageEnum.RestAPIRequestMessage)
         );
         requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
         );
         requestMessage.addData(
             getName(MessageEnum.RestAPIRequestMethodMessage),
             method
         );
         body &&
         requestMessage.addData(
             getName(MessageEnum.RestAPIRequestBodyMessage),
             body
         );
         requestMessage.addData(
             getName(MessageEnum.RestAPIResponceEndPointMessage),
             endPoint
         );
         runEngine.sendMessage(requestMessage.id, requestMessage);
         return requestMessage.messageId;
     };

    isInValidNewWorkspaceController = (responseJson: InvalidResponseType) =>{
        return responseJson && responseJson.errors;
    };

    isValidNewWorkspaceController = (responseJson: ValidResponseType) =>{
         return responseJson && !responseJson.errors;
     };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: ValidResponseType & CampaignList) => {
        if (apiRequestCallId === this.createNewWorkspaceApiCallId) {
            this.createNewWorkspaceSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getCampaignApiCallId) {
            this.getCampaignSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.addCampaignApiCallId) {
            this.addCampaignSuccessCallBack(responseJson)
        }
    };

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.createNewWorkspaceApiCallId) {
            this.createNewWorkspaceFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.getCampaignApiCallId) {
            this.getCampaignFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.addCampaignApiCallId) {
            this.addCampaignFailurCallBack(responseJson)
        }
    };

    openAddEmployee= () => {
        this.setState({ openAddEmployee: true });
    };

    closeAddEmployee = () => {
        this.setState({ openAddEmployee: false});
    };

    closeSuccessWorkspace = () => {
        this.setState({ openSuccessModal: false });
    };

    closeEmployeeAdded = () => {
        this.setState({ openEmployeeAdd: false });
    };

    openEmployeeAdded = () => {
        this.setState({ openEmployeeAdd: true, openAddEmployee: false })
    };

    handleNewWorkspace = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newWorkspace: event.target.value })
    };

    openAddCampaigns = () => {
        this.setState({ openAddCampaign: true },()=>{
            this.handleGetCampaignList();
        });
    };

    closeAddCampaigns = () => {
        this.setState({ openAddCampaign: false });
    };


    closeCampaignsAdded = () => {
        this.setState({ openCampaignAdded: false },()=>{
            this.goToMyWorkspace()
        });
    };

    goToMyWorkspace = () => {
        const contentResult = new Message(getName(MessageEnum.NavigationMessage));
        contentResult.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "MyWorkspaces"
        );
        contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(contentResult);
    };

    handleCreateNewWorkspace = async () => {
        const { newWorkspace } = this.state;
        let apiBody = {
            workspace: {
                name: newWorkspace
            }
        }

        this.createNewWorkspaceApiCallId = await this.newWorkspaceApiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.postApiMethod,
          endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces`,
          body: JSON.stringify(apiBody)
        });
      };
    
    createNewWorkspaceSuccessCallBack = (responseJson:  ValidResponseType) => {
        if (responseJson.data.workspace) {
            this.setState({ openSuccessModal: true, isShow: true, newWorkspace: responseJson.data.workspace.name, workspaceId: responseJson.data.workspace.id })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
      }
    
    createNewWorkspaceFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }

    handleGetCampaignList = async () => {
        this.setState({ isLoading : true})
        this.getCampaignApiCallId = await this.newWorkspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.campaignEndPoint}${this.userId}/campaigns`,
        });
    };

    getCampaignSuccessCallBack = (responseJson: CampaignList) => {
        if (responseJson.data) {
        this.setState({ campaignData : responseJson.data, isLoading: false})
        }
    }

    getCampaignFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleToggleSelect = (campaign : CampaignData ) => {
        const { selectedIds } = this.state;
        const campaignId = campaign.attributes.id
        if (selectedIds.includes(campaignId)) {
          this.setState({
            selectedIds: selectedIds.filter(selectedId => selectedId !== campaignId)
          });
        } else {
          this.setState({
            selectedIds: [...selectedIds, campaignId]
          });
        }
    }
    
    handleCampaignId = ( campaign : CampaignData ) =>{
        const { selectedIds } = this.state;
        return selectedIds.includes(campaign.attributes.id)
    }

    addCampaignToWorkspace = async () => {
        const { selectedIds, workspaceId } = this.state;
        let apiBody = {
          campaign_ids : selectedIds
        }

        this.addCampaignApiCallId = await this.newWorkspaceApiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.patchApiMethod,
          endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${workspaceId}/add_campaign`,
          body: JSON.stringify(apiBody)
        });
      };
    
    addCampaignSuccessCallBack = (responseJson: ValidResponseType) => {
        if (responseJson.data.workspace) {
            this.setState({ openCampaignAdded: true, openAddCampaign: false })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
      }
    
    addCampaignFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }
    // Customizable Area End
}