import React from 'react';
import { useDropzone } from 'react-dropzone';
interface Props {
  children:React.ReactNode
  onSelect:(file:File| null)=>void;
}
const DragAndDropImageSelector:React.FC<Props> = ({children, onSelect}) => {

  const onDrop = (acceptedFiles:any) => {
    onSelect(acceptedFiles[0])
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
  });

  return (
    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

const dropzoneStyle = {
  width: '100%',
  height: '100%',
  background:"transparent",
};

const imageStyle = {
 
};

export default DragAndDropImageSelector;
