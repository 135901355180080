import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";

export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface Chat {
  id: string
  type: string
  attributes: Attributes
  relationships: Relationships
}

export interface ChatHistory {
  id: string
  type: string
  attributes: Attributes2
  relationships: Relationships
}

export interface Attributes {
  id: number;
  account_id: number;
  seo_chat_id: number;
  device_uuid: string;
  url: string;
  starting_point: string;
  url_and_starting_point: string;
  recommendation: string;
  created_at: string;
  updated_at: string;
}
export interface Relationships {
  account: Account
}
export interface RootObject {
  data: Data2;
}
export interface Data2 {
  id: string;
  type: string;
  attributes: Attributes2;
  relationships: Relationships;
}
export interface Attributes2 {
  id: number;
  account_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  seo_recommendations: Seorecommendations;
}
export interface Seorecommendations {
  data: Datum[];
}
export interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
}
export interface Account {
  data: Data
}
export interface Data {
  id: string
  type: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isChatPage: boolean;
  isLoading: boolean;
  contentText: string;
  selectedBtn: number;
  selectedBtnTxt: string;
  openChatHistory: boolean;
  enteredLink: string;
  chatName: string;
  currChatId: string;
  chatContinueTxt: string;
  chatBubblesList: Chat[];
  chatHistoryList: ChatHistory[];
  chatHistorySorted: {[date: string]: ChatHistory[]},
  selectedChatFromSidebar: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfmarkyaiapiintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNewChatPostId: string = "";
  apiContinueChatPostId: string = "";
  apiHistoryId: string = "";
  apiOldChatsId: string = "";
  authToken: string = "";
  userId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isChatPage: false,
      isLoading: false,
      contentText: "",
      selectedBtn: 0,
      selectedBtnTxt: "SEO Analysis",
      openChatHistory: false,
      chatName: "",
      currChatId: "",
      chatContinueTxt: "",
      enteredLink: "",
      chatHistoryList:[],
      chatHistorySorted: {},
      chatBubblesList:[],
      selectedChatFromSidebar: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.apiNewChatPostId){
        this.setState({chatBubblesList:responseJson.data.attributes.seo_recommendations.data, currChatId: responseJson.data.attributes.id.toString(), isLoading: false, enteredLink:'', chatContinueTxt:''});
      }
      if(apiRequestCallId === this.apiContinueChatPostId){
        this.setState({chatBubblesList: [...this.state.chatBubblesList, responseJson.data], isLoading: false, chatContinueTxt:''});
      }
      if(apiRequestCallId === this.apiHistoryId){
        this.setState({chatHistoryList:responseJson.data, chatHistorySorted: this.groupAndSortByDate(responseJson.data)});
      }
      if(apiRequestCallId === this.apiOldChatsId){
        this.setState({chatBubblesList: responseJson.data, chatName: responseJson.data[responseJson.data.length-1].attributes.url})
      }
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
  }
  
  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getSeo = () => {
    this.setState({isLoading:true, isChatPage:true});
    this.apiNewChatPostId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method:'POST',
      endPoint: `bx_block_cfmarkyaiapiintegration/accounts/${this.userId}/seo_recommendations/new_chat`,
      body:{
        "url": this.state.enteredLink,
        "starting_point": this.state.selectedBtnTxt
      }
    })
  }

  continueChat = (txt: string, chatId: string) => {
    this.setState({isLoading:true});
    this.apiContinueChatPostId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method:'POST',
      endPoint: `bx_block_cfmarkyaiapiintegration/accounts/${this.userId}/seo_recommendations/check_seo`,
      body: {
        "url": txt,
        "starting_point": "",
        "seo_chat_id": chatId
      }
    })
  }

  getHistory = () => {
    this.apiHistoryId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method:'GET',
      endPoint: `bx_block_cfmarkyaiapiintegration/accounts/${this.userId}/seo_recommendations/load_chats`
    })
  }

  getOldChats = (chatId: string) => {
    if(chatId != ""){
      if(chatId === this.state.currChatId){
        this.setState({selectedChatFromSidebar: 0});
      } else{
        this.setState({selectedChatFromSidebar: parseInt(chatId)});
      }
      this.apiOldChatsId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method:'GET',
        endPoint: `bx_block_cfmarkyaiapiintegration/accounts/${this.userId}/seo_recommendations/show_chats?seo_chat_id=${chatId}`
      })
    } else{
      this.setState({chatBubblesList:[], chatName:'New Chat', selectedChatFromSidebar: 0})
    }
  }

  handleCheckSeo = () => {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:\d{1,5})?(\/[^\s]*)?$/;
    if(regex.test(this.state.enteredLink)){
      this.setState({chatName:this.state.enteredLink, chatBubblesList: []});
      this.getSeo();
      this.getHistory();
    } else{
      toast.error("Enter valid link")
    }
  }

  handleGenerateContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ contentText: event.target.value, enteredLink: event.target.value });
  }

  handleSelectedBtn = (txt: string, num: number) => {
    if (num === this.state.selectedBtn) {
      this.setState({ selectedBtn: 0, selectedBtnTxt: "SEO Analysis" });
    } else {
      this.setState({ selectedBtn: num, selectedBtnTxt: txt })
    }
  }

  handleNewChatButtonClicked = () => {
    this.getHistory();
    this.setState({ chatBubblesList: [], chatName: "New Chat", currChatId: "", selectedChatFromSidebar: 0 });
  }

  handleChatInputChange = (val: string) => {
    this.setState({chatContinueTxt:val});
    if(this.state.chatBubblesList.length === 0){
      this.setState({enteredLink:val});
    }
  }

  handleChatScreenInputButton = () => {
    if (this.state.chatBubblesList.length != 0) {
      if(this.state.chatContinueTxt != ""){
        this.continueChat(this.state.chatContinueTxt, this.state.chatBubblesList[0].attributes.seo_chat_id.toString())
      }
    } else {
      this.handleCheckSeo()
    }
  }

  handleBackToSeoPage = () => {
    this.setState({
      isChatPage: false,
      isLoading: false,
      contentText: "",
      selectedBtn: 0,
      selectedBtnTxt: "SEO Analysis",
      openChatHistory: false,
      chatName: "",
      currChatId: "",
      chatContinueTxt: "",
      enteredLink: "",
      chatHistoryList: [],
      chatHistorySorted: {},
      chatBubblesList: [],
      selectedChatFromSidebar: 0
    })
  }

  groupAndSortByDate(recommendations: ChatHistory[]) {
    const grouped: {[date: string]: ChatHistory[]} = {};
  
    recommendations.forEach((item: ChatHistory) => {
      const date = item.attributes.created_at.split('T')[0];
  
      if (!grouped[date]) {
        grouped[date] = [];
      }
  
      grouped[date].push(item);
    });
    return grouped;
  }

  formatDate(inputDateString: string) {
    const inputDate = new Date(inputDateString);
    const today = new Date();
  
    today.setHours(0, 0, 0, 0);
  
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const inputDateFormatted = `${String(inputDate.getDate()).padStart(2, '0')}.${String(inputDate.getMonth() + 1).padStart(2, '0')}.${inputDate.getFullYear()}`;
  
    if (inputDate.getTime() === today.getTime()) {
      return 'Today';
    } else if (inputDate.getTime() === yesterday.getTime()) {
      return 'Yesterday';
    } else {
      return inputDateFormatted;
    }
  }
  // Customizable Area End
}
