import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  { ChangeEvent } from 'react';
import { getStorageData, setStorageData } from "framework/src/Utilities";
const DOMPurify = require('dompurify');
import { v4 as uuidv4 } from 'uuid';
import { Slide, toast } from "react-toastify";

export interface ChatJarvysType {
  question : string
  jarvysAnswer : string
} 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  values: Array<ChatJarvysType>
  isGoogleLogedInFailed: boolean
  isOpenSignUpForm:boolean
  isOpenLogInForm:boolean
  isOpenForgetPasswordForm:boolean;
  isOpenForgetPasswordOtpForm:boolean;
  userMessage:string;
  userquestion:string
  userMessageResponse:string;
  isBackgroundVideoAvailable:boolean
  jarvysmodelopenlanding:boolean;
  openLoginSignup: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.closeSignUpFormMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.hasVideoMessage),
    ];

    this.state = {
      values:[],
      isGoogleLogedInFailed: false,
      isOpenSignUpForm:false,
      isOpenLogInForm:false,
      userMessage:"",
      userMessageResponse:"",
      userquestion:"",
      isBackgroundVideoAvailable:false,
      isOpenForgetPasswordForm:false,
      isOpenForgetPasswordOtpForm:false,
      jarvysmodelopenlanding:false,
      openLoginSignup: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveResponseHandler(from,message)
    runEngine.debugLog("Message Recived", message);
    if(getName(MessageEnum.hasVideoMessage)=== message.id){
      this.setState({isBackgroundVideoAvailable:true})
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

    let prevchats= await getStorageData('chats') 
    if (prevchats){
      let chats  = JSON.parse(prevchats)
      this.setState({values:chats.slice(-5)})
    }
  }

  async componentDidUpdate(prevProps: {}, prevState: Readonly<S>) {
    if (prevState.values.length !== this.state.values.length) {
      let prevchats= await getStorageData('chats') 
      if (prevchats){
        let chats  = JSON.parse(prevchats)
         this.setState({values:chats.slice(-5)})
    }
  }
    
  }

  getMessageResponseApiCallId:string="";
  getMessageResponseApiCallIdLanding: string="";

  async receiveResponseHandler(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (this.isMessageResponse(apiRequestCallId)) {
      this.handleMessageResponse(responseJson);
    }
  }
  
  isMessageResponse(apiRequestCallId: string) {
    return apiRequestCallId === this.getMessageResponseApiCallId;
  }
  
  handleMessageResponse(responseJson: any) {
    if (responseJson.error === "Please subscribe to ask more questions") {
      this.setState({ openLoginSignup: true, jarvysmodelopenlanding: false });
    } else if (responseJson.content) {
      this.responcehandler('<p>' + responseJson.content.replaceAll('\n', '<br>') + '<p>');
    } else {
      this.setState({ jarvysmodelopenlanding: false });
      toast.error("Something went wrong, Please try again later!");
    }
  }


  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  closeLoginSignup = () => {
    this.setState({ openLoginSignup: false })
  }

  handleOpenSignUpForm(){
    this.setState({ openLoginSignup: false})
    const message: Message = new Message(getName(MessageEnum.openSignUpFormMessage));
    message.addData(getName(MessageEnum.openSignUpFormMessage),'Open Sign up Form')
    runEngine.sendMessage(getName(MessageEnum.openSignUpFormMessage), message)
  }

  handleuserQuestion(userMessagess:string){
    this.setState({userquestion:userMessagess})
  }

  handleOpenLogInForm(){
    this.setState({ openLoginSignup: false})
    const message: Message = new Message(getName(MessageEnum.openLogInFormRegistrationMessage));
    message.addData(getName(MessageEnum.openLogInFormRegistrationMessage),'Open Login Form')
    runEngine.sendMessage(getName(MessageEnum.openLogInFormRegistrationMessage), message)
  }

  handleUserMessageChange(event:ChangeEvent<HTMLInputElement>){
    this.setState({userMessage:event.target.value})
  }
  async askToJarvys(){
    if(this.state.userMessage.length>0){
      let device_Id=await getStorageData("deviceId")
      const token= await getStorageData("authToken")
      if(!device_Id){ 
        device_Id= uuidv4()
        await setStorageData("deviceId",device_Id)
      }
        this.setState({jarvysmodelopenlanding:true })
      this.handleuserQuestion(this.state.userMessage)
      const header : {contentType : string , token? : string} = {
        contentType: configJSON.validationApiContentType,
      };  
      if(token){
        header.token = token;
      }
      const endPoint=`${configJSON.getMessageResponseFromJarvysEndPont}?question=${this.state.userMessage}&device_uuid=${device_Id}`
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMessageResponseApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  responcehandler(content:string) {
    this.setState({jarvysmodelopenlanding:false})

    const contentData: ChatJarvysType = {
      question: this.state.userquestion,
      jarvysAnswer: content,
    };
    this.setState((prevState) => ({
      values: [...prevState.values, contentData],
      userMessage: "" 
    }));

    setStorageData('chats',JSON.stringify(this.state.values.slice(-5)))
    this.props.navigation.navigate("Brainstromwithjarvis");
  }
 
  handleclosejarvysmodel(){
    this.setState({jarvysmodelopenlanding:false})
  }
  
  // Customizable Area End
}
