// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const btnbackIcon = require("../assets/button_back.png").default;
export const dislikeIcon = require("../assets/dislike_icon.png").default;
export const editIcon = require("../assets/edit_icon.png").default;
export const reloadIcon = require("../assets/reload_icon.png").default;
export const sendIcon = require("../assets/send_icon.png").default;
export const speakerIcon = require("../assets/speaker_icon.png").default;
export const closeIcon = require("../assets/closeIcon.png").default;
export const searchIcon = require('../assets/search.png').default;
export const starIcon = require('../assets/star.png').default;
export const blogIcon = require('../assets/blog.png').default;
export const campaignIcon = require('../assets/campaign.png').default;
export const productIcon = require('../assets/instaProduct.png').default;
export const subcriberIcon = require('../assets/subcriber.png').default;
export const arrowSelect = require('../assets/arrowselect.png').default;

// Customizable Area End
