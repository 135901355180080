
import React from "react";
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { facebookIcon, inboxIcon, instagramIcon, noteICon } from "../assets";
import MenuButton from "./MenuButton";

interface Props {
    onRename: (id:string, newName: string) => void
    onDelete: (id: string) => void
    onClick: (id:string, name:string) => void
    campaign: {
        id: string,
        type: string,
        attributes: {
            id: number
            name: string,
            created_at: string,
            updated_at: string
        },
        relationships: {
            account: {
                data: {
                    id: string,
                    type: string
                }
            }
        }
    },
}
const CampaignCard: React.FC<Props> = ({ campaign, onRename, onDelete, onClick }) => {
    const handleDelete = () => {
        onDelete(campaign.id)
    }
    const handleRename = (name:string) => {
        onRename(campaign.id, name)
    }

    const handleOnClick = () => {
        onClick(campaign.id, campaign.attributes.name);
    }

    return (
        <>
            <StyledCampaignCard data-testid="campaignCard" onClick={handleOnClick}>
                <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", gap: "12px", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', width: "100%", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                        <Typography sx={{ fontSize: "12px", fontFamily: "Open Sans", fontWeight: 400, borderRadius: "100px", background: "#FFFBAA", color: "#4D4D4D", padding: "2px 8px" }}>
                            {`Created on: ${new Date(campaign.attributes.created_at).toLocaleString('en-US', { day: '2-digit' , month: 'short'})} `}
                        </Typography>
                        <MenuButton data-testid="menuBtn" onRename={handleRename} onDelete={handleDelete} from="campaign" contentName={campaign.attributes.name}/>
                    </Box>
                    <Box sx={{ flexGrow: 1, overflowY: "auto", fontSize: "24px", fontFamily: "Open Sans", fontWeight: 600, color: "#000000", padding: "2px" }}>
                        {campaign.attributes.name!}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "220px", padding: "8px 16px 8px 16px", background: "#FFFBAA", borderRadius: "100px" }}>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={facebookIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'0'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={inboxIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'0'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={instagramIcon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'0'}</span>
                        </Box>
                        <Box sx={{ display: "flex", width: "fit-content", justifyContent: "center", alignItems: "center", gap: "8px" }}>
                            <img src={noteICon} />
                            <span style={{ fontSize: "12", fontFamily: "Open Sans", fontWeight: 400, color: "#4D4D4D" }}>{'0'}</span>
                        </Box>
                    </Box>
                </Box>
            </StyledCampaignCard>
        </>
    )
}

export default CampaignCard;

const StyledCampaignCard = styled(Box)({
    width: "260px",
    height: '218px',
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFDD8",
    borderRadius: "8px",
    padding: "24px 16px 24px 16px",
    gap: "36px",
    '&:hover': {
        background: "#FCF670",
        cursor: "pointer"
    },
    '@media (max-width: 363px)': {
        width: 'clamp(150px, 70vw, 260px)',
    }
})