// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Modal,
  Paper
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { facebooklogo, instagramlogo, twitterlogo, tiktoklogo, magnifylicon, bgwhitefacebooklogo, bgwhiteinstagramlogo, bgwhitetwitterlogo, bgwhitetiktoklogo, Loader, buttondesign } from "./assets";
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageController, {
  Props, configJSON
} from "./LandingPageController";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Footerbar from "../../../components/src/Footerbar"

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

  }


Gradienttext=()=>{
return(
<>
    <StyledTextGradient
      style={{
        background: this.state.isBackgroundVideoAvailable ? 'none' : 'linear-gradient(to right, #0066FF, #E8CC17)',
        WebkitBackgroundClip: this.state.isBackgroundVideoAvailable ? 'none' : 'text',
        WebkitTextFillColor: this.state.isBackgroundVideoAvailable ? 'white' : 'transparent',
      }}>Talk with JARVYS</StyledTextGradient>
  </>
)

}

renderLoginSignupPage = () =>{
  return(
    <ModalLandingContainer
    open={this.state.openLoginSignup}
    disablePortal
    disableEnforceFocus
    disableAutoFocus
    className="deleteModal"
  >
    <Paper className="JarvaysLandingPaper">
      <IconButton
        onClick={this.closeLoginSignup}
        className="closeJarvysLandingBtn"
      >
        <CloseIcon />
      </IconButton>
      <Typography className="jarvaysLandingMsgBox">
          {configJSON.jarvysHeadMsg}
      </Typography>
      <Typography className="jarvys_landing_platform_msg">{configJSON.platformMessgae}</Typography>
      <Box className="buttonJarvysLandingBox">
        <Button className="signupLandingBtn" data-testid='signupLandingBtn' onClick={this.handleOpenSignUpForm.bind(this)}>
          {configJSON.signupText}
        </Button>
        <Button className="loginLandingBtn" data-testid="loginLandingBtn" onClick={this.handleOpenLogInForm.bind(this)}>{configJSON.loginText}</Button>
      </Box>
    </Paper>
  </ModalLandingContainer>
  )
}


  render() {
    return (
      <>
        <StyledCenterText sx={{color:this.state.isBackgroundVideoAvailable ? '#FFFFFF ' : '#000000'}}
        >
          Marketing Made Easy
        </StyledCenterText>
        <MainContainer>
          <StyleLandingContainer>
            <TalkJarvysBox>
              {this.Gradienttext()}
              <StyleJarvysParentBoxInput>
                <StyleJarvysInput style={{ border: this.state.isBackgroundVideoAvailable ? '#FFFFFF' : '2px solid #DDDDDD' }} >
                  <img src={magnifylicon} alt="magnicon" style={{ marginLeft: '12px' }} />
                  <TextField
                    className="jarvys_textField"
                    placeholder="Talk with JARVYS"
                    data-testid={"messageInput"}
                    fullWidth
                    multiline
                    maxRows={1}
                    variant="standard"
                    sx={{
                      "& fieldset": { border: 'none' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontWeight: 400,
                        border: 'none',
                        paddingLeft: '10px',
                        color: this.state.isBackgroundVideoAvailable ? '#000000' : '#4D4D4D',
                      },
                    }}
                    value={this.state.userMessage}
                    onChange={this.handleUserMessageChange.bind(this)}
                  />
                </StyleJarvysInput>
                <StyleJarvysBoxButton
                  data-test-id="goToHomeButton"
                  data-testid="askToJarvysButton"
                  style={{
                    background: this.state.isBackgroundVideoAvailable ? 'linear-gradient(to right, #0066FF, #E8CC17)' : '#202020',

                  }}
                  test-data={this.state.userMessageResponse}
                  onClick={this.askToJarvys.bind(this)}>
                  Ask JARVYS<img
                    src={buttondesign}
                    alt="Icon"
                    style={{ marginLeft: '8px' }}
                  />
                </StyleJarvysBoxButton>
              </StyleJarvysParentBoxInput>
            </TalkJarvysBox>
          </StyleLandingContainer>
        </MainContainer>
        <Box sx={{ flexShrink: 0 }}>
          <Footerbar isActive={this.state.isBackgroundVideoAvailable} />
        </Box>
        {this.renderLoginSignupPage()}
        <Modal data-testid="jarvysmodel"
          open={this.state.jarvysmodelopenlanding}
          aria-labelledby="email-verified-title"
          aria-describedby="email-verified-description"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Box style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: 'fit-content',
            width: "fit-content",
            margin: "10px",
            position: "relative",
          }}>

            <StyledModelContainer>
              <StyledModelInnerContainer>
                <Box display={'flex'} justifyContent={'end'} >
                  <IconButton onClick={this.handleclosejarvysmodel.bind(this)} data-testid="closejarvysmodelbutton">
                    <CloseIcon style={{ color: '#0F172A' }} />
                  </IconButton>

                </Box>

                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={'2vw'}>

                  <StyledModelTextGradient
                    style={{

                      background: 'linear-gradient(to right, #0066FF, #E8CC17)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}>JARVYS AI</StyledModelTextGradient>

                  <Box
                    sx={{
                      width: '51px',
                      height: '50px',
                      animation: 'rotate 2s linear infinite',
                    }}
                  >
                    <img
                      src={Loader}
                      alt="Loading..."
                      style={{
                        width: '51px',
                        height: '50px',
                        animation: 'rotate 2s linear infinite',
                      }}
                    />
                  </Box>
                  <ModelBottomTypo>JARVYS processes your question...</ModelBottomTypo>
                </Box>

              </StyledModelInnerContainer>
            </StyledModelContainer>
          </Box>
        </Modal>
      </>
    );
  }
}

const MainContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  overflow: 'auto',
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

const StyledModelContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  maxHeight: '85vh',
  width: "40vw",
  padding: "40px",
  overflowY: "auto",
  borderRadius: '12px',
  '@media (max-height: 615px)': {
    maxHeight: '82vh',
  },
  '@media (max-height: 530px)': {
    maxHeight: '80vh',
  },
  '@media (max-height: 430px)': {
    maxHeight: '76vh',
  },
  '@media (max-height: 350px)': {
    maxHeight: '71vh',
  },
  '@media (max-height: 290px)': {
    maxHeight: '65vh',
  },
  '@media (max-height: 250px)': {
    maxHeight: '50vh',
  },
  '@media (max-height: 200px)': {
    maxHeight: '40vh',
  },
  '@media (max-height: 100px)': {
    maxHeight: '25vh',
  },
  "::-webkit-scrollbar": {
    display: 'none',
  },
  "-ms-overflow-style": 'none',
  "scrollbar-width": 'none',

});

const StyledModelInnerContainer = styled(Box)({
  width: '100%',
  height: "100%",
  '@media (max-width: 858px)': {
    flexDirection: 'column-reverse',
    justifyContent: "start",
    gap: "0px",
  },
});

const StyledModelTextGradient = styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '64px',
  '@media (max-width: 858px)': {
    fontSize: '5vw',
  }
})

const ModelBottomTypo = styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  color: '#515151',
  '@media (max-width: 858px)': {
    fontSize: '2vw',
  }
})

const StyleLandingContainer = styled(Box)({
  width: "100%",
  "& .backdrop_modal": {
    zIndex: 1500
  }
});


const StyledCenterText = styled(Typography)({
  textAlign: 'center',
  fontSize: '72px',
  fontWeight: 500,
  fontFamily: 'Open Sans',
  position: "absolute",
  top: 300,
  width: "100%",
  '@media (max-height: 1800px)': {
    top: 250,
  },
  '@media (max-height: 1440px)': {
    top: 150,
  },
  '@media (max-height: 725px)': {
    top: 120,
  },
  '@media (max-height: 686px)': {
    top: 100,
  },
  '@media (max-height: 600px) and (max-height: 883px)': {
    top: 70,
  },
  '@media (max-width: 1439px)': {
    fontSize: 'clamp(22px,6vw, 72px)',
  },
  '@media (max-width: 415px) and (min-height: 883px)': {
      top: 200,
  }
})

const StyledTextGradient = styled(Typography)({
  background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  WebkitBackgroundClip: 'text',
  fontFamily: 'Georgia',
  textAlign: 'center',
  fontSize: '96px',
  '@media (max-width: 1439px)': {
    fontSize: 'clamp(24px,8.6vw, 90px)',
  },

})


const StyleJarvysInput = styled(Box)({
  border: "2px solid #434343",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'clamp(250px,60vw, 1000px)',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  minHeight: "60px",
  backgroundColor: '#FFFFFF',
  '@media (max-width: 1200px)': {
    width: 'clamp(250px,70vw, 900px)',
  },
  '@media (max-width: 900px)': {
    width: 'clamp(250px,65vw, 750px)',
  },
  '@media (max-width: 700px)': {
    borderTopLeftRadius: '100px',
    borderBottomLeftRadius: '100px',
    borderRadius: "60px",
    alignItems: 'center',
    width: '90%',
    gap: '20px',
    height: "60px",
    backgroundColor: '#FFFFFF',
  },
  "& .jarvys_textField": {
    "& .MuiOutlinedInput-input": {
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .MuiInputBase-inputMultiline": {
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }
})


const StyleJarvysParentBoxInput = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 700px)': {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  }
})

const StyleJarvysBoxButton = styled(Button)({
  color: '#FFFFFF',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  fontFamily: 'Open Sans',
  padding: '6px 50px',
  cursor: 'pointer',
  '@media (max-width: 700px)': {
    borderRadius: '100px',
    padding: '20px 60px',
    width: "91%"
  }

})

const ModalLandingContainer = styled(Modal)({
  padding: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .closeJarvysLandingBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .JarvaysLandingPaper": {
    maxHeight: 'fit-content',
    width: "520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
      width: "420px",
    },
    "@media (max-width: 460px)": {
      width: "300px",
      padding: "20px",
    },
  },
  "& .jarvys_landing_platform_msg": {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign: "center",
    fontSize: '18px',
    color: '#4D4D4D',
    marginTop: "40px",
    "@media (max-width: 460px)": {
      marginTop: "15px",
    },
  },
  "& .jarvaysLandingMsgBox": {
    background: 'linear-gradient(to right, #0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign: "center",
    fontSize: '48px !important',
    lineHeight: "65.37px !important",
    "@media (max-width: 460px)": {
      fontSize: "32px !important",
    },
  },
  "& .buttonJarvysLandingBox": {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    "& .signupLandingBtn": {
      color: "#000000",
      border: "1px solid #000000",
      cursor: "pointer"
    },
    "& .loginLandingBtn": {
      color: "white",
      backgroundColor: "#000000",
      cursor: "pointer"
    },
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px",
      "@media (max-width: 600px)": {
        width: "150px",
      },
      "@media (max-width: 460px)": {
        width: "100px",
      },
    },
  }
});

const TalkJarvysBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 'clamp(10px,5vw, 20px)'
})

// Customizable Area End