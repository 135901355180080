export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const group = require("../assets/group.jpeg").default;
export const rightActive = require("../assets/rightActive.png").default;
export const leftInactive = require("../assets/leftInactive.png").default;
export const banner = require("../assets/banner.png").default;
export const cardPlaceholder = require("../assets/card.png").default;
export const sample = require("../assets/sample.mp4").default;
export const rightArrow = require("../assets/rightArrow.png").default;
export const bellIcon = require("../assets/bellIcon.png").default;
export const mailIcon = require("../assets/mailIcon.png").default;
export const avatar = require("../assets/avatar.png").default;
export const play = require("../assets/play.png").default;
export const calender = require("../assets/calender.svg").default;
export const cross = require("../assets/cross.svg").default;
