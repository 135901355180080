import React from "react";
// Customizable Area Start

 // Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End

import {Box,Typography, Dialog, styled } from "@mui/material";

import { rightArrow, cardPlaceholder ,mailIcon,bellIcon,avatar ,play, calender, cross} from "./assets";

import 'react-multi-carousel/lib/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import CarouselContainer from "./Component/CarouselContainer";

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, Arial, sans-serif',
  },
});

// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController";

export default class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    },
    desktop: {
      breakpoint: { max: 2999, min: 1024 },
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 30
    },
    laptop:{
      breakpoint: { max: 1023, min: 820 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 819, min: 560 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 559, min: 0 },
      items: 1,
      slidesToSlide: 1,
    }
  };

  renderVideoPlayer = () => {
    return (
      <StyledDialog open={this.state.showVideoPlayer} style={webStyle.videoModal} data-test-id="video-modal" onClose={() => {this.setState({showVideoPlayer: false, videoModalData: {created_at: "", video_url: "", title: "", description: null}})}}>
        <Box style={{padding:'24px'}}>
          <Box display="flex" style={{justifyContent:"space-between"}}>
            <Typography className="modal-heading" style={{fontSize:'20px', fontWeight:600, fontFamily:'Open sans', color: '#4D4D4D'}}>{this.state.videoModalData.title}</Typography>
            <img src={cross} onClick={() => {this.setState({showVideoPlayer: false})}} data-test-id="video-modal-close-btn"/>
          </Box>
          <Typography className="" dangerouslySetInnerHTML={{ __html: this.state.videoModalData.description || "" }}></Typography>
          <Box sx={{marginTop:'12px'}}>
            <video src={this.state.videoModalData.video_url} style={{width:'100%', height:'auto', borderRadius:'12px'}} controls/>
          </Box>
        </Box>
      </StyledDialog>
    )
  }

  renderArticle = () => {
    return (
        <Box data-test-id="opend-article">
          <Box style={{width:'100%', height:'225px'}}><img src={this.state.openArticle[0].image_url || cardPlaceholder} style={{objectFit:'cover', width:'100%', height:'100%', borderRadius:'16px'}}/></Box>
          <ArticleContentBox>
            <Styled40Text>{this.state.openArticle[0].title}</Styled40Text>
            <StyledDateTypo style={{marginTop:'8px'}}>{new Date(this.state.tutorialData.attributes.created_at).toISOString().split('T')[0]}</StyledDateTypo>
            <Typography dangerouslySetInnerHTML={{ __html: this.state.openArticle[0].content }}></Typography>
          </ArticleContentBox>
        </Box>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="row">
        <style>
          {`
            .videoWrapper{
              gap: 12px;
              flex-wrap: wrap;
            }
            .video{
              width: 549px;
            }
            .modal-heading{
              font-size: 20px;
              font-weight: 600;
              color: #4D4D4D;
              font-family: Open sans;
              text-transform: capitalize;
            }
            .f24w{
              color: #fff;
              font-size:24px;
              font-family: Open sans;
              font-weight: 700;
            }
            .f16w{
              color: #fff;
              font-size: 16px;
              font-weight: 600;
              font-family: Open sans;
            }
            .textHolder{position:absolute; top:60%; left:4%; width:550px; text-align:start}
            .clamped-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #4D4D4D;
              margin-bottom: 8px;
              font-family: Open sans;
            }

            @media( max-width: 1024){

              .video{
                width: 500px;
              }
            }

            @media (min-width: 1024px) and (max-width: 3000px){
              .video{
                width: 500px;
              }
              .f24w{
                color: #fff;
                font-size:24px;
                font-family: Open Sans;
                font-weight: 700;
              }
              .f16w{
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                font-family: Open Sans;
              }
            }
            
            @media (min-width: 820px) and (max-width: 1023px) {
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 480px;
              }
              .f24w{
                color: #fff;
                font-size:24px;
                font-family: Open Sans;
                font-weight: 700;
              }
              .f16w{
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                font-family: Open Sans;
              }
            }
            
            @media (min-width: 560px) and (max-width: 819px) {
              .react-multi-carousel-item {
                width: 327px;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 400px;
              }
              .f24w:{
                font-size:20px;
              }
              .f16w: {
                font-size: 16px;
              }
              .textHolder{width:420px; top:40%}
            }
            @media (min-width: 420px) and (max-width: 559px) {
              .modal-heading:{
                font-size: 16px;
              }
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 325px;
              }
              .f24w{
                fontSize: 16px;
              }
              .f16w{
                font-size: 14px;
              }
              .textHolder{width:420px; top:40%}
            }
            @media (min-width: 0px) and (max-width: 419px) {
              .videoWrapper{
                justify-content: center;
              }
              .video{
                width: 325px;
              }
              .f24w{
                font-size: 16px
              }
              .f16w{
                font-size: 14px;
              }
              .textHolder{width:350px; top:30%}
            }
          `}
        </style>
        <Box style={webStyle.tutorialContainer}>
          {this.state.openArticle.length === 0 ? <Box>
            <Box style={webStyle.bannerContainer as React.CSSProperties} data-test-id="open-video" onClick={() => {this.setState({showVideoPlayer: true, videoModalData: {
              created_at: "",
              title: "DatAI Keynote: Introduction",
              description: "The keynote depicts the basic principles of the DatAI company that align with the basics of the qualitative marketing services while using AI tools.",
              video_url: this.state.tutorialData.attributes.primary_video
            }})}}>
              <video src={this.state.tutorialData.attributes.primary_video} style={webStyle.bannerImg} data-test-id="video-banner" onLoadedMetadata={(e) => {const videoElement = e.target as HTMLVideoElement; this.handleLoadedMetadata(-1, videoElement.duration);}} />
              <img src={play} style={{ position: "absolute", top: "45%", left: "45%", }} />
              <Box className="textHolder">
                <Typography className="f24w">DatAI Keynote: Introduction</Typography>
                <Typography className="f16w">The keynote depicts the basic principles of the DatAI company that align with the basics of the qualitative marketing services while using AI tools.</Typography>
                <Box style={{ borderRadius:'8px', backgroundColor:'#00000080', padding:'4px', display:'inline-block', textAlign: 'center'}}>
                  <Typography style={{color:'#fff', fontSize:"14px", fontWeight:700, fontFamily:'Open sans'}}>{this.convertSecondsToHMS(this.state.videoDurations[-1])}</Typography>
                </Box>
              </Box>
            </Box>


            <Box>
              <StyledHeadingTypo style={{marginBottom:'16px'}}>You may also be interested in</StyledHeadingTypo>
              <StyledCarouselWrapper>
              <CarouselContainer>
                {this.state.tutorialData.attributes.related_articles.map((article, index) => (
                <Box key={index} height="383px" width="327px"  onClick={() => {this.showArticle(article)}} data-test-id={`article-${index}`}>
                  <img src={article.image_url || cardPlaceholder} style={{borderRadius:"8px", height:'226px', width:'327px'}}/>
                  <Typography style={webStyle.cardHeading as React.CSSProperties} sx={{whiteSpace:'nowrap', overflow:'clip', textOverflow: 'ellipsis'}}>
                    {article.title}
                  </Typography>
                  <Box>
                    <Typography dangerouslySetInnerHTML={{ __html: article.content }} sx={webStyle.articleSubContent}/>
                    <Box display="flex" gap='4px'>
                      <img src={calender} width="16px" />
                      <StyledTimeTypo>{this.formatDate(this.state.tutorialData.attributes.created_at)}</StyledTimeTypo>
                      <StyledTimeTypo sx={{marginLeft:'auto'}}>10 minutes to read</StyledTimeTypo>
                    </Box>
                  </Box>
                </Box>))}
              </CarouselContainer>

              </StyledCarouselWrapper>
            </Box>

            <Box style={{marginTop:'48px'}}>
              <StyledHeadingTypo>Additional Support Video</StyledHeadingTypo>
              <Box width="100%" position="relative" display="flex" className="videoWrapper">
                {this.state.tutorialData.attributes.support_videos.map((item, index) =>{ 
                  return (
                  <Box key={item.created_at} onClick={() => {this.setState({showVideoPlayer: true, videoModalData: item})}} data-test-id={`videos-${index}`}>
                    <Box style={{ position: "relative", marginTop:'24px' }}>
                      <video src={item.video_url} height="auto" className="video" style={{borderRadius:"8px"}} onLoadedMetadata={(e) => {const videoElement = e.target as HTMLVideoElement; this.handleLoadedMetadata(index, videoElement.duration);}}/>
                      <Box style={{ position: "absolute", bottom: "4%", right: "4%", borderRadius:'8px', backgroundColor:'#00000080', padding:'4px' }}>
                        <Typography style={{color:'#fff', fontSize:"14px", fontWeight:700, fontFamily:'Open sans'}}>{this.convertSecondsToHMS(this.state.videoDurations[index])}</Typography>
                      </Box>
                    </Box>
                    <StyledVideoTitleTypo>{item.title}</StyledVideoTitleTypo>
                    <StyledDateTypo>{this.timeAgo(this.state.tutorialData.attributes.created_at)}</StyledDateTypo>
                  </Box>
                )})}
              </Box>
            </Box>

            <Box style={{marginTop:'200px', marginBottom:'50px'}}> 
              <Typography style={{color:'#7D7D7D', fontSize:'14px', fontWeight: 600}}>DatAI © 2020-2024</Typography>
              <Box display="flex" style={{gap:'16px'}}>
                <Typography style={{color:'#8F8F8F', fontSize:'12px', fontWeight: 400}}>Privacy policy</Typography>   
                <Typography style={{color:'#8F8F8F', fontSize:'12px', fontWeight: 400}}>Terms & Conditions</Typography>
              </Box>
            </Box>
          </Box> : this.renderArticle()}
          {this.state.showVideoPlayer && this.renderVideoPlayer()}
        </Box>
      </Box>
      </ThemeProvider>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start

const ArticleContentBox = styled(Box)({
  maxWidth:'795px',
  width:'100%',
  margin:'auto',
  marginTop:'32px',
})

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm":{
    width:'100%',
    borderRadius: '16px',
  },
  "& .MuiDialog-paper": {
    margin: '0'
  },
  "& .MuiDialog-paperScrollPaper":{
  }
})


const StyledCarouselWrapper = styled(Box)({
  display:'flex',
  alignItems:'center',
  justifyContent:'start',
  flexGrow:1,
  '@media (max-width: 1220px)':{
    justifyContent:'center',
  }
})

const StyledHeadingTypo = styled(Typography)({
  marginTop:'48px',
  fontSize:'32px',
  color: "#4D4D4D",
  fontFamily: 'Open sans',
  fontWeight: 600,
  '@media (max-width: 950px)':{
    marginTop:'24px',
    fontSize:'24px',
    textAlign:'center'
  },
  '@media(max-width: 450px)':{
    fontSize:"18px",
  }
})

const StyledVideoTitleTypo = styled(Typography)({
  fontSize:'20px',
  color:'#232323',
  fontWeight: 600,
  fontFamily:'Open sans',
  lineHeight: '27px',
  textTransform:'capitalize',
  padding:"10px 0 0 0",
  '@media(max-width: 950px)':{
    fontSize:'18px',
  },
  '@media(max-width: 650px)':{
    fontSize:'16px',
  }
})

const StyledDateTypo = styled(Typography)({
  fontSize:'16px',
  lineHeight:'21.79px',
  color:'#B0B0B0',
  fontWeight: 400,
  fontFamily:'Open sans',
  textTransform:'capitalize',
  '@media(max-width: 950px)':{
    fontSize:'14px',
  },
  '@media(max-width: 650px)':{
    fontSize:'12px',
  }
})

const StyledTimeTypo = styled(Typography)({
  fontSize:'14px',
  color:'#B0B0B0',
  fontWeight: 400,
  fontFamily:'Open sans',
  textTransform:'capitalize',
  '@media(max-width: 950px)':{
    fontSize:'12px',
  },
  '@media(max-width: 650px)':{
    fontSize:'10px',
  }
})


const Styled40Text = styled(Typography)({
  fontSize:'24px',
  fontFamily:'Open Sans',
  overflow:'hidden',
  whiteSpace:"nowrap",
  fontWeight:600,
  color:'#4D4D4D',
  textTransform:'capitalize',
  '@media (max-height: 430px)': {
    fontSize:'18px !important',
  },
  '@media (max-height: 350px)': {
    fontSize:'16px !important',
  },
})

const webStyle = {

  articleSubContent:{
    height:'88px',
    overflow:'hidden',
  },

  videoModal:{
    padding:'16px',
    borderRadius:'16px',
    ".MuiDialog-paperWidthSm": {
      width:'80%',
      borderRadius: '16px',
    }
  },
  videoBox:{
    height:'400px',
    width: "327px",
    padding:'12px'
  },
  videoElement:{
    width: "327px",
    height: "149px",
    borderRadius:'8px',
  },
  caraousel:{
    display : "flex",
    flexDirection:"row",
    alignItems:"center",
    height:'300px',
    justifyContent:"center",
    gap: "40px",
  },
  bannerImg: {
    width: '100%',
    maxHeight: "464px",
    borderRadius:"12px",
    objectFit:'cover' as 'cover'
    
  },
  tutorialContainer:{
    width: "calc(100%-48px)",
    // height: "1280px",
    Top: "164px",
    Left: "324px",
    gap: "54px",
    alignItems:"center",
    justifyContent:"center",
    margin:"0 auto",
    padding:'24px',
    fontFamily:'Open sans'
  },
  cardHeading:{
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "27.24px",
    textAlign: "left",
    margin:0,
    padding:"10px 0",
    color:'#4D4D4D',
    textTransform: "capitalize",
    fontFamily:'Open sans'
  },
  cardSubHeading:{
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.34px",
    textAlign: "left",
    margin: 0,
    padding:0,
    fontFamily: 'Open sans',
    color:'#4D4D4D',
  },
  cardDate:{
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "14.98px",
    textAlign: "left",
    margin:"10px 0",
    padding:0,
    fontFamily: 'Open sans'
  },
  caraouselContainer:{
    height:"301px",
    marginTop:"54px"
  },
  bannerContainer:{
    textAlign:"center",
    position: 'relative',
    objectFit:'cover'

  },
  pathText: {
    alignItems: "center",
    height: "26px",
    top: "106px",
    left: "293px",
    gap: "4px",
    opacity: "0px",
    margin : "20px 10px",
    fontFamily: 'Open sans'
  },
  header:{
    height: "26px",
    top: "106px",
    left: "293px",
    gap: "16px",
    opacity: "0px",
    margin : "20px 10px",
    alignItems: "center" , 
    justifyContent:"flex-end" 
  },
  f14:{
    fontSize:'14px',
    fontWeight:400,
    color:'#B0B0B0',
    lineHeight:'21px',
    fontFamily: 'Open sans'
  },
  f16:{
    fontSize:'16px',
    fontWeight:400,
    color:'#B0B0B0',
    lineHeight:'21px',
    fontFamily: 'Open sans'
  },
  f20:{
    fontSize:'20px',
    fontWeight:600,
    color:'#232323',
    lineHeight:'28px',
    fontFamily: 'Open sans'
  },
  f32:{
    fontSize:'32px',
    color:'#4D4D4D',
    fontWeight:700,
    lineHeight:'43.5px',
    fontFamily: 'Open sans'
  }
}
// Customizable Area End
