Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

exports.CommunityEndPoint = 'bx_block_communityforum/community_forums';
exports.updateCommunityEndPoint = "bx_block_communityforum/change_status";
exports.getCommunityVideoEndPoint = "bx_block_communityforum/embed_videos"
exports.getCommunityMethod = 'GET';
exports.deleteCommunityMethod = 'DELETE';
exports.createCommunityMethod = 'POST';
exports.updateCommunityMethod = 'PUT';
exports.getConnectedAccDetailEndPoint = "bx_block_communityforum/get_accounts";

exports.dashboardHeadingText = "Welcome to DatAI";
exports.exploreHeadingText = "Explore with us";
exports.TailormadeHeading = "Tailormade";
exports.TailormadeContent = "Personalized solutions tailored to your business needs. Each tool and service is customized to help you achieve your goals efficiently.";
exports.ContentCreationHeading = "Content Creation";
exports.ContentCreationContent = "Create high-quality, engaging content easily. Craft stories and visuals effortlessly.";
exports.RealTimeAnalyticsHeading = "Real Time Analytics";
exports.RealTimeAnalyticsContent = "Compile data from all social media streams instantly. Real-time insights to access and monitor your entire online presence at a glance.";
exports.UserEngagementToolsHeading = "User Engagement Tools";
exports.UserEngagementToolsContent = "Compile data from all social media streams instantly. Real-time insights to access and monitor your entire online presence at a glance.";

exports.helpHeadingText = "Do you need help?"
exports.QuickStartTutorialHeader = "QuickStart Tutorial";
exports.QuickStartTutorialContent = "Get started and learn how to navigate features and tools to use the app to its full potential.";
exports.DatAICommunityHeader = "DatAI Community";
exports.DatAICommunityContent = "Discuss, share advice, showcase your JARVYS content, and explore user cases . Enhance your experience and learn from real-world examples.";
exports.NeedAdditionalhelp = "Need additional help?";
exports.NeedAdditionalhelpContent = "Need further advice? We are here to help you. Complete our form and the Datai Team will be in touch with you.";
exports.FAQsHeader = "FAQs";
exports.FAQsContent = "Get answers to frequently asked questions about our products and services. Find detailed information and helpful tips .";

exports.latestUpdatesHeading = "Our latest updates";
exports.latestUpdatesContent = "The keynote depicts the basic principles of the DatAI company that align with the basics of the qualitative marketing services while using AI tools.";
// Customizable Area End