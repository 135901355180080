// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData, removeStorageData} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area Start

interface MessageArray {
  id: number;
  message: string;
  isUser: boolean;
}

interface RespData {
  warning:string;
  id:string;
  object:string;
  created:number;
  model:string;
  choices:[{"text":" Hello there! How can I help you?","index":0,"logprobs":null,"finish_reason":"stop"}];
  usage:{"prompt_tokens":7,"completion_tokens":9,"total_tokens":16}
}

interface IMessage {
  id: number;
  message: string;
  isUser: boolean;
}

export interface ValidResponseType {
  errors: Array<string>;
  error: string;
}
export interface InvalidResponseType {
  errors: Array<InvalidType>;
}

export interface InvalidResponse {
  errors: Array<string>;
}

export interface InvalidType {
  token : string;
}

export interface GenerateContentType {
  content: string;
  post_id: string;
  thread_id: string;
  success: boolean;
}

export interface CampaignDataType {
  data: CampaignData;
}

export interface ChatContentType {
  question : string
  jarvysAnswer : string
} 

export interface CampaignData {
  id: string,
  type: string,
  attributes: {
    id: string,
    name: string,
    created_at: string,
    updated_at: string
  },
  relationships: {
    account: {
      data: {
        id: string,
        type: string
      }
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  messages:Array<MessageArray>;
  input:string;
  isLoading:boolean;
  data:string;
  isModalOpen: boolean
  isChatPage: boolean;
  contentText: string;
  contentData: string;
  postId: string;
  threadId: string;
  nextQuestion: string;
  isContentText: string;
  isNextQuestion: string;
  campaignName: string;
  campaignId: string;
  editCampaign: boolean;
  isCampaignName: string;
  chatData: Array<ChatContentType>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatgptController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  openApiID: string = "";
  authToken: string = "";
  userId: string = "";
  postId: string = "";
  campaignName: string = "";
  campaignId: string = "";
  postGenerateContentApiCallId: string = "";
  postCampaignCreateApiCallId: string = "";
  patchCampaignEditApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.generateContent)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start

      messages:[],
      input:'',
      isLoading:false,
      isModalOpen: false,
      isChatPage: false,
      contentText: "",
      contentData: "",
      postId : "",
      threadId: "",
      nextQuestion: "",
      isContentText: "",
      isNextQuestion: "",
      editCampaign: true,
      isCampaignName: "",
      campaignName: "",
      campaignId: "",
      chatData: [],
      data:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if (apiRequestCallId === this.openApiID) {
         this.getAllSuccessFunctionCallBack(responseJson);
      }
       
      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.generateContent));
      this.setState({contentText : data , isContentText: data },()=>this.handleCampaignCreate())
     }
    // Customizable Area End

  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    this.postId = await getStorageData('postId');
    if(this.postId){
      this.setState({postId : this.postId})
    }
    this.campaignName = await getStorageData('campaignName');
    this.campaignId = await getStorageData('campaignId')
    if(this.campaignName && this.campaignId){
      this.setState({campaignName: this.campaignName, isCampaignName: this.campaignName ,campaignId: this.campaignId })
    }
  }

  async componentWillUnmount() {
    await removeStorageData("campaignName");
    await removeStorageData("campaignId");
    await removeStorageData("postId");
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: GenerateContentType & CampaignDataType) => {
    if (apiRequestCallId === this.postGenerateContentApiCallId) {
      this.postGenerateContentSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.postCampaignCreateApiCallId) {
      this.postCampaignCreateSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.patchCampaignEditApiCallId) {
      this.patchCampaignEditSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponse) => {
    if (apiRequestCallId === this.postGenerateContentApiCallId) {
      this.postGenerateContentFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.postCampaignCreateApiCallId) {
      this.postCampaignCreateFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.patchCampaignEditApiCallId) {
      this.patchCampaignEditFailurCallBack(responseJson)
    }
  };

  generateContentApiCall = async (valueData: {
      genContentType?: string;
      genMethod?: string;
      genEndPoint?: string;
      genBody?: {};
      genApiType?: string;
      genType?: string;
      }) => {
      let { genContentType, genMethod, genEndPoint, genBody } = valueData;
        const token = this.authToken;
       let header = {
          "Content-Type": genContentType,
          token,
         };
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
       );
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
          genEndPoint
        );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
          genMethod
       );
       genBody &&
          requestMessage.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
            genBody
         );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       runEngine.sendMessage(requestMessage.id, requestMessage);
       return requestMessage.messageId;
     };

  getAllSuccessFunctionCallBack = ( responseJson: RespData) => {
    
  this.setState({ data: responseJson.choices[0].text });

  const aiMessage = {
    id: this.state.messages.length + 2,
    message: this.state.data,
    isUser: false,
  };
  this.setState((prevState) => ({
    messages: [...prevState.messages, aiMessage],
    isLoading: false,
  }));
}

newState=(e: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({ input: e?.target?.value })
}

tempState=(text:string)=>{
  this.setState({input:text})}

sendMessage = async () => {
  if (this.state.input?.trim().length === 0) return;
  const message: IMessage = {
    id: this.state.messages.length + 1,
    message: this.state.input,
    isUser: true,
  };

  this.setState((prevState) => ({
    messages: [...prevState.messages, message],
    input: "",
    isLoading: true,
  }));

  try {
   this.openApiCall(this.state.input)
  
  } catch (error) {
    this.setState({ isLoading: false });
  }
};

secondScreen=()=>{
  this.props.navigation.navigate('Chatgpt')
}
 sendMessages = () => {
  if (this.state.input?.trim().length === 0) return;

  let message = {
    id: this.state.messages.length + 1,
    message: this.state.input,
    isUser: true,
  };
  this.setState({
        messages: [...this.state.messages,message],
        input: "",
        isLoading:true
      });
      
  try {
  this.openApiCall(this.state.input)
  } catch (error) {
   this.setState({
    isLoading:false
  });
  }
};


  openApiCall = (message: string) => {
 const header= {
      Authorization: `Bearer ${"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"}`,
      "Content-Type": "application/json",
    }
    const prompt = `User: ${message}\nAI:`;

    const data = {
    prompt,
    model: "text-davinci-003",
    temperature: 0.7,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "https://api.openai.com/v1/completions"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"    
      );
      
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseModal = () =>{
    this.setState({ isModalOpen: false})
  }

  goToGenerateContentPage = () =>{
    const generateContent = new Message(getName(MessageEnum.NavigationMessage));
    generateContent.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "GenerateContent"
    );
    generateContent.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(generateContent);
  }

  goToMyCampaignPage = () =>{
    const generateContent = new Message(getName(MessageEnum.NavigationMessage));
    generateContent.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Campaigns"
    );
    generateContent.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(generateContent);
  }

  handleChatPageBack = () =>{
    this.goToGenerateContentPage()
  }

  handlePostContent = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({nextQuestion : event.target.value });
  }

  handleCampaignName = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({isCampaignName : event.target.value });
  }

  editCampaignOpen = () =>{
    this.setState({editCampaign : false})
  }

  editCampaignClose = () =>{
    this.setState({editCampaign : true},()=>this.handleCampaignEdit())
  }

  handlePostGenerateContent = async () => {
    const { contentText, nextQuestion, campaignId } = this.state;
    this.setState({ isModalOpen: true, isNextQuestion: nextQuestion })
    let apiBody = {
      user_input: contentText
    };
    let postIdbody = {
      user_input: nextQuestion,
      post_id: this.state.postId
    };

    this.postGenerateContentApiCallId = await this.generateContentApiCall({
      genContentType: configJSON.validationApiContentType,
      genMethod: configJSON.exampleAPiMethod,
      genEndPoint: `${configJSON.postGenerateContentEndPoint}${campaignId}`,
      genBody: nextQuestion ? JSON.stringify(postIdbody) : JSON.stringify(apiBody),
    });
  };

  postGenerateContentSuccessCallBack = async (responseJson: GenerateContentType) => {
    const { contentText, nextQuestion } = this.state;
    if (responseJson.post_id) {
      const postId = responseJson.post_id
      await setStorageData("postId", postId);
    }
    if (responseJson.success === true ){
        this.goToMyCampaignPage();
    }
    let chatContent = {
      question: nextQuestion ? nextQuestion : contentText,
      jarvysAnswer: responseJson.content
    }
    this.setState((prevState) => ({
      chatData: [
        ...prevState.chatData,
        {
          question: chatContent.question,
          jarvysAnswer: chatContent.jarvysAnswer,
        }
      ],
      postId: responseJson.post_id,
      threadId: responseJson.thread_id,
      isModalOpen: false,
      nextQuestion: "",
    }));
  }

  postGenerateContentFailurCallBack = (responseJson: InvalidResponseType & InvalidResponse) => {
    if (responseJson.errors) {
      toast.error(responseJson.errors[0].token)
    }
    if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
  }

  handleCampaignCreate = async () => {
    this.postCampaignCreateApiCallId = await this.generateContentApiCall({
      genContentType: configJSON.validationApiContentType,
      genMethod: configJSON.exampleAPiMethod,
      genEndPoint: `${configJSON.postCampaignCreateEndPoint}${this.userId}/campaigns`,
    });
  };

  postCampaignCreateSuccessCallBack = (responseJson: CampaignDataType) => {
    const campaignName = responseJson.data.attributes.name;
    const campaignId = responseJson.data.attributes.id;
    if(responseJson.data){
       setStorageData("campaignName", campaignName);
       setStorageData("campaignId", campaignId);
    }
    this.setState({campaignName : campaignName, campaignId: campaignId , isCampaignName: campaignName },()=>{
      this.handlePostGenerateContent();
      toast.success("Campaign Created Successfully!")
    })
  }

  postCampaignCreateFailurCallBack = (responseJson: InvalidResponseType & InvalidResponse) => {
    if (responseJson.errors) {
      toast.error(responseJson.errors[0].token)
    }
    if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
  }

  handleCampaignEdit = async () => {
    const { campaignId , isCampaignName} = this.state;
    let campaignNameBody = {
      campaign: {
        name: isCampaignName
    }
    };
    this.patchCampaignEditApiCallId = await this.generateContentApiCall({
      genContentType: configJSON.validationApiContentType,
      genMethod: configJSON.patchApiMethod,
      genEndPoint: `${configJSON.postCampaignCreateEndPoint}${this.userId}/campaigns/${campaignId}`,
      genBody: JSON.stringify(campaignNameBody),
    });
  };

  patchCampaignEditSuccessCallBack = (responseJson: CampaignDataType) => {
    const campaignName = responseJson.data.attributes.name;
    const campaignId = responseJson.data.attributes.id;
    if(responseJson.data){
      setStorageData("campaignName", campaignName);
    }
    this.setState({campaignName : campaignName, campaignId: campaignId ,isCampaignName: campaignName },()=>{
      toast.success("Campaign Name Changed Successfully!")
    })
  }

  patchCampaignEditFailurCallBack = (responseJson: InvalidResponseType & InvalidResponse) => {
    if (responseJson.errors[0]) {
      toast.error(responseJson.errors[0].token)
    }
    if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
  }

  startGuideJourney = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "GuidedWalkthrough");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}

// Customizable Area End
