import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Menu,
  IconButton,
  Modal,
} from '@mui/material'
import { renameIcon, editIcon } from "../assets";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { StyledTextField } from '../../../../components/src/StyledComponents'
import { styled } from '@mui/material/styles';


interface Props {
  onRename: (newName: string) => void
  onDelete: () => void
  onEdit?: () => void
  from: string
  contentName:string,
}

const MenuButton: React.FC<Props> = ({from, onRename, onEdit, onDelete, contentName}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [name, setName] = useState<string>("");

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
  };

  const handleOpenDeleteModal = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      event.stopPropagation();
      setOpenDeleteModal(true)
      handleClose(event)
  }
  
  const handleCloseDeleteModal = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setOpenDeleteModal(false)
}

  const handleOpenRenameModal = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      event.stopPropagation();
      setOpenRenameModal(true)
      handleClose(event)
  }

  const handleCloseRenameModal = (event: React.MouseEvent<Element>) => {
      event.stopPropagation();
      setOpenRenameModal(false);
      setName("");
      setNameError(false)
      setNameErrorMessage("")
  }

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      setName(event.target.value)
  }
  
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    event.stopPropagation();
    onDelete();
    handleCloseDeleteModal(event);
  }

  const handleRename = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
    event.stopPropagation();
    if(name.length > 0){   
        onRename(name)
        handleCloseRenameModal(event)
    }else{
        setNameError(true)
        setNameErrorMessage("Name required")
    }  
  }

  return (
    <>
      <Box>
        <IconButton
            data-testid='openMenuButton'
            aria-controls={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
            sx={{ padding: "0px" }}
        >
            <MoreVertIcon />
        </IconButton>
        <StyledMenu
            id="simple-menu"
            data-testid='cardMenu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ mt: -1, ml: 1 }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={handleOpenDeleteModal} data-testid='deleteOption' 
                sx={{display:"flex", gap:"10px", alignItems:"center", padding:'6px , 10px', fontWeight:400, fontFamily:'Open Sans'}}>
                    <DeleteOutlineOutlinedIcon sx={{color:"#4D4D4D"}}/>Delete
                </MenuItem>
            <div style={{display:"flex", height:"1px", background:"#DDDDDD"}}/>
            <MenuItem onClick={handleOpenRenameModal} data-testid='renameOption' sx={{display:"flex", gap:"10px", alignItems:"center", padding:'6px , 10px', fontWeight:400, fontFamily:'Open Sans'}}> <img src={renameIcon}/>Rename</MenuItem>
            {from != "campaign" && <div style={{display:"flex", height:"1px", background:"#DDDDDD"}}/> }
            {from != "campaign" &&  <MenuItem data-testid='editOption' sx={{display:"flex", gap:"10px", alignItems:"center", padding:'6px , 10px', fontWeight:400, fontFamily:'Open Sans'}} onClick={()=>{ if(!!onEdit){onEdit()}}}> <img src={editIcon}/>Edit</MenuItem>}
        </StyledMenu>
        <Modal
                data-testid='deleteModal'
                open={openDeleteModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "transparent" }}
                onClose={handleCloseDeleteModal}
                onClick={(e) => e.stopPropagation()}
                BackdropProps={{
                    sx: { background: '#00000080' }
                }}
            >
                <StyledModalContainer>
                    <StyledCloseIcon onClick={handleCloseDeleteModal}/>
                    <Box sx={{ display: 'flex', gap: "12px", flexDirection: "column", alignItems: "center" }} >
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "20px", fontWeight: 600, color: "#4D4D4D", textAlign: "center" }}>{from === "campaign" ? "Delete Campaign" : "Delete Post"}</Typography>
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "16", fontWeight: 400, color: "#4D4D4D", textAlign: "center" }}>
                            Are you sure do you want to delete <span style={{fontFamily:"Open Sans", fontWeight:600, fontSize:"16px", color:"#4D4D4D"}}>{contentName}</span>?
                        </Typography>
                    </Box>
                    <StyledModalButtonContainer >
                        <Button
                            // variant="outlined"
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#000000",
                                maxWidth: "220px",
                                "&:hover": {
                                    background: '#eaeaeacf;',
                                    borderColor: "1px solid #000000",
                                }
                            }}
                            onClick={handleCloseDeleteModal}
                        >
                            No, cancel
                        </Button>
                        <Button
                            fullWidth
                            data-testid='deleteButton'
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#ffffff",
                                background: "#000000",
                                padding: '10px',
                                maxWidth: "220px",
                                "&:hover": {
                                    background: "#000000",
                                }

                            }}
                            onClick={handleDelete}
                        >
                            Yes, delete

                        </Button>
                    </StyledModalButtonContainer>
                </StyledModalContainer>
            </Modal>
            <Modal
                data-testid={'renameModal'}
                open={openRenameModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "transparent" }}
                onClose={handleCloseRenameModal}
                onClick={(e) => e.stopPropagation()}
                BackdropProps={{
                    sx: { background: '#00000080' }
                }}
            >
                <StyledModalContainer>
                    <StyledCloseIcon onClick={handleCloseRenameModal} />
                    <Box sx={{ display: 'flex', gap: "12px", flexDirection: "column", alignItems: "center" }} >
                        <Typography sx={{ fontFamily: "Open Sans", fontSize: "20px", fontWeight: 600, color: "#4D4D4D", textAlign: "center" }}>Rename</Typography>
                        <StyledTextField
                            data-testid='nameInput'
                            fullWidth
                            height="35px"
                            onChange={handleChangeName}
                            value={name}
                            error={nameError}
                            helperText={nameErrorMessage}
                        />
                    </Box>
                    <StyledModalButtonContainer>
                        <Button
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "9px",
                                color: "#000000",
                                maxWidth: "220px",
                                "&:hover": {
                                    borderColor: "#000000"
                                }
                            }}
                            onClick={handleCloseRenameModal}
                        >
                            No, cancel
                        </Button>
                        <Button
                            data-testid='saveButton'
                            fullWidth
                            sx={{
                                textTransform: "none",
                                fontFamily: "Open Sans",
                                fontSize: '16px',
                                fontWeight: 600,
                                border: "1px solid #000000",
                                borderRadius: "8px",
                                color: "#ffffff",
                                background: "#000000",
                                padding: '9px',
                                maxWidth: "220px",
                                "&:hover": {
                                    background: "#000000",
                                }

                            }}
                            onClick={handleRename}
                        >
                            Save

                        </Button>
                    </StyledModalButtonContainer>
                </StyledModalContainer>
            </Modal>
    </Box>
</>
  );
}

export default MenuButton;

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: "10px",
      border: "1px solid #DDDDDD",
      height: "fit-content"
  },
  '& .MuiList-root': {
      paddingTop: "0px",
      paddingBottom: "0px"
  },
  '& .MuiMenuItem-root': {
      '&:hover': {
          backgroundColor: '#ccc',
      },
  },
})

const StyledCloseIcon = styled(CloseOutlinedIcon)({
  position: "absolute",
  color: "##0F172A",
  fontSize: "26px",
  cursor: "pointer",
  zIndex: 5,
  top: 15,
  right: 15,
})

const StyledModalContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  background: "#ffffff",
  width: '529px',
  height: "fit-content",
  gap: "40px",
  borderRadius: "24px",
  position: "relative",
  padding: "40px",
  margin: "12px"
})

const StyledModalButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    gap: "12px",
    '@media (max-width : 371px)':{
        flexDirection:"column",
        alignItems:"center"
    }
  })