Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PhotoLibrary";
exports.labelBodyText = "PhotoLibrary Body";

exports.errorMessage = "Something went wrong!";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";

exports.photoLibraryApiEndpoint = "photo_library/photo_library";
exports.myLibrary = "My library"; 
exports.addContent = "Add content";
exports.shortBy = "Sort by";
exports.nameAZ = "Name: (A - Z)";
exports.nameZA = "Name: (Z - A)";
exports.typeImage = "Type: (Image)";
exports.typeVideo = "Type: (Video)";
exports.dateLH = "Date Created: Low > High";
exports.dateHL = "Date Created: High > Low";
exports.imagesText = "Images";
exports.videosText = "Videos";
exports.deleteText = "Delete";
exports.renameText = "Rename";
exports.deleteImageText = "Delete Image";
exports.sureDeleteText = "Are you sure do you want to delete ";
exports.cancelDeleteText = "No, cancel";
exports.yesDeleteText = "Yes, delete";
exports.saveText = "Save";
exports.chooseFiles = "Choose files";
exports.choiceText = "Select and upload the files of your choice";
exports.dragDropText = "Choose a file or drag & drop it here.";
exports.formatsText = "Supported formats: JPG, PNG, up to 10MB.";
exports.browseBtn = "Browse File";
exports.photoLibraryEndPoint = "bx_block_campaigns/accounts/";
exports.recordNotFound = "Record not found";
exports.fileValidation = "File size exceeds the 10MB limit. Please upload JPG or PNG formats only."
exports.successUpload = "Content added to your library!";

exports.sortKey = {
  name_asc: "Name: (A - Z)",
  name_desc: "Name: (Z - A)",
  type_image: "Type: (Image)",
  type_video: "Type: (Video)",
  created_at_asc: "Date Created: Low > High",
  created_at_desc: "Date Created: High > Low",
}
// Customizable Area End

exports.textDelete = "Delete";
exports.textDeleteSelected = "Delete Selected";
exports.textDeleteGallery = "Delete Gallery";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textSelectImage = "Select Image";
exports.textAddNewImage = "Add New Image";
exports.textShowImages = "Show Images";
exports.noImageErrorMsg = "Please select an image";
exports.textShare = "Share";
exports.textAccountId = "Account ID";
exports.errorMsgAccountId = "Account ID cannot be empty";
