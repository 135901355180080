import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import {
    SelectChangeEvent
} from '@mui/material';
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ValidResponseType {
    data: PhotoLibrary;
    errors: Array<string>;
    error: string;
    message: string;
}
export interface InvalidResponseType {
    errors: Array<string>;
}

export interface SearchResponseType {
    data: Array<SearchDataType>;
    errors: Array<string>;
    error: string;
}
export interface SearchDataType {
    id: string,
    type: string,
    attributes: LibraryContent
}
export interface PhotoLibrary {
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        created_at: string,
        updated_at: string,
        contents: Array<LibraryContent>
    },
    relationships: {
        account: {
            data: {
                id: string,
                type: string
            }
        }
    }
}

export interface LibraryContent {
    id: number,
    url: string,
    filename: string
    content_type: string,
    byte_size: number,
    created_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    photoData: Array<LibraryContent>;
    videoData: Array<LibraryContent>;
    openImageMenuIndex: number | boolean;
    openVideoMenuIndex: number | boolean;
    selectedUrl : string;
    openDeleteModal: boolean;
    openRenameModal: boolean;
    openUploadModal: boolean;
    renameText: string;
    deleteFileName: string;
    selectedFile: File[] |null;
    showValidFile: boolean;
    sortContent: string;
    searchContent: string;
    isLoading: boolean;
    blobId: number;
    contentType: string;
    openSuccessModal: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PhotosLibraryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    authToken: string = "";
    userId: string = "";
    getPhotoLibraryApiCallId: string = "";
    postAddLibararyApiCallId: string = "";
    searchLibraryApiCallId: string = "";
    deleteLibraryApiCallId: string = "";
    renameLibraryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            photoData:[],
            videoData:[],
            openImageMenuIndex: false,
            openVideoMenuIndex: false,
            selectedUrl: "",
            openDeleteModal: false,
            openRenameModal: false,
            openUploadModal: false,
            renameText: "",
            deleteFileName: "",
            selectedFile: null, 
            showValidFile: false,
            sortContent: "",
            searchContent: "",
            isLoading: false,
            blobId: 0,
            contentType: "",
            openSuccessModal: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponseController(responseJson)) {
                this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
            }
            if (this.isInValidResponseController(responseJson)) {
                this.apiFailureCallBacksController(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.authToken = await getStorageData('authToken');
        this.userId = await getStorageData('userId');
        this.handleGetPhotosLibrary();
    }

    photoLibraryApiCall = async (valueData: {
        method?: string;
        contentType?: string;
        body?: {};
        endPoint?: string;
        type?: string;
        apiType?: string;
      }) => {
        const token = this.authToken;
        let { contentType, method, endPoint, body } = valueData;
        let header = {
           "Content-Type": contentType,
           token,
         };
        let requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
          );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
             body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };

    isValidResponseController = (responseJson: ValidResponseType) => {
        return responseJson && !responseJson.errors;
    };

    isInValidResponseController = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: ValidResponseType & SearchResponseType) => {
        if (apiRequestCallId === this.getPhotoLibraryApiCallId) {
            this.getPhotosLibrarySuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.postAddLibararyApiCallId) {
            this.addLibrarySuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.searchLibraryApiCallId) {
            this.searchLibrarySuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.deleteLibraryApiCallId) {
            this.deleteLibrarySuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.renameLibraryApiCallId) {
            this.RenameLibrarySuccessCallBack(responseJson)
        }
    };

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getPhotoLibraryApiCallId) {
            this.getPhotosLibraryFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.postAddLibararyApiCallId) {
            this.addLibraryFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.searchLibraryApiCallId) {
            this.searchLibraryFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.deleteLibraryApiCallId) {
            this.deleteLibraryFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.renameLibraryApiCallId) {
            this.RenameLibraryFailurCallBack(responseJson)
        }
    };

    handleMenu = (index: number) => {
        if (typeof this.state.openImageMenuIndex === "number") {
          this.setState({ openImageMenuIndex: false });
        } else {
          this.setState({ openImageMenuIndex: index });
        }
      };
    
    handleVideoMenu = (index: number) => {
        if (typeof this.state.openVideoMenuIndex === "number") {
          this.setState({ openVideoMenuIndex: false });
        } else {
          this.setState({ openVideoMenuIndex: index });
        }
      };

    openImageModal = (item: LibraryContent) => {
        this.setState({ selectedUrl: item.url , contentType : item.content_type });
      };
    
    closeImageModal = () => {
        this.setState({ selectedUrl: "" });
    };

    openDeleteModal = (item: LibraryContent) => {
        this.setState({ openDeleteModal: true, blobId: item.id, deleteFileName: item.filename });
    };

    closeDeleteModal = () => {
        this.setState({ openDeleteModal: false , openImageMenuIndex: false, openVideoMenuIndex: false  });
    };

    openRenameModal = (item: LibraryContent) => {
        this.setState({ openRenameModal: true, renameText: item.filename, blobId: item.id });
    };

    closeRenameModal = () => {
        this.setState({ openRenameModal: false, renameText: "", openImageMenuIndex: false, openVideoMenuIndex: false });
    };

    openUploadModal = () => {
        this.setState({ openUploadModal: true });
    };

    closeUploadModal = () => {
        this.setState({ openUploadModal: false , selectedFile : null });
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ renameText: event.target.value })
    };

    closeSuccessModal = () =>{
        this.setState({ openSuccessModal : false })
    }

    isValidFiles = (file: File) => {
        const validExtensions = [".jpg", ".jpeg", ".png", ".mp4"];
        return file.size < 10 * 1024 * 1024 && validExtensions.some(ext => file.name.toLowerCase().endsWith(ext));
      };
      
    handleUploadChange = (files: File[]) => {
        if (files && files.length > 0) {
            const validFiles = files.filter(file => this.isValidFiles(file));
            if (validFiles.length > 0) {
                this.setState({ selectedFile: validFiles, showValidFile: false });
            } else {
                this.setState({ showValidFile: true });
            }
        }
    };
      
    handleSortData = (event: SelectChangeEvent<string>) => {
        this.setState({ sortContent: event.target.value }, () => {
            if (event.target.value !== "") {
                this.handleSortLibraryData();
            } else {
                this.handleGetPhotosLibrary();
            }
        })
    }

    handleLibrarySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchContent: event.target.value }, ()=>{
            if(event.target.value !== ""){
              this.handleSearchLibraryData();
            }else{
             this.handleGetPhotosLibrary();
            }
        })
    };

    handleLibraryResponse = (libraryData: LibraryContent[]) => {
        const photoList = libraryData.filter((item) => item.content_type.includes("image"));
        this.setState({ photoData: photoList , isLoading: false})
        const videoList = libraryData.filter((item) => item.content_type.includes("video"));
        this.setState({ videoData: videoList , isLoading: false})
    }

    handleGetPhotosLibrary = async () => {
        this.setState({ isLoading : true})
        this.getPhotoLibraryApiCallId = await this.photoLibraryApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries`,
        });
    };

    getPhotosLibrarySuccessCallBack = (responseJson: ValidResponseType) => {
        if (responseJson.data) {
            const libraryData = responseJson.data.attributes.contents;
            this.handleLibraryResponse(libraryData)
        }
        if (responseJson.error) {
            toast.error(responseJson.error)
            this.setState({ isLoading: false })
        }
    }

    getPhotosLibraryFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleAddLibrary = async () => {
        this.setState({ isLoading: true });
        const fileContents = this.state.selectedFile;
        if (fileContents && fileContents.length > 0) {
          let formdata = new FormData();
      
          fileContents.forEach((file) => {
            formdata.append("content[]", file);
          });
      
          this.postAddLibararyApiCallId = await this.photoLibraryApiCall({
            method: configJSON.postApiMethod,
            endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries/add_content`,
            body: formdata,
          });
        }
      };
      

    addLibrarySuccessCallBack = (responseJson : ValidResponseType ) =>{
        const libraryData = responseJson.data;
        const errorData = responseJson.error;
      if(libraryData){
        this.handleGetPhotosLibrary();
        this.setState({ isLoading: false , openUploadModal: false , selectedFile: null , openSuccessModal: true})
      }
      if(errorData){
        toast.error(responseJson.error)
        this.setState({ isLoading: false , openUploadModal: false})
      }
    }

    addLibraryFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading: false , openUploadModal: false})
        }
    }

    handleSearchLibraryData = async () => {
        const { searchContent } = this.state;
        this.searchLibraryApiCallId = await this.photoLibraryApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries/search_content?query=${searchContent}`,
        });
    };

    searchLibrarySuccessCallBack = (responseJson: SearchResponseType) => {
        if (responseJson.data) {
            const libraryList: Array<LibraryContent> = [];
            responseJson.data.forEach((item) => {
                libraryList.push(item.attributes)
            })
            this.handleLibraryResponse(libraryList)
        }
        if (responseJson.error) {
            toast.error(responseJson.error)
        }
    }

    searchLibraryFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
        }
    }

    handleSortLibraryData = async () => {
        const { sortContent } = this.state;
        this.searchLibraryApiCallId = await this.photoLibraryApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries/sort_content?sort_by=${sortContent}`,
        });
    };

    handleDeleteLibraryData = async () => {
        const { blobId } = this.state;
        this.deleteLibraryApiCallId = await this.photoLibraryApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries/delete_content?blob_id=${blobId}`,
        });
    };

    deleteLibrarySuccessCallBack = (responseJson: ValidResponseType) => {
        if (responseJson.message) {
            toast.success("Content deleted successfully!");
            this.handleGetPhotosLibrary();
            this.setState({ openDeleteModal: false, openImageMenuIndex: false, openVideoMenuIndex: false })
        }
    }

    deleteLibraryFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0]);
        }
    }

    handleRenameLibraryData = async () => {
        const { renameText, blobId } = this.state;
        this.renameLibraryApiCallId = await this.photoLibraryApiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.patchApiMethod,
          endPoint: `${configJSON.photoLibraryEndPoint}${this.userId}/libraries/rename_content?blob_id=${blobId}&content_name=${renameText}`,
        });
      };
    
    RenameLibrarySuccessCallBack = (responseJson:  ValidResponseType) => {
        if (responseJson.data) {
            toast.success("Content name updated successfully!");
            this.handleGetPhotosLibrary();
            this.setState({ openRenameModal: false, openImageMenuIndex: false, openVideoMenuIndex: false})
        }
      }
    
    RenameLibraryFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }
    // Customizable Area End
}