// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    children: React.ReactNode;
    isBackgroundVideo:boolean;
}

interface UserprofileResponse {
    id: string,
    attributes: {
        first_name?: string
        last_name?: string
        position?: string;
        full_phone_number?: string;
        city?: string;
        country?: string;
        postal_code?: string;
        tax_id?: string;
        email?: string;
        google_profile_picture?: string,
        profile_image?: string,
    }
}
export interface LoginResponse {
    meta?: {
        token: string
        refresh_token: string;
        id: string | number
        user_subscriptions: boolean
    }
    data?: UserprofileResponse
}
interface UserData {
    firstName?: string,
    lastName?: string,
    position?: string,
    city?: string,
    country?: string,
    email?: string,
    tax_id?: string,
    phone?: string,
    profileImage?: string
}
interface S {
    isBackgroundVideoAvailable: boolean,
    isOpenSignUpForm: boolean,
    isOpenLogInForm: boolean,
    openLoginSignup: boolean,
    isOpenForgetPasswordForm: boolean,
    isGoogleLogedInFailed: boolean,
    isOpenForgetPasswordOtpForm: boolean,
    userData: UserData
}

interface SS {
    id: any;
}

export default class LandingPageHeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    googleLoginApiId: string = "";
    getUserProfileApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.closeSignUpFormMessage),
            getName(MessageEnum.openLogInFormRegistrationMessage),
            getName(MessageEnum.openSignUpFormMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.hasVideoMessage),
        ];

        this.state = {
            isGoogleLogedInFailed: false,
            isOpenSignUpForm: false,
            isOpenLogInForm: false,
            isBackgroundVideoAvailable: false,
            isOpenForgetPasswordForm: false,
            isOpenForgetPasswordOtpForm: false,
            openLoginSignup: false,
            userData: {}
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (getName(MessageEnum.hasVideoMessage) === message.id) {
            this.setState({ isBackgroundVideoAvailable: true })
        }
        if (getName(MessageEnum.openLogInFormRegistrationMessage) === message.id) {
            this.handleOpenLogInFromRegistration()
        }
        if (getName(MessageEnum.openSignUpFormMessage) === message.id) {
            this.handleOpenSignUpForm()
        }
        if (getName(MessageEnum.closeSignUpFormMessage) === message.id) {
            this.handleCloseSignUpForm();
        }
        if (apiRequestCallId === this.googleLoginApiId) {
            this.handleGoogleLoginResponse(responseJson)
        } else if (apiRequestCallId === this.getUserProfileApiCallId) {
            this.handleUserProfileResponse(responseJson.data)
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getProfileData()
    }

    handleOpenLogInForm() {
        this.setState({ isOpenLogInForm: true, isOpenSignUpForm: false })
    }

    handleOpenForgetPassword() { 
        this.setState({ isOpenLogInForm: false, isOpenForgetPasswordForm: true })
    }
    handleCloseForgetPasswordForm() {
        this.setState({ isOpenForgetPasswordForm: false })
    }
    handleOpenRegistrationFromLogIn() {
        this.setState({ isOpenLogInForm: false, isOpenSignUpForm: true })
    }

    handleCloseLogInForm() {
        this.setState({ isOpenLogInForm: false })
    }

    handleOpenSignUpForm() {
        this.setState({ isOpenSignUpForm: true, openLoginSignup: false })
    }
    handleOpenLogInFromRegistration() {
        this.setState({ isOpenLogInForm: true, isOpenSignUpForm: false })
    }
    handleCloseSignUpForm() {
        this.setState({ isOpenSignUpForm: false })
    }

    handleNavigate(path:string){
        this.props.navigation.navigate(path)
    }

    handleGoogleRegister(token: string) {
        const header = {
            "Content-Type": "application/json"
        };
        const httpBody = {
            data: {
                type: "social_account",
                token: token
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.googleLoginApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/accounts"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    async handleGoogleLoginResponse(responseJson: LoginResponse) {
        if (responseJson.meta && responseJson.meta.token) {
            await setStorageData('authToken', responseJson.meta.token);
            await setStorageData('userId', responseJson.meta.id);
            await setStorageData('refreshToken', responseJson.meta.refresh_token);
            await this.getProfileData()
            this.navigateToNextPage(responseJson.meta.user_subscriptions);
        }
    }

    navigateToNextPage(userSubscriptions: boolean) {
        const nextPage = userSubscriptions
            ? 'CustomisableUserProfileEdit'
            : 'Customisableusersubscriptions';
        this.props.navigation.navigate(nextPage);
    }

    async getProfileData() {
        const token = await getStorageData('authToken');
        const userId = await getStorageData('userId');
        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const endPoint = `${configJSON.getCustomizableProfileEndPoint}${userId}/logged_user`
        this.getUserProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUserProfileResponse(response: UserprofileResponse) {
        if (response) {
            this.setState({
                userData: {
                    firstName: response.attributes.first_name,
                    lastName: response.attributes.first_name,
                    email: response.attributes.email,
                    phone: response.attributes.full_phone_number,
                    position: response.attributes.position,
                    city: response.attributes.city,
                    country: response.attributes.country,
                    tax_id: response.attributes.tax_id,
                    profileImage: response.attributes.profile_image ? response.attributes.profile_image : response.attributes.google_profile_picture
                }
            })
        }
    }
}

// Customizable Area End
