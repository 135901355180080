import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import {
    SelectChangeEvent
} from '@mui/material';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface ValidResponseType {
    success: boolean,
    statusCode: number,
    message: string,
    data: Workspace;
    errors: Array<string>;
    error: {
        code: string,
        message: string,
        details: string
    }
}
export interface InvalidResponseType {
    errors: Array<string>;
}

export interface Workspace {
        workspaces: [ WorkspaceList ]   
}

export interface WorkspaceList {
    workspace: {
        id: string,
        name: string,
        account_id: number,
        campaigns: Array<CampaignWorkspace>,
        communitys: Array<object>,
        employees: Array<object>
    }
}

export interface WorkspaceDetails {
    success: boolean,
    statusCode: number,
    message: string,
    data: WorkspaceList;
    errors: Array<string>;
    error: {
        code: string,
        message: string,
        details: string
    }
}

export interface CampaignWorkspace {
    id: string,
    name: string,
    created_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    workspaceData: Array<WorkspaceList>;
    campaignData: Array<CampaignWorkspace>;
    openMenuIndex: number | boolean;
    openDeleteModal: boolean;
    openRenameModal: boolean;
    renameText: string;
    deleteFileName: string;
    sortWorkspace: string;
    searchWorkspace: string;
    isLoading: boolean;
    openSuccessModal: boolean;
    openWorkspaceName: boolean;
    blobId: string;
    workspaceName: string;
    workspaceId: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PhotosLibraryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    authToken: string = "";
    userId: string = "";
    workspaceId: string = "";
    getMyWorkspaceApiCallId: string = "";
    deleteWorkspaceApiCallId: string = "";
    renameWorkspaceApiCallId: string = "";
    getWorkspaceDeatilsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            workspaceData: [],
            campaignData: [],
            openMenuIndex: false,
            openDeleteModal: false,
            openRenameModal: false,
            renameText: "",
            deleteFileName: "",
            sortWorkspace: "",
            searchWorkspace: "",
            isLoading: false,
            openSuccessModal: false,
            openWorkspaceName: false,
            blobId: "",
            workspaceName: "",
            workspaceId: "",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponseController(responseJson)) {
                this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
            }
            if (this.isInValidResponseController(responseJson)) {
                this.apiFailureCallBacksController(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.authToken = await getStorageData('authToken');
        this.userId = await getStorageData('userId');
        this.workspaceId = await getStorageData('workId');
        this.handleGetMyWorkspace();
        if(this.workspaceId){
        this.handleGetWorkspaceDeatils();
        }
    }

    workspaceApiCall = async (valueData: {
        method?: string;
        contentType?: string;
        body?: {};
        endPoint?: string;
        type?: string;
        apiType?: string;
    }) => {
        const token = this.authToken;
        let { contentType, method, endPoint, body } = valueData;
        let header = {
            "Content-Type": contentType,
            token,
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    isValidResponseController = (responseJson: ValidResponseType) => {
        return responseJson && !responseJson.errors;
    };

    isInValidResponseController = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: ValidResponseType & WorkspaceDetails) => {
        if (apiRequestCallId === this.getMyWorkspaceApiCallId) {
            this.getMyWorkspaceSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.deleteWorkspaceApiCallId) {
            this.deleteWorkspaceSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.renameWorkspaceApiCallId) {
            this.RenameWorkspaceSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getWorkspaceDeatilsApiCallId) {
            this.getWorkspaceDeatilsSuccessCallBack(responseJson)
        }
    };

    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getMyWorkspaceApiCallId) {
            this.getMyWorkspaceFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.deleteWorkspaceApiCallId) {
            this.deleteWorkspaceFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.renameWorkspaceApiCallId) {
            this.RenameWorkspaceFailurCallBack(responseJson)
        }
        if (apiRequestCallId === this.getWorkspaceDeatilsApiCallId) {
            this.getWorkspaceDeatilsFailurCallBack(responseJson)
        }
    };

    handleWorkspaceMenu = (index: number) => {
        if (typeof this.state.openMenuIndex === "number") {
            this.setState({ openMenuIndex: false });
        } else {
            this.setState({ openMenuIndex: index });
        }
    };

    workspaceNameMenu = () => {
        this.setState({ openWorkspaceName: !this.state.openWorkspaceName });
    }

    openDeleteWorkspace = (item : WorkspaceList) => {
        this.setState({ openDeleteModal: true, blobId: item.workspace.id, workspaceName: item.workspace.name});
    };

    openRenameWorkspace = (item : WorkspaceList) => {
        this.setState({ openRenameModal: true, blobId: item.workspace.id, renameText: item.workspace.name});
    };

    openDeleteWorkspaceName = () => {
        this.setState({ openDeleteModal: true});
    };

    openRenameWorkspaceName = () => {
        this.setState({ openRenameModal: true});
    };

    closeDeleteWorkspace = () => {
        this.setState({ openDeleteModal: false, openMenuIndex: false });
    };

    closeRenameWorkspace = () => {
        this.setState({ openRenameModal: false, renameText: "", openMenuIndex: false });
    };

    handleWorkspaceName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ renameText: event.target.value });
    };

    closeSuccessWorkspace = () => {
        this.setState({ openSuccessModal: false });
    };

    handleWorkspaceSort = (event: SelectChangeEvent<string>) => {
        this.setState({ sortWorkspace: event.target.value }, ()=>{
            this.handleGetMyWorkspace();
        })
    };

    handleWorkspaceSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchWorkspace: event.target.value }, ()=>{
            this.handleGetMyWorkspace();
        })
    };

    goToNewWorkspace = () => {
        const contentResult = new Message(getName(MessageEnum.NavigationMessage));
        contentResult.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "NewWorkspace"
        );
        contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(contentResult);
    };

    goToWorkspaceName = (item : WorkspaceList) => {
        const contentResult = new Message(getName(MessageEnum.NavigationMessage));
        contentResult.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "WorkspaceName"
        );
        contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(contentResult);
        setStorageData("workId", item.workspace.id)
        setStorageData("workName", item.workspace.name)
    };

    goToCampaignWorkspace = () => {
        const Workspace = new Message(getName(MessageEnum.NavigationMessage));
        Workspace.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "CampaignWorkspace"
        );
        Workspace.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(Workspace);
    };

    handleGetMyWorkspace = async () => {
        const { sortWorkspace, searchWorkspace } = this.state;
        this.setState({ isLoading : true})
        this.getMyWorkspaceApiCallId = await this.workspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces?query=${searchWorkspace}&sort_by=${sortWorkspace}`,
        });
    };

    getMyWorkspaceSuccessCallBack = (responseJson: ValidResponseType) => {
        const workspaces = responseJson.data.workspaces
        if (workspaces) {
        this.setState({ 
            workspaceData : workspaces,
            isLoading: false
        })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
            this.setState({ isLoading: false })
        }
    }

    getMyWorkspaceFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleGetWorkspaceDeatils = async () => {
        this.setState({ isLoading : true})
        this.getWorkspaceDeatilsApiCallId = await this.workspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${this.workspaceId}`,
        });
    };

    getWorkspaceDeatilsSuccessCallBack = (responseJson: WorkspaceDetails) => {
        if (responseJson.data.workspace) {
          const workspaces = responseJson.data.workspace
        this.setState({ 
            workspaceName : workspaces.name,
            renameText: workspaces.name, 
            campaignData: workspaces.campaigns,
            isLoading: false
        })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
            this.setState({ isLoading: false })
        }
    }

    getWorkspaceDeatilsFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0])
            this.setState({ isLoading : false})
        }
    }

    handleDeleteWorkspaceData = async () => {
        const { blobId } = this.state;
        this.deleteWorkspaceApiCallId = await this.workspaceApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${blobId}`,
        });
    };

    deleteWorkspaceSuccessCallBack = (responseJson: ValidResponseType) => {
        if (responseJson.message) {
            this.handleGetMyWorkspace();
            this.handleGetWorkspaceDeatils();
            this.setState({ openSuccessModal: true, openDeleteModal: false, openMenuIndex: false, openWorkspaceName: false })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
    }

    deleteWorkspaceFailurCallBack = (responseJson : InvalidResponseType) =>{
        if(responseJson.errors){
            toast.error(responseJson.errors[0]);
        }
    }

    handleRenameWorkspaceData = async () => {
        const { renameText, blobId } = this.state;
        let apiBody = {
            workspace: {
                name: renameText
            }
        }

        this.renameWorkspaceApiCallId = await this.workspaceApiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.patchApiMethod,
          endPoint: `${configJSON.myWorkspaceEndPoint}${this.userId}/workspaces/${blobId}`,
          body: JSON.stringify(apiBody)
        });
      };
    
    RenameWorkspaceSuccessCallBack = (responseJson:  WorkspaceDetails) => {
        if (responseJson.data.workspace) {
            toast.success("Workspace name updated successfully!");
            this.handleGetMyWorkspace()
            this.handleGetWorkspaceDeatils()
            this.setState({ openRenameModal: false, openMenuIndex: false, openWorkspaceName: false })
        }
        if (responseJson.error) {
            toast.error(responseJson.error.message)
        }
      }
    
    RenameWorkspaceFailurCallBack = (responseJson: InvalidResponseType) => {
        if (responseJson.errors[0]) {
          toast.error(responseJson.errors[0])
        }
      }
    // Customizable Area End
}