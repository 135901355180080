import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  IconButton,
  Modal,
  Paper,
  TextField
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { deletesIcon, renameIcon, addButton, facebookIcon, subcribeIcon, instaIcon, blogIcon, successIcon } from './assets';
import WorkspaceNameController, { Props, configJSON } from "./MyWorkspacesController.web";
import moment from "moment";
// Customizable Area End

export default class WorkspaceName extends WorkspaceNameController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderWorkspaceNameHeader = () => {
    const { openWorkspaceName, workspaceName } = this.state;
    return (
      <WorkspaceNameCardBox>
        <Box className="cardsNameBox" >
          <Box className="titleNameBox">
            <Typography className='fileName'>{workspaceName}</Typography>
            <MoreVertIcon
              className={openWorkspaceName === true ? "moreNameButtonActive" : "moreNameButton"}
              onClick={this.workspaceNameMenu}
              data-testId="nameMenuIconBtnTestId"
            />
            {openWorkspaceName === true && (
              <Box className="moreNameMenu" >
                <Button className="deleteNameBtn" data-testId="deleteBtnTestID"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openDeleteWorkspaceName()
                  }}
                >
                  <img src={deletesIcon} alt=" " />
                  <Typography data-testId="deleteBtnTextTestID">{configJSON.deleteText}</Typography>
                </Button>
                <Button className="renameNameBtn" data-testId="renameBtnTestID"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openRenameWorkspaceName()
                  }} >
                  <img src={renameIcon} alt=" " />
                  <Typography data-testId="renameBtnTextTestID">{configJSON.renameText}</Typography>
                </Button>
              </Box>)}
          </Box>
        </Box>
      </WorkspaceNameCardBox>
    )
  };

  renderWorkspaceName = () => {
    const { campaignData } = this.state;
    return (
      <WorkspaceNameContainer>
        <WorkspaceNameBox>
          <WorkspaceNameHeadingBox>
            <NameHeadingBox>
              <Box className="headingName" data-testId="campaignWorkspaceLabelTestId">{configJSON.campaign}</Box>
              <Box className="HeadingTotal campaignBg">{campaignData.length}</Box>
            </NameHeadingBox>
            <img src={addButton} data-testId="campaignWorkspaceBtnTestId" onClick={this.goToCampaignWorkspace} className="Name_img" />
          </WorkspaceNameHeadingBox>
          <CampaignNameCardBox>
        <Box className="CardNameBox">
          {campaignData.map((item, index) => (
            <Box className="CampaignNameCard" key={index}
            >
              <Box className="CampaignNameTitleBox">
                <Typography className='campaignName_date'>{"Created on: " + moment(item.created_at).format("MMM DD")}</Typography>
              </Box>
              <CampaignNameMainBox>
                <Typography className='campaign_workspace_name'>{item.name}</Typography>
                <SocialIconNameBox>
                  <IconNameBox>
                    <img src={facebookIcon} className="social_name_icon_img" />
                    <Box className="socialCountName">{"0"}</Box>
                  </IconNameBox>
                  <IconNameBox>
                    <img src={subcribeIcon} className="social_name_icon_img" />
                    <Box className="socialCountName">{"0"}</Box>
                  </IconNameBox>
                  <IconNameBox>
                    <img src={instaIcon} className="social_name_icon_img" />
                    <Box className="socialCountName">{"0"}</Box>
                  </IconNameBox>
                  <IconNameBox>
                    <img src={blogIcon} className="social_name_icon_img" />
                    <Box className="socialCountName">{"0"}</Box>
                  </IconNameBox>
                </SocialIconNameBox>
              </CampaignNameMainBox>
            </Box>
          ))}
        </Box>
      </CampaignNameCardBox>
          <WorkspaceNameSeeAllBtn>{configJSON.seeAllText}</WorkspaceNameSeeAllBtn>
        </WorkspaceNameBox>
        <WorkspaceNameBox>
          <WorkspaceNameHeadingBox>
            <NameHeadingBox>
              <Box className="headingName">{configJSON.communityManager}</Box>
              <Box className="HeadingTotal communityBg">{"2"}</Box>
            </NameHeadingBox>
            <img src={addButton} className="Name_img" />
          </WorkspaceNameHeadingBox>
          <WorkspaceNameSeeAllBtn>{configJSON.seeAllText}</WorkspaceNameSeeAllBtn>
        </WorkspaceNameBox>
        <WorkspaceNameBox>
          <WorkspaceNameHeadingBox>
            <NameHeadingBox>
              <Box className="headingName">{configJSON.report}</Box>
              <Box className="HeadingTotal reportBg">{"9"}</Box>
            </NameHeadingBox>
            <img src={addButton} className="Name_img" />
          </WorkspaceNameHeadingBox>
          <WorkspaceNameSeeAllBtn>{configJSON.seeAllText}</WorkspaceNameSeeAllBtn>
        </WorkspaceNameBox>
        <WorkspaceNameBox>
          <WorkspaceNameHeadingBox>
            <NameHeadingBox>
              <Box className="headingName">{configJSON.team}</Box>
              <Box className="HeadingTotal teamBg">{"13"}</Box>
            </NameHeadingBox>
            <img src={addButton} className="Name_img" />
          </WorkspaceNameHeadingBox>
          <WorkspaceNameSeeAllBtn>{configJSON.seeAllText}</WorkspaceNameSeeAllBtn>
        </WorkspaceNameBox>
      </WorkspaceNameContainer>
    )
  };

  renderDeleteWorkspaceName = () => {
    const { openDeleteModal, workspaceName } = this.state;
    return (
       <WorkspaceNameModalContainer
          open={openDeleteModal}
          disablePortal
          disableAutoFocus
          disableEnforceFocus
        >
          <Paper className="paper">
            <IconButton
              onClick={this.closeDeleteWorkspace}
              data-testId="closeDeleteTestID"
              className="closeIconBtn"
             >
              <CloseIcon/>
              </IconButton>
             <Typography variant="body1" data-testId="closeDeleteLabelTestID">{configJSON.deleteWorkspaceText}</Typography>
             <Typography variant="body2">{configJSON.workspaceDeletefirst}<span className='workspace_name'>{workspaceName+"?"}</span></Typography>
             <Typography variant="body2">{configJSON.workspaceDeletesecond}</Typography>
              <Box className="buttonBox" >
               <Button className="cancleBtn" onClick={this.closeDeleteWorkspace}>{configJSON.cancelDeleteText}</Button>
               <Button className="deleteBtn" data-testId="deleteWorkspaceTestID" onClick={this.handleDeleteWorkspaceData}>{configJSON.yesDeleteText}</Button>
             </Box>
            </Paper>
         </WorkspaceNameModalContainer>
      )
    };

  renderRenameWorkspaceName = () => {
     const { renameText } = this.state;
     return (
       <WorkspaceNameModalContainer
         open={this.state.openRenameModal}
         disablePortal
         disableEnforceFocus
         disableAutoFocus
       >
         <Paper className={this.state.openRenameModal ? "renamepaper paper" : "paper"}>
           <IconButton
             onClick={this.closeRenameWorkspace}
             data-testId="closeRenameTestID"
             className="closeIconBtn"
           >
             <CloseIcon />
           </IconButton>
           <Typography variant="body1" data-testId="closeRenameLabelTestID">
             {configJSON.renameWorkspaceText}
           </Typography>
           <RenameWorkspaceNameField
             value={renameText}
             onChange={(event) => { this.handleWorkspaceName(event) }}
             data-testId="renameWorkspaceTestID"
           />
           <Box className="buttonBox">
            <Button className="cancleBtn" onClick={this.closeRenameWorkspace}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="deleteBtn"  data-testId="saveRenameTestID" onClick={this.handleRenameWorkspaceData} disabled={renameText === "" ? true : false} >{configJSON.saveText}</Button>
          </Box>
        </Paper>
      </WorkspaceNameModalContainer>
    )
  };

  renderSuccessWorkspaceName = () => {
    const { openSuccessModal } = this.state;
    return (
      <WorkspaceNameModalContainer
        open={openSuccessModal}
        onClose={this.closeSuccessWorkspace}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_upload paper_success">
          <IconButton
            onClick={this.closeSuccessWorkspace}
            data-testId="deleleCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <WorkspaceNameHeadBox>
            <WorkspaceNameHeadTypo data-testId="deleleCloseLabelTestID">{configJSON.deleteSuccessText}</WorkspaceNameHeadTypo>
            <Typography className='workspaceDeleteWarn'>{configJSON.workspaceDeletesecond}</Typography>
            <img src={successIcon} className="succes_image" />
          </WorkspaceNameHeadBox>
        </Paper>
      </WorkspaceNameModalContainer>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainNewContainer>
        {this.renderWorkspaceNameHeader()}
        {this.renderWorkspaceName()}
        {this.renderDeleteWorkspaceName()}
        {this.renderRenameWorkspaceName()}
        {this.renderSuccessWorkspaceName()}
      </MainNewContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainNewContainer = styled(Box)({
  padding: "30px 24px",
  background: "#F6F6F6",
  '@media (max-width: 600px)': {
    padding: '15px',
  },
});

const WorkspaceNameSeeAllBtn = styled(Button)({
  textTransform: 'none',
  color: "#000000",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  fontFamily: "Open Sans",
  padding: '10px 0px',
  width: "calc(100% - 30px)",
  background: '#FFFFFF',
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid #DDDDDDDD",
  gap: "24px",
  position: "absolute",
  bottom: "10px",
  "&:hover": {
    background: '#FFFFFF',
  },
});

const WorkspaceNameContainer = styled(Box)({
  display: "flex",
  marginTop: "36px",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "20px"
});

const WorkspaceNameBox = styled(Box)({
  flex: "1 0 45%",
  height: "460px",
  border: "1px solid #DDDDDDDD",
  borderRadius: "8px",
  padding: "16px",
  gap: "24px",
  background: "#FFFFFF",
  position: "relative",
});

const NameHeadingBox = styled(Box)({
  display: "flex",
  gap: "8px"
});

const WorkspaceNameHeadingBox = styled(Box)({
  borderBottom: "1px solid #DDDDDDDD",
  padding: "12px 8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .headingName": {
    color: "#4D4D4D",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  "& .HeadingTotal": {
    color: "#4D4D4D",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    textAlign: "center"
  },
  "& .Name_img": {
    width: "24px",
    hight: "24px"
  },
  "& .campaignBg": {
    background: "#FFFDD8",
  },
  "& .communityBg": {
    background: "#E5F6EB",
  },
  "& .reportBg": {
    background: "#F2F5FF",
  },
  "& .teamBg": {
    background: "#F9F9F9",
  },
});

const WorkspaceNameCardBox = styled(Box)({
  "& .cardsNameBox": {
    width: "100%",
    display: "flex",
  },
  "& .titleNameBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px",
    },
    "& .fileName": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 600,
      lineHeight: "43.58px",
      fontSize: "32px",
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
  },
  "& .moreNameButton": {
    cursor: "pointer",
    fontSize: "28px"
  },
  "& .moreNameButtonActive": {
    cursor: "pointer",
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
    fontSize: "28px"
  },
  "& .moreNameMenu": {
     position: "absolute",
     zIndex: 1,
     top: 38,
     right: 0,
     display : "flex",
     justifyContent: "center",
     flexDirection: "column",
     "& .MuiButton-root" : {
       backgroundColor: "#fff",
       fontFamily: "Open Sans",
       textTransform: "none",
       color: "#4D4D4D",
       lineHeight: "21px",
       fontWeight: 400,
       gap: "10px",
       fontSize: "14px",
       width: "116px",
       height: "36px",
       boxSizing: "border-box",
       justifyContent: "flex-start",
       padding: "0px 16px",
       "& img" : {
         width : "18px",
         height : "18px"
       }
     }
   },
   "& .deleteNameBtn" : {
     borderBottomLeftRadius: "0px",
     borderTopRightRadius: "8px",
     border: "1px solid #DDDDDD",
     borderTopLeftRadius: "8px",
     borderBottomRightRadius: "0px",
   },
   "& .renameNameBtn" : {
     borderTop: "none",
     borderBottomRightRadius: "8px",
     borderBottomLeftRadius: "8px",
     borderTopRightRadius: "0px",
     borderTopLeftRadius: "0px",
     border: "1px solid #DDDDDD",
   }
 });

 const CampaignNameCardBox = styled(Box)({
  "& .CardNameBox": {
    width: "100%",
    height: "350px",
    gap: "12px",
    display: "flex",
    flexWrap: "wrap",
    overflow: "scroll",
    scrollbarWidth: "0.5px !important",
    marginTop: "10px",
  },
  "& .CampaignNameCard": {
    background: "#FFFDD8",
    width: "200px",
    height: "168px",
    borderRadius: "8px",
    boxSizing: "border-box",
    border: "1px solid #DDDDDDDD",
    position: "relative",
    '@media (max-width: 580px)': {
      width: "260px",
    },
  },
  "& .CampaignNameTitleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    padding: "18px 8px 12px",
    boxSizing: "border-box",
    width: "100%",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px",
    },
    "& .campaignName_date": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "16.34px",
      fontSize: "12px",
      padding: "2px 8px",
      borderRadius: "100px",
      background: "#FFFBAA"
    }
  }
});

const CampaignNameMainBox = styled(Box)({
  padding: "0 8px",
  "& .campaign_workspace_name": {
      color: "#000000",
      fontFamily: "Open Sans",
      fontWeight: 600,
      lineHeight: "28px",
      fontSize: "18px !important",
      padding: "0px 4px !important",
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
});

const SocialIconNameBox = styled(Box)({
   width: "calc(100% - 41px)",
   padding: "4px 12px",
   display: "flex",
   justifyContent: "space-between",
   background: "#FFFBAA",
   position: "absolute",
   bottom: "14px",
   borderRadius: "100px",
   height: "26px"
});

const IconNameBox = styled(Box)({
  display: 'flex',
  gap: "8px",
  alignItems: "center",
  "& .social_name_icon_img":{
    width: "14px",
    height: "14px"
  },
  "& .socialCountName":{
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "16px",
    fontSize: "12px",
  }
});

const WorkspaceNameModalContainer = styled(Modal)({
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   margin: "20px 0px",
   padding: "20px",
    "& .paper": {
     width: "fit-content",
     maxHeight: 'fit-content',
     flexDirection: "column",
     display: "flex",
     padding: "40px",
     alignItems: "center",
     gap: "20px 0px",
     borderRadius: "24px",
     boxSizing: "border-box",
     position: "relative",
     "& .MuiTypography-body1": {
       lineHeight: "27px",
       color: "#4D4D4D",
       fontSize: "20px",
       fontWeight: 600,
     },
     "& .MuiTypography-body2": {
       lineHeight: "21px",
       color: "#4D4D4D",
       fontSize: "16px",
       fontWeight: 400,
       textAlign: "center",
       maxWidth: "500px",
     },
     '@media (max-width: 375px)': {
       padding: "20px",
     },
   },
   "& .paper_success": {
     padding: "40px !important",
   },
   "& .paper_upload": {
     width: "600px",
     maxHeight: 'fit-content',
     flexDirection: "column",
     display: "flex",
     padding: "40px",
     alignItems: "center",
     gap: "20px 0px",
     borderRadius: "24px",
     boxSizing: "border-box",
     position: "relative",
     '@media (max-width: 775px)': {
       width: "550px"
     },
     '@media (max-width: 675px)': {
       width: "500px"
     },
     '@media (max-width: 575px)': {
       width: "450px"
     },
     '@media (max-width: 475px)': {
       width: "350px"
     },
     '@media (max-width: 375px)': {
       width: "280px",
       padding: "20px",
     },
   },
   "& .renamepaper": {
      "& .MuiOutlinedInput-input": {
        borderRadius: "8px",
       padding: "4px !important",
       width: "100%",
       border: "1px solid #DDDDDD",
     }
    },
    "& .closeIconBtn": {
     color: "black",
     position: "absolute",
     right: "10px",
     top: "10px",
   },
   "& .buttonBox": {
     display: "flex",
     padding: "20px 0px",
     gap: "20px",
     "& .MuiButton-root": {
       borderRadius: "8px",
       textTransform: "none",
       lineHeight: "24px",
       padding: "10px 16px",
       fontSize: "16px",
       fontWeight: 600,
       height: "48px",
       width: "212px",
       '@media (max-width: 675px)': {
         width: "200px !important"
       },
       '@media (max-width: 575px)': {
         width: "180px !important"
       },
       '@media (max-width: 475px)': {
         width: "130px !important"
       },
       '@media (max-width: 375px)': {
         width: "100px !important",
         padding: "10px 5px"
       },
     },
     "& .cancleBtn": {
       color: "#000000",
       border: "1px solid #000000"
     },
     "& .deleteBtn": {
       color: "white",
       backgroundColor: "#000000"
     }
   },
   "& .workspace_name":{
     lineHeight: "21.79px",
     color: "#4D4D4D",
     fontSize: "16px",
     fontWeight: 600,
   }
 });

 const RenameWorkspaceNameField = styled(TextField)({
   width: "100%",
   fontFamily: "Open Sans",
   "& .Mui-focused": {
     "& fieldset": {
       border: "none",
       "&:hover": {
         border: "none"
       }
     }
   },
   '& .MuiOutlinedInput-root': {
     color: "#4D4D4D",
     fontFamily: "Open Sans",
     "& fieldset": {
      border: "1px solid #DDDDDD !important",
      borderRadius: "8px",
      padding: "4px",
    },
    "&:hover": {
      border: "none"
    },
   }
 });

 const WorkspaceNameHeadBox = styled(Box)({
   display: "flex",
   width: '100%',
   justifyContent: "center",
   alignItems: "center",
   flexDirection: "column",
   "& .succes_image": {
      marginTop: "35px",
      width: "80px",
      height: "80px",
    },
   "& .workspaceDeleteWarn": {
     fontSize: '16px',
     fontFamily: 'Open Sans',
     fontWeight: 400,
     marginTop: "16px",
     color: '#4D4D4D',
   }
 });

 const WorkspaceNameHeadTypo = styled(Typography)({
   fontFamily: 'Open Sans',
   textAlign: "center",
   fontWeight: 600,
   color: '#4D4D4D',
   fontSize: '20px',
 });
// Customizable Area End 