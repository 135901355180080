import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import React from "react";
export interface GetVideoApiResponse {
  data:Array<{
    attributes:{
      video:string;
    }
  }>
  error:string[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children:React.ReactNode
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  video:string;
  isGettingVideo:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoBackgroundsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVideoApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      // Customizable Area Start
      video:"",
      isGettingVideo:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  apiLoginDataCallId: string = "";
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiLoginDataCallId) {
          if (responseJson) {
            localStorage.setItem("Token", responseJson.meta.token);
          }
        }
      }
    }

    // Customizable Area Start
    this.receiveResponseHandler(from,message)
    // Customizable Area End
  }
  async componentDidMount() {
    await this.login();
  }
  login() {
    let body = {
      data: {
        attributes: {
          email: configJSON.loginEmailWeb,
          password: configJSON.loginPasswordWeb,
        },
        type: "email_account",
      },
    };
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.apiLoginDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  navigateVideoBackgorundInput() {
    this.props.navigation.navigate("VideoBackgroundInput");
  }

  navigateVideoPageList() {
    this.props.navigation.navigate("VideoPageList");
  }

  // Customizable Area Start
  async receiveResponseHandler(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId ===this.getVideoApiCallId ){
          this.handleGetVideoResponse(responseJson)
        }
      }
    }
  }
  async getVideo(){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gtVideoEndPoint  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getVideoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  sendHaveVideo(){
    const message: Message = new Message(getName(MessageEnum.hasVideoMessage));
    message.addData(getName(MessageEnum.hasVideoMessage),'Video Available')
    runEngine.sendMessage(getName(MessageEnum.hasVideoMessage), message)
  }
  handleGetVideoResponse(response:GetVideoApiResponse){
    if(response.data.length>0){
      this.setState({video:response.data[0].attributes.video})
      this.sendHaveVideo()
    }
    this.setState({isGettingVideo:false})
  }
  // Customizable Area End
}
