import React, { useState } from 'react';
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    profileIcon,
    noImage,
    earthIcon,
    dotIcon,
    facebookLikeCommentShareIcons,
    facebookCommentIcon,
    facebookShareIcon,
    facebookLikeIcon,
} from '../assets';
import { Image, Video } from '../ContentManagementEditController';

interface Props {
    images: Image[];
    caption: string;
    videos: Video[]
}

const FacebookPreview: React.FC<Props> = ({ caption, images, videos }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const handleDotClick = (index: number) => {
        if (index !== currentIndex) {
            setCurrentIndex(index);
        }
    };
    const formattedText = caption.split(/(\s+)/).map((word, index) => {
        if (word.startsWith('#')) {
            return (
                <span key={index} style={{ color: '#1977F3', fontWeight: 'bold' }}>
                    {word}
                </span>
            );
        }
        return word;
    });

    return (
        <StyledWrapperContainer>
            <StyledHeader>
                <img src={profileIcon} alt="" />
                <Box>
                    <Typography sx={styles.username}>Username</Typography>
                    <Box sx={{ display: "flex", gap: "12px" }}>
                        <Typography sx={styles.visibleFor}>Sponsored</Typography>
                        <img src={dotIcon} />
                        <img src={earthIcon} />
                    </Box>
                </Box>
                <MoreHorizIcon sx={{ marginLeft: 'auto' }} />
            </StyledHeader>

            <Box sx={styles.captionContainer}>
                <Typography sx={styles.caption}>{formattedText}</Typography>
            </Box>

            <Box sx={styles.carouselContainer}>
                <div
                    style={{
                        ...styles.imageContainer,
                        transform: `translateX(-${currentIndex * 100}%)`,
                        transition: 'transform 0.5s ease',
                    }}
                >
                    {images.length === 0 && videos.length === 0 ? <img src={noImage} style={styles.image} /> :
                        <>
                            {<img src={noImage} alt={`carousel`} style={styles.image} />}
                            {videos.map((video, index) => (<video key={index} src={video.video_url} autoPlay loop style={styles.image} />))}
                        </>
                    }
                </div>
            </Box>
            {[...images, ...videos].length > 1 && <Box sx={styles.dotsContainer}>
                {[...images, ...videos].map((_, index) => (
                    <span key={index} data-testid={`dot-${index}`} style={{ ...styles.dot, ...(currentIndex === index ? {width: '8px', height: '8px', backgroundColor: '#079FFC',} : {}) }} onClick={() => handleDotClick(index)} />
                ))}
            </Box>}

            <Box sx={styles.likeIconCommentsContainer}>
                <Box sx={styles.likeIconContainer}>
                    <img src={facebookLikeCommentShareIcons} />
                    <span style={styles.likeCommentShare}>129</span>
                </Box>
                <span style={styles.likeCommentShare}>12 Comments  8 Shares</span>
            </Box>

            <Box sx={{ display: "flex", height: "2px", background: "#B3B3B3" }} />

            <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                <Box sx={styles.likeIconCommentsButtons}>
                    <img src={facebookLikeIcon} />
                    Like
                </Box>
                <Box sx={styles.likeIconCommentsButtons}>
                    <img src={facebookCommentIcon} />
                    <span style={{}}>Comments</span>
                </Box>
                <Box sx={styles.likeIconCommentsButtons}>
                    <img src={facebookShareIcon} />
                    Share
                </Box>
            </Box>
        </StyledWrapperContainer>
    );
}

export default FacebookPreview;

const StyledWrapperContainer = styled(Box)({
    borderRadius: "20px",
    border: '1px solid #B3B3B3',
    padding: '12px',
    width: "362px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    '@media (max-width:408px)': {
        width: "clamp(265px, 85vw, 362px)",
    }
})

const StyledHeader = styled(Box)({
    padding: '1px 16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
})

const styles = {
    likeIconCommentsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    captionContainer: {
        padding: '0px 12px',
    },
    visibleFor: {
        fontWeight: 400,
        fontFamily: 'Open sans',
        fontSize: '12px',
        color: '#000',
        lineHeight: '14px'
    },
    likeIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        width: "fit-content"
    },
    username: {
        fontWeight: 600,
        fontFamily: 'Open sans',
        lineHeight: '15.6px',
        fontSize: '13px',
        color: '#000',
    },
    caption: {
        color: '#4D4D4D',
        fontSize: '13px',
        fontFamily: 'Open sans',
        fontWeight: 400,
        lineHeight: '15.23px',
    },
    likeCommentShare: {
        fontFamily: "Open Sans",
        fontSize: "13px",
        color: "#65676B",
        fontWeight: 400
    },
    dot: {
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        margin: '0 2px',
        backgroundColor: '#949494',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    likeIconCommentsButtons: {
        display: "flex",
        width: 'fit-content',
        gap: "5px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "12px",
        color: "#000000"
    },
    carouselContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative' as 'relative',
        overflow: 'hidden',
    },
    imageContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    image: {
        minWidth: '100%',
        height: '100%',
        objectFit: 'cover' as 'cover',
        transition: 'transform 0.5s ease',
        maxHeight: "331px"
    },
    dotsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
};
