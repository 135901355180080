//Customizable Area Start
import React, { CSSProperties } from "react";
import {
    Typography,
    Box,
    FormControl,
    Button,
    TextField,
    Backdrop
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input';
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { headerImage, successRightSign } from "./assets";
import PasswordValidator from "../../../components/src/PasswordValidator/PasswordValidator.web";
import { StyledOuterContainer } from "../../../components/src/StyledComponents";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
        <Backdrop open={this.props.open} onClick={this.props.onClose}>
            <Box
                sx={webStyles.outerContainer}
                data-testid='ForgetPasswordOuterContainer'
                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => { event.stopPropagation(); }}
            >
                <StyledOuterContainer data-test-id='loginFormContainer'>
                    <Box sx={webStyles.forgetPasswordImageContainer}>
                        <img src={headerImage} style={webStyles.imageStyle} />
                    </Box>
                    {this.state.showEmailForm && <StyledForgetPasswordInnerContainer data-testid='emailForm'>
                        <Box sx={webStyles.forgetPasswordHeaderContainer}>
                            <Typography sx={webStyles.boldHeader}>
                                Forget Password
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "8px", maxWidth: "263px", }}>
                                <Typography sx={webStyles.boldSubHeader}>
                                    Enter your email Address
                                </Typography>
                                <Typography sx={webStyles.subHeaderDescription}>
                                    We will send a verification code to reset your password
                                </Typography>
                            </Box>
                        </Box>
                        <StyledFormControl error={this.state.emailError}>
                            <StyledLabel data-error={this.state.emailError}>Email Address</StyledLabel>
                            <StyledTextField
                                data-testid='emailInput'
                                aria-invalid={this.state.emailError}
                                placeholder="Email Address"
                                onChange={this.handleChangeEmail.bind(this)}
                                value={this.state.email}
                                error={this.state.emailError}
                                helperText={this.state.emailErrorMessage}
                            />
                        </StyledFormControl>
                        <Button
                            data-testid="continueButton"
                            fullWidth
                            sx={webStyles.continueButton}
                            onClick={this.handleForgetPassword.bind(this)}
                        >
                            Continue
                        </Button>
                    </StyledForgetPasswordInnerContainer>}
                    {this.state.showOtpForm && <StyledForgetPasswordInnerContainer style={{gap:'30px'}} data-testid="otpFormContainer">
                        <Box sx={webStyles.forgetPasswordHeaderContainer}>
                            <Typography sx={webStyles.boldHeader}>
                                Check your email box
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "8px", maxWidth: "288px", }}>
                                <Typography sx={webStyles.subHeaderDescription}>
                                    The verification code has been sent to your email:
                                </Typography>
                                <Typography sx={webStyles.boldSubHeader}>
                                    {this.state.email}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"8px",width:"100%"}}>                            
                        <OtpInput
                            data-testid="otpInput"
                            value={this.state.oneTimePassword}
                            onChange={(value) => { this.setState({ oneTimePassword: value }) }}
                            numInputs={5}
                            inputType={'tel'}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={otpInputStyle}
                            containerStyle={{ display: "flex", justifyContent: "space-between", maxWidth: "283px", width: "100%", gap: '5px', }}
                        />
                            {this.state.verifyOtpError && <Typography data-testid ="invalidOtpError" 
                                sx={{
                                    color: '#E04545',
                                    textAlign:"center",
                                    width:'100%',
                                    fontFamily: "Open Sans",
                                    fontSize: "12px",
                                }}>
                                    {this.state.verifyOtpError}
                            </Typography>}
                        </Box>
                        <Box sx={{display:'flex', flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"8px", width:"100%"}}>
                            {this.state.timeLeft === 0 ? <Button
                                data-testid="resendButton"
                                fullWidth
                                sx={webStyles.resendButton}
                                onClick={this.senOptOnEmail.bind(this)}
                                variant="outlined"
                            >
                                Resend code
                            </Button> :
                                <Typography>
                                    {`Resend code in ${this.state.timeLeft} sec`}
                                </Typography>}
                            <StyledButton
                                data-testid="confirmButton"
                                fullWidth
                                onClick={this.handleVerifyOtp.bind(this)}
                                disabled={this.state.oneTimePassword.length !== 5}
                            >
                                Confirm
                            </StyledButton>
                        </Box>
                    </StyledForgetPasswordInnerContainer>}
                    {this.state.showNewPasswordForm && <StyledForgetPasswordInnerContainer data-testid='newPasswordForm'>
                        <Box sx={webStyles.forgetPasswordHeaderContainer}>
                            <Typography sx={webStyles.boldHeader}>
                                New Password
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "8px", width: "333px", }}>
                                <Typography sx={webStyles.subHeaderDescription}>
                                    Enter a new password for email <span style={{fontWeight:700, color:"#000000"}}>{this.state.email}</span>
                                </Typography>
                            </Box>
                        </Box>
                        <StyledFormControl error={this.state.newPasswordError}>
                            <StyledLabel data-error={this.state.newPasswordError}>New Password</StyledLabel>
                            <StyledTextField
                                data-testid='newPasswordInput'
                                aria-invalid={this.state.newPasswordError}
                                placeholder="New Password"
                                onChange={this.handleChangeNewPassword.bind(this)}
                                value={this.state.newPassword}
                                error={this.state.newPasswordError}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.confirmNewPasswordError}>
                            <StyledLabel data-error={this.state.confirmNewPasswordError}>Confirm New Password</StyledLabel>
                            <StyledTextField
                                data-testid='confirmNewPasswordInput'
                                aria-invalid={this.state.confirmNewPasswordError}
                                placeholder="Confirm Password"
                                onChange={this.handleChangeConfirmNewPassword.bind(this)}
                                value={this.state.confirmNewPassword}
                                error={this.state.confirmNewPasswordError}
                                helperText={this.state.confirmNewPasswordErrorMessage}
                            />
                        </StyledFormControl>
                        <Box sx={{display:'flex', flexDirection:"column", width:"100%", gap:"8px"}}>
                            <Typography sx={{fontFamily:"Open Sans", fontWeight:600, fontSize:"12px", color:'#000000'}}>Your password must contain:</Typography>
                            <PasswordValidator password={this.state.newPassword} data-testid='passwordValidator' onValidate={this.onValidatePassword.bind(this)} />
                        </Box>
                        <StyledButton
                            data-testid="setNewPassword"
                            fullWidth
                            sx={webStyles.continueButton}
                            onClick={this.handleSetNewPassword.bind(this)}
                            disabled={!this.state.isValidPassword}
                        >
                            Set New Password
                        </StyledButton>
                    </StyledForgetPasswordInnerContainer>}
                    {this.state.showSuccessResetForm && <StyledForgetPasswordInnerContainer data-testid='successResetForm'>                        
                        <Typography sx={webStyles.boldHeader}>
                             Password Reset
                        </Typography> 
                        <StyledResetPasswordInnerContainer>                  
                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", gap: "8px", width: "100%", }}>
                                <img src={successRightSign}/>
                                <Typography sx={webStyles.subHeaderDescription}>
                                    Your password has been reset successfully 
                                </Typography>
                        </Box>
                        <StyledButton
                            data-testid="backToLoginButton"
                            fullWidth
                            onClick={this.handleOpenLoginForm.bind(this)}
                        >
                            Back to Log In
                        </StyledButton>
                        </StyledResetPasswordInnerContainer>  
                    </StyledForgetPasswordInnerContainer>}
                    {!this.state.showSuccessResetForm && <Typography sx={webStyles.backToLogInMessage}>
                        Back to {" "}
                        <span
                            data-testid='goToLogInButton'
                            style={{ fontWeight: 700, cursor: "pointer", }}
                            onClick={this.handleOpenLoginForm.bind(this)}
                        >
                            Log In
                        </span>
                    </Typography>}
                </StyledOuterContainer>
                <StyledCloseIcon data-testid={"closeButton"} onClick={this.props.onClose} />
                {this.state.showOtpForm && <StyledBackIcon  data-testid='backToForgetPasswordButton' onClick={this.handleBackToForgetPassword.bind(this)} />}
            </Box>
        </Backdrop>
        );
    }
}

const StyledForgetPasswordInnerContainer = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    gap: "24px",
    maxWidth: "310px",
    height: "fit-content",
    width: "100%",
})
const StyledResetPasswordInnerContainer = styled(Box)({
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent:"space-between",
    gap: "24px",
    width: "333px",
    height: "260px",
    marginTop:"40px",
    '@media (max-width: 440px)': {
        width: "70vw",
    },
    '@media (max-width: 350px)': {
        width: "100%",
    },
   '@media (max-height: 532px)': {
        height: '50vh',
        marginTop:"0px",
    },
    '@media (max-height: 408px)': {
        height: '40vh',
    },
    '@media (max-height: 335px)': {
        height: '32vh',
    } 
})

const StyledButton = styled(Button)({
    color: "#ffff",
    background: "#202020",
    borderRadius: "8px",
    textTransform: "none",
    height: "42px",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    fontFamily: "Open Sans",
    '&:hover': {
        background: "#202020",
    },
    '&:disabled': {
        background: "#C2C2C2",
        color: "#E9E9E9",
        cursor: "not-allowed",
    }
})
const StyledFormControl = styled(FormControl)({
    display: 'flex',
    flexDirection: "column",
    width: "100%",
    gap: "4px",
    '& .MuiFormHelperText-root': {
        marginLeft: "0px",
        fontWight: 600,
    },
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: "48px",
        borderRadius: "8px",
        '& fieldset': {
            borderColor: '#6F6F6F',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F6F6F',
        },
        '&:hover fieldset': {
            borderColor: '#6F6F6F',
        },
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#E04545',
        },
        '&:hover fieldset': {
            borderColor: '#E04545',
        },
        '& fieldset': {
            borderColor: '#E04545',
        },
    },
    '& .MuiInputBase-input': {
        height: '100%',
    },
});

const StyledLabel = styled(Typography)({
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#000000",
    '&[data-error="true"]': {
        color: '#E04545',
    },
});
const StyledCloseIcon = styled(CloseOutlinedIcon)({
    position: "absolute",
    color: "#878787",
    fontSize: "24px",
    cursor: "pointer",
    zIndex: 5,
    top: 10,
    right: 10,
});

const StyledBackIcon = styled(ArrowBackIcon)({
    position: "absolute",
    color: "#878787",
    fontSize: "24px",
    cursor: "pointer",
    zIndex: 5,
    top: 10,
    left: 10,
});
const otpInputStyle: CSSProperties = {
    fontSize: '32px',
    fontWeight: 400,
    color: '#000000',
    fontFamily: "Open Sans",
    textAlign: 'center',
    padding: '0px',
    height: '57px',
    width: "100%",
    maxWidth: "47px",
    border: "1px solid black ",
    borderRadius: "8px"
};
const webStyles = {
    outerContainer: {
        background: "#FFFFFF",
        maxHeight: 'fit-content',
        width: "fit-content",
        borderRadius: "24px",
        margin: "10px",
        position: "relative",
        border: '10px solid #FFFFFF'
    },
    forgetPasswordImageContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    imageStyle: {
        objectFit: 'cover' as 'cover',
        objectPosition: "center",
        overflow: "hidden",
    },
    forgetPasswordHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.8vh",
        minHeight: "50px",
        height: "fit-content",
        width: "100%",
    },
    resetPasswordHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.8vh",
        minHeight: "50px",
        height: "fit-content",
        width: "100%",
    },
    boldHeader: {
        fontFamily: "Open Sans",
        fontSize: "22px",
        fontWeight: 700,
        color: "#000000",
        lineHeight: '29px',
        textAlign: "center",
    },
    boldSubHeader: {
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontWeight: 700,
        color: "#000000",
        lineHeight: '16px',
        textAlign: "center",
        letterSpacing: "inherit"
    },
    subHeaderDescription: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: 600,
        color: "#4D4D4D",
        lineHeight: '16px',
        textAlign: "center",
        letterSpacing: "inherit"
    },
    invalidOtp: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: 400,
        color: "#E04545",
        textAlign: "center",
    },
    continueButton: {
        color: "#ffff",
        background: "#202020",
        borderRadius: "8px",
        textTransform: "none",
        height: "42px",
        fontSize: "16px",
        fontWeight: "600",
        cursor: "pointer",
        fontFamily: "Open Sans",
        '&:hover': {
            background: "#202020",
        }
    },
    resendButton: {
        color: "#2D2D2D",
        borderRadius: "8px",
        border: '1px solid #000000',
        textTransform: "none",
        height: "40px",
        fontSize: "16px",
        fontWeight: "600",
        cursor: "pointer",
        fontFamily: "Open Sans",
        '&:hover': {
            background: "#dddcdcd1",
            border: '1px solid #000000',
        }
    },
    backToLogInMessage: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
        textAlign: "center",
        lineHeight: "19px",
        marginTop: '24px'
    },
}
//Customizable Area End