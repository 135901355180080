import React from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { darkFeatureIcon, whiteFeatureIcon } from "../assets";
const useStyles = makeStyles({
    cardContainer:{
        width:"327px",
        height:"481px",
        border:"3px solid #454545",
        borderRadius:'12px',
        '&:hover $color': {
            color:'white'
        },
        '&:hover $whiteFeatureIcon' :{
            display:"inline"
        },
        '&:hover $darkFeatureIcon' :{
            display:"none"
        },
        '&:hover $subscribeButton':{
            background:"white",
            color:"#000000",
        },
        '&:hover': {
            backgroundColor: '#1A1A1A',
            
        },
        '@media (max-width:449px)': {
            width:"290px",
            marginRight:'0px',
        },
    },
    planNameContainer:{
        display:"flex",
        fontFamily:"Open Sans",
        fontSize:"28px",
        fontWeight:700,
        padding:"20px"
    },
    imageContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"70px",
        borderTop:"1px solid  #D7D7D7",
        borderBottom:"1px solid  #D7D7D7"
    },
    priceContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"fit-content",
        gap:"10px",
        padding:'10px'
    },
    price:{
        fontSize:"36px",
        fontWeight:700,
        fontFamily:"Open Sans"
    },
    duration:{
        fontSize:"15px",
        fontWeight:600,
        fontFamily:"Open Sans"
    },
    features:{
        display:"flex",
        gap:"8px",
        height:"190px",
        flexDirection:"column",
        paddingRight:"20px",
        paddingLeft:"20px",
        overflowY:"auto",
    },
    feature:{
        display:"flex",
        justifyContent:"start",
        gap:"8px",
    },
    featureText:{
        color:"",
        fontFamily:"Open Sans",
        fontSize:"14px",
        fontWeight:400,
    },
    whiteFeatureIcon:{
        display:"none",
        height:"22px",
        width:"22px",
    },
    darkFeatureIcon:{
        height:"22px",
        width:"22px",
    },
    color:{
        color:"#000000"
    },
    subscribeButton:{
        borderRadius:"12px",
        background:"#202020",
        textTransform:"none",
        fontFamily:"Open Sans",
        margin:"20px",
        height:"42px",
        color:"white",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }
  });

interface Feature {
    id:number,
    description: string,
    subscription_id: number,
    created_at: string,
    updated_at: string
}
interface Props{
    name:string,
    amount:number,
    id:string,
    duration:string,
    image:string | null,
    features:Feature[],
    onSubscribe:(id:string)=>void
}

const SubscriptionCard:React.FC<Props> =({name, id, image, duration,amount, features, onSubscribe})=>{
    const classes = useStyles(); 
    const handleSubscribe = ()=>{
        onSubscribe(id)
    }
    return <Box className={classes.cardContainer}>
    <Box className={`${classes.planNameContainer} ${classes.color}`}>
      {name}
    </Box>
    <Box className={classes.imageContainer }>
       {image && <img src={image} height={'40px'} width='40px'/>}
    </Box>
    <Box className={classes.priceContainer}>
       <span className={`${classes.price} ${classes.color}`}>{`$${amount}`}</span>
       <Typography className={`${classes.duration} ${classes.color}`}>{`per ${duration}`}</Typography>
    </Box>
    <Box className={classes.features}>
       {features.map((feature, index)=>(<Box key={index} className={classes.feature}>
            <img src={darkFeatureIcon} className={classes.darkFeatureIcon} />
            <img src={whiteFeatureIcon} className={classes.whiteFeatureIcon} />
            <Typography className={`${classes.featureText} ${classes.color}`}>{feature.description}</Typography>
        </Box>))}
    </Box>
    <Box className={`${classes.subscribeButton}` } data-testid='subscribeButton' onClick={handleSubscribe}>
        Subscribe
    </Box>
  </Box>
}


export default SubscriptionCard