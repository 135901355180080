import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Modal,
  Paper,
  AvatarGroup,
  Avatar
} from '@mui/material';
import { deletesIcon, renameIcon, successIcon, workspaceButton } from './assets';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import MyWorkspacesController, { Props, configJSON } from './MyWorkspacesController.web';
import Loader from '../../../components/src/Loader.web';
// Customizable Area End

export default class MyWorkspaces extends MyWorkspacesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMyWorkspacesHeader = () => {
    const { searchWorkspace, sortWorkspace } = this.state;
    return (
      <WorkspacesHeaderBox>
        <WorkspacesHeading>
          <Typography className="pageHeading">{configJSON.myWorkspaces}</Typography>
          <Button className="newCampaignButton" data-testId="newWorkspaceBtnTestId" onClick={this.goToNewWorkspace} endIcon={<AddIcon className='heading_icon' />}>
            {configJSON.NewWorkspace}
          </Button>
        </WorkspacesHeading>
        <WorkspaceSearchContainer>
          <SearchWorkspaceField
            placeholder="Search"
            fullWidth
            data-testId="searchWorkspaceTestID"
            onChange={this.handleWorkspaceSearch}
            value={searchWorkspace}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <WorkspacesFormControl>
            <Typography className='sort_heading'>{configJSON.shortBy}</Typography>
            <WorkspaceSelect>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownTwoToneIcon}
                data-testId="sortWorkspaceTestID"
                onChange={this.handleWorkspaceSort}
                placeholder='Select'
                value={sortWorkspace}
                className='workspaces_sort_select'
                displayEmpty
                renderValue={(selected: string) => {
                  if (selected === "") {
                    return <Box className="place_workspaces_text">{"Select"}</Box>;
                  }
                  return configJSON.sortKey[selected];
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#F0F0F0 !important",
                      "& .Mui-selected": {
                        backgroundColor: "#D9D9D9 !important",
                      }
                    },
                  },
                }}
              >
                <MenuItem className='sort_Item' value="name_asc" >{configJSON.nameAZ}</MenuItem>
                <MenuItem className='sort_Item' value="name_desc">{configJSON.nameZA}</MenuItem>
                <MenuItem className='sort_Item' value="created_at_asc">{configJSON.dateLH}</MenuItem>
                <MenuItem className='sort_Item' value="created_at_desc">{configJSON.dateHL}</MenuItem>
              </Select>
            </WorkspaceSelect>
          </WorkspacesFormControl>
        </WorkspaceSearchContainer>
      </WorkspacesHeaderBox>)
  };

  renderWorkspaces = () => {
    const { workspaceData, openMenuIndex } = this.state;
    return (
      <WorkspaceCardBox>
        <Box className="cardsBox" >
          {workspaceData.map((item, index) => (
            <Box className="workspaceCard" key={index}
            >
              <Box className="titleBox">
                <Typography title={item.workspace.name} className='file_name'>{item.workspace.name}</Typography>
                <MoreVertIcon
                  className={openMenuIndex === index ? "moreButtonActive" : "moreButton"}
                  data-testId="moreWorkspaceTestID"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleWorkspaceMenu(index);
                  }}
                />
                {openMenuIndex === index && (
                  <Box className="moreMenu" >
                    <Button className="deleteBtn" data-testId="deleteBtnTestID"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.openDeleteWorkspace(item)
                      }}
                    >
                      <img src={deletesIcon} alt=" " />
                      <Typography data-testId="deleteBtnTextTestID">{configJSON.deleteText}</Typography>
                    </Button>
                    <Button className="renameBtn" data-testId="renameBtnTestID"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.openRenameWorkspace(item)
                      }} >
                      <img src={renameIcon} alt=" " />
                      <Typography data-testId="renameBtnTextTestID">{configJSON.renameText}</Typography>
                    </Button>
                  </Box>)}
              </Box>
              <WorkSpaceMainBox>
                <AvatarBox>
                  <AvatarGroup max={4}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                    <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                    <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                    <Avatar alt="Trevor Henderson" src="/static/images/avatar/6.jpg" />
                  </AvatarGroup>
                  <Typography className='total_employe'>{"5 employees"}</Typography>
                </AvatarBox>
                <AvatarButtonBox onClick={(event) => {
                  event.stopPropagation();
                  this.goToWorkspaceName(item)
                 }}
                  data-testId="WorkspaceNameBtnTestId">
                  <img src={workspaceButton} className='avatarButton' alt=""/>
                </AvatarButtonBox>
              </WorkSpaceMainBox>
            </Box>
          ))}
          {workspaceData.length === 0 && this.renderRecordNotFound()}
        </Box>
      </WorkspaceCardBox>
    )
  };


  renderDeleteWorkspace = () => {
    const { openDeleteModal, workspaceName } = this.state;
    return (
      <WorkspaceModalContainer
        open={openDeleteModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper">
          <IconButton
            onClick={this.closeDeleteWorkspace}
            data-testId="closeDeleteTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" data-testId="closeDeleteLabelTestID">
            {configJSON.deleteWorkspaceText}
          </Typography>
          <Typography variant="body2">{configJSON.workspaceDeletefirst}<span className='workspace_name'>{workspaceName+"?"}</span></Typography>
          <Typography variant="body2">{configJSON.workspaceDeletesecond}</Typography>
          <Box className="buttonBox">
            <Button className="cancleBtn" onClick={this.closeDeleteWorkspace}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="deleteBtn" data-testId="deleteWorkspaceTestID" onClick={this.handleDeleteWorkspaceData}>{configJSON.yesDeleteText}</Button>
          </Box>
        </Paper>
      </WorkspaceModalContainer>
    )
  };

  renderRenameWorkspace = () => {
    const { renameText } = this.state;
    return (
      <WorkspaceModalContainer
        open={this.state.openRenameModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className={this.state.openRenameModal ? "renamepaper paper" : "paper"}>
          <IconButton
            onClick={this.closeRenameWorkspace}
            data-testId="closeRenameTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" data-testId="closeRenameLabelTestID">
            {configJSON.renameWorkspaceText}
          </Typography>
          <RenameWorkspaceField
            value={renameText}
            onChange={(event) => { this.handleWorkspaceName(event) }}
            data-testId="renameWorkspaceTestID"
          />
          <Box className="buttonBox">
            <Button className="cancleBtn" onClick={this.closeRenameWorkspace}>
              {configJSON.cancelDeleteText}
            </Button>
            <Button className="deleteBtn"  data-testId="saveRenameTestID" onClick={this.handleRenameWorkspaceData} disabled={renameText === "" ? true : false} >{configJSON.saveText}</Button>
          </Box>
        </Paper>
      </WorkspaceModalContainer>
    )
  };

  renderSuccessWorkspace = () => {
    const { openSuccessModal } = this.state;
    return (
      <WorkspaceModalContainer
        open={openSuccessModal}
        onClose={this.closeSuccessWorkspace}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper className="paper_upload paper_success">
          <IconButton
            onClick={this.closeSuccessWorkspace}
            data-testId="deleleCloseIconTestID"
            className="closeIconBtn"
          >
            <CloseIcon />
          </IconButton>
          <WorkspaceHeadBox>
            <WorkspaceHeadTypo data-testId="deleleCloseLabelTestID">{configJSON.deleteSuccessText}</WorkspaceHeadTypo>
            <Typography className='workspaceDeleteWarn'>{configJSON.workspaceDeletesecond}</Typography>
            <img src={successIcon} className="succes_image" />
          </WorkspaceHeadBox>
        </Paper>
      </WorkspaceModalContainer>
    )
  };

  renderRecordNotFound = () => {
    return (
      <RecordNotFoundBox>
        <Typography className='record_content'>{configJSON.recordNotFound}</Typography>
      </RecordNotFoundBox>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    return (
      <MainContainer>
        <Loader loading={isLoading}/>
        {this.renderMyWorkspacesHeader()}
        {this.renderWorkspaces()}
        {this.renderDeleteWorkspace()}
        {this.renderRenameWorkspace()}
        {this.renderSuccessWorkspace()}
      </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "50px 24px",
  background: "#F6F6F6",
  minHeight: "100vh",
  '@media (max-width: 600px)': {
    padding: '15px',
  },
})

const WorkspacesHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const WorkspacesHeading = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "& .heading_icon": {
    fontSize: "16px"
  },
  "& .pageHeading": {
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  },

  "& .newCampaignButton": {
    textTransform: 'none',
    color: "#FFFFFF",
    ontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px 40px',
    background: '#000000',
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: '#000000',
    },
    '@media (max-width: 600px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 400px)': {
      width: "100%"
    },
  },

  '@media (max-width: 646px)': {
    alignItems: "start",
    justifyContent: "space-between",
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    alignItems: "center",
    justifyContent: "space-between",
  },
  '@media (max-width: 400px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const WorkspaceSearchContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  width: "100%",
  '@media (max-width: 754px)': {
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
  },
  '@media (max-width: 500px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const SearchWorkspaceField = styled(TextField)({
  width: "calc(100% - 220px)",
  "& .Mui-focused": {
    "& fieldset": {
      border: "1px solid #DDDDDD !important",
      "&:hover": {
        border: "1px solid #DDDDDD !important"
      }
    }
  },
  "& fieldset": {
    border: "1px solid #DDDDDD !important"
  },
  "&:hover": {
    border: "none"
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: "Open Sans",
    height: "48px",
    background: "white",
    '& fieldset': {
      border: "1px solid #DDDDDD",
      borderRadius: "8px",
    },
    '&.Mui-focused fieldset': {
      border: "1px solid #000000",
    },
    '&:hover fieldset': {
      borderColor: '#797979',
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
  },
  '& .MuiInputBase-input': {
    height: '100%',
    width: "100%"
  },
  '@media (max-width: 854px)': {
    width: "calc(100% - 170px)"
  },
  '@media (max-width: 500px)': {
    width: "100%"
  },
});

const RenameWorkspaceField = styled(TextField)({
  width: "100%",
  fontFamily: "Open Sans",
  "& .Mui-focused": {
    "& fieldset": {
      border: "none",
      "&:hover": {
        border: "none"
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    "& fieldset": {
      border: "1px solid #DDDDDD !important",
      borderRadius: "8px",
      padding: "4px",
    },
    "&:hover": {
      border: "none"
    },
  }
})

const WorkspaceSelect = styled(Box)({
  "& .workspaces_sort_select": {
    height: "48px",
    width: '202px',
    borderRadius: "8px",
    background:"#FFFFFF",
    "& .Mui-focused": {
      "& fieldset": {
        border: "1px solid #DDDDDD !important",
        "&:hover": {
          border: "1px solid #DDDDDD !important"
        }
      }
    },
    "& fieldset": {
      border: "1px solid #DDDDDD !important"
    },
    "&:hover": {
      border: "none"
    },
    '& .place_workspaces_text': {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      color: '#B3B3B3',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000000',
      borderSize: "1px",
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
    '@media (max-width: 854px)': {
      width: 'clamp(150px, 10vw, 202px)'
    },
    '@media (max-width: 754px)': {
      width: '220px'
    },
    '@media (max-width: 600px)': {
      width: "150px"
    },
    '@media (max-width: 500px)': {
      width: "94vw"
    },
    '@media (max-width: 450px)': {
      width: "92vw"
    },
    '@media (max-width: 400px)': {
      width: "91vw"
    },
    "& .sort_Item": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Open Sans",
      color: "#4D4D4D"
    }
  }
})

const WorkspacesFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: "column",
  gap: "4px",
  '& .MuiFormHelperText-root': {
    marginLeft: "0px",
    fontWight: 600
  },
  "& .sort_heading": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  }
});

const WorkspaceCardBox = styled(Box)({
  padding: "10px 0px",
  marginTop: "24px",
  "& .headBox": {
    display: "flex",
    padding: "10px 0px",
    width: "100%",
    gap: "10px",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "Open Sans"
    }
  },
  "& .cardsBox": {
    width: "100%",
    gap: "25px 15px",
    display: "flex",
    flexWrap: "wrap"
  },
  "& .workspaceCard": {
    background: "#FFFFFF",
    width: "260px",
    height: "170px",
    borderRadius: "8px",
    boxSizing: "border-box",
    border: "1px solid #DDDDDDDD",
    '@media (max-width: 580px)': {
      width: "260px",
    },
  },
  "& .imageBox": {
    height: "125px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    "& .library_img": {
      width: "100%",
      height: "100%",
      borderRadius: "8px",
    }
  },
  "& .titleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    padding: "24px 16px",
    boxSizing: "border-box",
    width: "100%",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px",
    },
    "& .file_name": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 600,
      lineHeight: "33px",
      fontSize: "24px",
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
  },
  "& .moreButton": {
    cursor: "pointer"
  },
  "& .moreButtonActive": {
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: "50%"
  },
  "& .moreMenu": {
    position: "absolute",
    zIndex: 1,
    top: 56,
    right: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiButton-root": {
      backgroundColor: "#fff",
      fontFamily: "Open Sans",
      textTransform: "none",
      color: "#4D4D4D",
      lineHeight: "21px",
      fontWeight: 400,
      gap: "10px",
      fontSize: "14px",
      width: "116px",
      height: "36px",
      boxSizing: "border-box",
      justifyContent: "flex-start",
      padding: "0px 16px",
      "& img": {
        width: "18px",
        height: "18px"
      }
    }
  },
  "& .deleteBtn": {
    borderTopRightRadius: "8px",
    border: "1px solid #DDDDDD",
    borderTopLeftRadius: "8px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  "& .renameBtn": {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    border: "1px solid #DDDDDD",
    borderTop: "none"
  },
  "& .playBtn": {
    position: "absolute",
    top: "40%",
    left: "45%",
    cursor: "pointer"
  }
});

const WorkspaceModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margin: "20px 0px",
  "& .paper": {
    maxHeight: 'fit-content',
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      lineHeight: "27px",
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiTypography-body2": {
      color: "#4D4D4D",
      lineHeight: "21px",
      fontWeight: 400,
      fontSize: "16px",
      maxWidth: "500px",
      textAlign: "center"
    },
    '@media (max-width: 375px)': {
      padding: "20px",
    },
  },
  "& .paper_success": {
    padding: "40px !important",
  },
  "& .paper_upload": {
    maxHeight: 'fit-content',
    width: "600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    '@media (max-width: 775px)': {
      width: "550px"
    },
    '@media (max-width: 675px)': {
      width: "500px"
    },
    '@media (max-width: 575px)': {
      width: "450px"
    },
    '@media (max-width: 475px)': {
      width: "350px"
    },
    '@media (max-width: 375px)': {
      width: "280px",
      padding: "20px",
    },
  },
  "& .renamepaper": {
    "& .MuiOutlinedInput-input": {
      padding: "4px !important",
      borderRadius: "8px",
      border: "1px solid #DDDDDD",
      width: "100%"
    }
  },
  "& .closeIconBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .buttonBox": {
    display: "flex",
    gap: "20px",
    padding: "20px 0px",
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px",
      '@media (max-width: 675px)': {
        width: "200px !important"
      },
      '@media (max-width: 575px)': {
        width: "180px !important"
      },
      '@media (max-width: 475px)': {
        width: "130px !important"
      },
      '@media (max-width: 375px)': {
        width: "100px !important",
        padding: "10px 5px"
      },
    },
    "& .cancleBtn": {
      color: "#000000",
      border: "1px solid #000000"
    },
    "& .deleteBtn": {
      color: "white",
      backgroundColor: "#000000"
    }
  },
  "& .workspace_name":{
    color: "#4D4D4D",
    lineHeight: "21.79px",
    fontWeight: 600,
    fontSize: "16px"
  }
});

const WorkspaceHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign: "center",
  fontSize: '20px',
  color: '#4D4D4D',
})

const WorkspaceHeadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: '100%',
  flexDirection: "column",
  alignItems: "center",
  "& .succes_image": {
    width: "80px",
    height: "80px",
    marginTop: "35px"
  },
  "& .workspaceDeleteWarn": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '16px',
    color: '#4D4D4D',
    marginTop: "16px"
  }
})

const RecordNotFoundBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .record_content": {
    color: '#B3B3B3',
    fontWeight: 600,
    fontSize: '20px',
    fontFamily: 'Open Sans',
    padding: "20px 0px"
  }
})

const WorkSpaceMainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 16px",
  marginTop: "20px",
  marginLeft: "8px"
})

const AvatarBox = styled(Box)({
  "& .MuiAvatar-root": {
    border: "0px !important",
    marginLeft: "-8px !important",
    width: "26px",
    height: "26px",
    fontSize: "10px",
    fontFamily: 'Open Sans',
  },
  "& .total_employe": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '12px',
    color: '#4D4D4D',
    marginLeft: "-8px !important",
  }
})

const AvatarButtonBox = styled(Box)({
  cursor: "pointer",
  "& .avatarButton": {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  }
})

// Customizable Area End 