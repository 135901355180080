import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  Breadcrumbs,
  Link as BreadcrumbItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from '@mui/material/Avatar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const notificationbell = require("./notificationbell.svg").default


import { styled } from '@mui/material/styles';
interface NavbarProps {
  handleopendrawer: () => void;
  fetchUserName: string
  fetchImageUrl:string
  currentRoute: string;
}

interface BreadcrumbItemType {
  name: string;
  path?: string;
}

const breadcrumbList: Record<string, BreadcrumbItemType[]> = {
  GenerateContent: [
    { name: "Content Creation", path: "" },
    { name: "Generate Content", path: "GenerateContent" }
  ],
  CustomisableUserProfileEdit: [
    { name: "My Profile", path: "CustomisableUserProfileEdit" },
  ],
  Dashboard: [
    { name: "Home", path: "Dashboard" },
  ],
  dashbored: [
    { name: "User Engagement Tools", path: "dashbored" },
  ],
  NewCommunityManager: [
    { name: "User Engagement Tools", path: "dashbored" },
    { name: "New Community Manager", path: "NewCommunityManager" },
  ],
  EditCommunityManager: [
    { name: "User Engagement Tools", path: "" },
    { name: "Edit Community Manager", path: "EditCommunityManager" },
  ],
  PhotosLibrary: [
    { name: "Content Creation", path: "" },
    { name: "My library", path: "PhotosLibrary" }
  ],
  Campaigns: [
    { name: "Content Creation", path: "" },
    { name: "My Campaigns", path: "Campaigns" }
  ],
  CampaignsResults: [
    { name: "Content Creation", path: "" },
    { name: "My Campaigns", path: "Campaigns" },
    { name: "Results", path: "CampaignsResults" }
  ],
  EditContent: [
    { name: "Content Creation", path: "" },
    { name: "My Campaigns", path: "Campaigns" },
    { name: "Results", path: "CampaignsResults" },
    { name: "Post name", path: "EditContent" }
  ],
  AddContent: [
    { name: "Content Creation", path: "" },
    { name: "My Campaigns", path: "Campaigns" },
    { name: "Add content", path: "AddContent" }
  ],
  seorecommendations: [
    { name: "Content Creation", path: "" },
    { name: "SEO Recommendations", path: "seorecommendations" }
  ],
  ConnectedAccounts: [
    { name: "Home", path: "/" },
    { name: "Settings", path: "" },
    { name: "Connected Accounts", path: "ConnectedAccounts" }
  ],
  Interactivefaqs: [
    { name: "Help Center", path: "" },
    { name: "FAQ", path: "Interactivefaqs" }
  ],
  Onboardingguide: [
    { name: "Help Center", path: "" },
    { name: "Tutorials", path: "Onboardingguide" }
  ],
  GuidedWalkthrough:  [
    { name: "Content Creation", path: "" },
    { name: "Generate Content", path: "GenerateContent" },
    { name: "Guided Walkthrough", path: "GuidedWalkthrough" }
  ],
  MyWorkspaces :  [
    { name: "My Business", path: "" },
    { name: "My Workspace", path: "MyWorkspaces" },
  ],
  NewWorkspace :  [
    { name: "My Business", path: "" },
    { name: "My Workspace", path: "MyWorkspaces" },
    { name: "New Workspace", path: "NewWorkspace" }
  ],
  WorkspaceName :  [
    { name: "My Business", path: "" },
    { name: "My Workspace", path: "MyWorkspaces" },
    { name: "Workspace name", path: "WorkspaceName" }
  ],
  CampaignWorkspace :  [
    { name: "My Business", path: "" },
    { name: "My Workspace", path: "MyWorkspaces" },
    { name: "Workspace name", path: "WorkspaceName" },
    { name: "Campaigns", path: "CampaignWorkspace" }
  ]
};

const Navbar: React.FC<NavbarProps> = ({ handleopendrawer ,fetchUserName,fetchImageUrl ,currentRoute}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbItemType[]>([]);

  const handleDrawerOpen = () => {
    handleopendrawer()
  };

  useEffect(() => {
    if (breadcrumbList[currentRoute]) {
      setBreadcrumbData(breadcrumbList[currentRoute]);
    }
  }, [currentRoute]);

  return (
    <Box>
      <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#FFFFFF' }}>
        <Toolbar >
          <IconButton
         
            edge="start"
            color="success"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <MenuIcon   style={{ color: 'black' }} />
          </IconButton>

          <HeaderBox> 
            <BreadcrumbsContainer>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small"/>}
              aria-label="breadcrumb"
            >
              {
                breadcrumbData.map((item, index) => {
                  const isLastItem = index === breadcrumbData.length - 1;
                  return (
                    <BreadcrumbItem
                      className="breadcumItem"
                      underline="hover"
                      key={index}
                      color={isLastItem ? "#404040" : "#909090"}
                      href={item.path} 
                      style={{textDecoration: isLastItem ? 'underline' : 'none'}}
                    >
                      {item.name}
                    </BreadcrumbItem>
                  );
                })
              }
            </Breadcrumbs>
            </BreadcrumbsContainer>

            <Box display={'flex'} gap={2}>

              <StyledMenuTypo
              >
                <Box>

                  <img src={notificationbell} alt="avtar" style={{ marginTop: '2px' }} />
                </Box>

              </StyledMenuTypo>
              <StyledMenuTypo
              >
                Hi,{fetchUserName ? fetchUserName : "User Name"}
              </StyledMenuTypo>
              <Avatar src={fetchImageUrl ? fetchImageUrl : "/broken-image.jpg"} />

            </Box>

          </HeaderBox>
        </Toolbar>
      </AppBar>
      <BreadcrumbsBox>
      <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small"/>}
              aria-label="breadcrumb"
            >
              {
                breadcrumbData.map((item, index) => {
                  const isLastItem = index === breadcrumbData.length - 1;
                  return (
                    <BreadcrumbItem
                      className="breadcumItem"
                      underline="hover"
                      key={index}
                      color={isLastItem ? "#404040" : "#909090"}
                      href={item.path} 
                      style={{textDecoration: isLastItem ? 'underline' : 'none'}}
                    >
                      {item.name}
                    </BreadcrumbItem>
                  );
                })
              }
            </Breadcrumbs>
      </BreadcrumbsBox>
      <Drawer
        anchor="top"
        open={drawerOpen}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
      >

      </Drawer>
    </Box>
  );
};

export default Navbar;
const StyledMenuTypo = styled(Typography)({

  fontFamily: 'Open Sans',
  margin: "2px",
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '18px',
  color: '#000000',

});


const HeaderBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  '@media (max-width: 800px)': {
    justifyContent: "end",
  },
  "& .breadcumItem":{
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '16px',
    '@media (max-width: 800px)': {
      fontSize: '14px',
    },
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
  }
})

const BreadcrumbsContainer = styled(Box)({
    display: "block",
  '@media (max-width: 800px)': {
    display: "none",
  },
})

const BreadcrumbsBox = styled(Box)({
  display: "none",
  "& .MuiBreadcrumbs-ol":{
    flexWrap: "nowrap !important",
  },
  "& .MuiBreadcrumbs-root":{
    padding: "10px 15px",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  '@media (max-width: 800px)': {
    display: "block",
  },
  "& .breadcumItem":{
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '16px',
    textWrap: 'nowrap',
    '@media (max-width: 800px)': {
      fontSize: '14px',
    },
    '@media (max-width: 600px)': {
      fontSize: '13px',
    },
  }
})



