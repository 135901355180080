import React, { CSSProperties } from 'react';
import { Box, Typography ,Link as Upload} from '@mui/material';
import { pdfIcon, pdfCrossIcon, pdfDownloadIcon } from '../../blocks/dashboard/src/assets';

interface WebStyles {
    h5: CSSProperties;
    dotedBox: CSSProperties;
    uploadFileIcon: CSSProperties;
    t14: CSSProperties;
    browseFileTxt: CSSProperties;
    hiddenInput: CSSProperties;
    fileList: CSSProperties;
    fileItem: CSSProperties;
    fileIcon: CSSProperties;
    fileName: CSSProperties;
    removeFile: CSSProperties;
    downloadIcon: CSSProperties;
    addFiles: CSSProperties;
}

interface FileUploadProps {
    title: string;
    fileList: File[];
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveFile: (index: number) => void;
    uploadFileIcon: string;
    inputId: string;
    webStyles: WebStyles;
}

const FileUpload: React.FC<FileUploadProps> = ({
    title,
    fileList,
    onFileChange,
    onRemoveFile,
    uploadFileIcon,
    inputId,
    webStyles
}) => {
    return (
        <Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                <Typography sx={webStyles.h5}>{title}</Typography>
                {fileList.length > 0 && (
                    <label htmlFor={inputId}>
                        <Typography style={webStyles.addFiles}>Add Files</Typography>
                    </label>
                )}
            </Box>
            {fileList.length <= 0 && (
                <label style={webStyles.dotedBox} htmlFor={inputId}>
                    <img style={webStyles.uploadFileIcon} src={uploadFileIcon} alt="Upload Icon" />
                    <Typography sx={webStyles.h5} style={{ fontWeight: 400 }}>Choose a file or drag & drop it here</Typography>
                    <Typography sx={webStyles.t14}>PDF formats, up to 100MB</Typography>
                    <Typography style={webStyles.browseFileTxt}>Browse File</Typography>
                </label>
            )}
            <input
                id={inputId}
                type="file"
                accept=".pdf"
                multiple
                style={webStyles.hiddenInput}
                onChange={onFileChange}
                data-test-id='handleGuidelineFileChange'
            />
            <div style={webStyles.fileList}>
                {fileList.map((file, index) => (
                    <div key={index} style={webStyles.fileItem}>
                        <img src={pdfIcon} alt="PDF icon" style={webStyles.fileIcon} />
                        <Upload href={URL.createObjectURL(file)} download style={webStyles.fileName}>
                            {file.name}
                        </Upload>
                        <span style={webStyles.removeFile} onClick={() => onRemoveFile(index)}>
                            <img src={pdfCrossIcon} alt="Remove file" />
                        </span>
                        <Upload href={URL.createObjectURL(file)} download style={webStyles.downloadIcon}>
                            <img src={pdfDownloadIcon} alt="Download file" />
                        </Upload>
                    </div>
                ))}
            </div>
        </Box>
    );
};

export default FileUpload;
