Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmarkyaiapiintegration";
exports.labelBodyText = "cfmarkyaiapiintegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.seoRecommendations = "SEO Recommendations";
exports.exampleContent = "Want to improve your website's SEO? Simply share your URL with us, and we'll provide you with personalized recommendations to enhance your online presence.";
exports.checkSeo = "Check SEO";
exports.submitYourURL = "Submit Your URL";
exports.usefulContent = "Some useful starting points";
exports.seoQuickTips = "SEO Quick Tips";
exports.seoBestPractices = "SEO Best Practices";
exports.learnSeoBasics = "Learn SEO Basics";
exports.seoStrategies = "SEO Strategies";
exports.ApiContentTypeJson="application/json";
// Customizable Area End