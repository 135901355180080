import React from "react";

import {
  Container,
  Box,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {loader} from './assets'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import VideoBackgroundsController, {
  Props,
  configJSON,
} from "./VideoBackgroundsController.web";

export default class VideoBackgrounds extends VideoBackgroundsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    await this.getVideo()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BackgroundVideoWrapper data-testid='videoContainer'>
          <div style={{ position: 'absolute', left:0, top:0, width:'100%', height:'100%', zIndex: -1 ,}}>
           {!!this.state.video &&
            <Video autoPlay muted loop data-test-id="backgroundVideo">
              <source src={this.state.video} type="video/mp4" />
            </Video> }
          </div>     
          <div style={{ position: 'absolute', left:0, top:0, display:"flex", flexDirection:"column", width:'100%', height:'100vh', zIndex:1, scrollbarWidth:"none",}}>
            {this.props.children}
          </div>
        </BackgroundVideoWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BackgroundVideoWrapper = styled('div')({
  position: 'fixed',
  width: '100%',
  height: '100%',
});

const Video = styled('video')({
  width: "inherit",
  height: '100%',
  objectFit: 'fill'
});

const Content = styled('div')({
  position: 'relative',
  zIndex: 1,
  color: 'white',
  textAlign: 'center',
  padding: '20px',
  overflow:"scroll",
  scrollbarWidth: "none",
  height:"100%"
});
// Customizable Area End
