import {
    Box,
    TextField,
    Typography,
    Button,
    FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)<{height?:string}>(({height})=> ({
    '& .MuiOutlinedInput-root': {
     fontFamily: "Open Sans",
      height: height ? height:"48px",
      borderRadius: "8px",
      '& fieldset': {
        borderColor: '#6F6F6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F6F6F',
      },
      '&:hover fieldset': {
        borderColor: '#6F6F6F',
      },
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root':{
        '&.Mui-focused fieldset': {
            borderColor: '#E04545',
          },
          '&:hover fieldset': {
            borderColor: '#E04545',
          },
          '& fieldset': {
            borderColor:'#E04545',
          },
    },
    '& .MuiInputBase-input': {
      height: '100%', 
    },
}));

export const StyledOuterContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    width: "fit-content",
    borderRadius: "24px",
    padding: "30px",
    overflowY: "auto",
    '@media (min-height: 616px)': {
        maxHeight: 'fit-content',
    },
    '@media (max-height: 615px)': {
        maxHeight: '82vh',
    },
    '@media (max-height: 530px)': {
        maxHeight: '80vh',
    },
    '@media (max-height: 430px)': {
        maxHeight: '76vh',
    },
    '@media (max-height: 350px)': {
        maxHeight: '71vh',
    },
    '@media (max-height: 290px)': {
        maxHeight: '65vh',
    },
    '@media (max-height: 250px)': {
        maxHeight: '50vh',
    },
    '@media (max-height: 200px)': {
        maxHeight: '40vh',
    },
    '@media (max-height: 100px)': {
        maxHeight: '25vh',
    },
    "::-webkit-scrollbar": {
        display: 'none',
    },
    "-ms-overflow-style": 'none',
    "scrollbar-width": 'none'

});

export const EditableContainer = styled(Box)<{isActive:boolean}>(({ isActive }) => ({
    borderRadius:"12px",
    display:"flex",
    flexDirection:"column",
    gap:"16px",
    background: isActive ?"#212121":"transparent",
}));

export const StyledProfileTextField = styled(TextField) ({
    width:"100%",
    '& .MuiOutlinedInput-root': {
     fontFamily: "Open Sans",
      height: "48px",
      with:"100%",
      background:"white",
      '& fieldset': {
        border:"none",
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',
      },
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root':{
        '&.Mui-focused fieldset': {
            borderColor: '#E04545',
          },
          '&:hover fieldset': {
            borderColor: '#E04545',
          },
          '& fieldset': {
            borderColor:'#E04545',
          },
    },
    '& .MuiInputBase-input': {
      height: '100%',
      width:"100%" 
    },
});

export const StyledProfileFieldLabel = styled(Typography)<{isActive:boolean}>(({ isActive }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '16px',
    color: isActive?"#FFFFFF":'#686868',
    '&[data-error="true"]':{
        color: '#E04545',
    },
}));

export const StyledProfileSectionHeader = styled(Typography)<{isActive:boolean}>(({ isActive }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '18px',
    color: isActive?"#FFFFFF":'#000000',
}));

export const StyledProfileImageContainer = styled(Box)<{isActive:boolean}>(({ isActive })=> ({
    display: 'flex',
    justifyContent:'center',
    alignItems:"center",
    border:"10px",
    borderStyle:"solid",
    height:'clamp(60px, 11.3vw, 150px)',
    width:'clamp(60px, 11.3vw, 150px)',
    borderRadius:"50%",
    position:"absolute",
    background: isActive?"#F4F4F4":"#212121",
    borderColor:isActive?"#212121":"#F4F4F4",
}));

export const StyledProfilePageHeading = styled(Box)<{isActive:boolean}>(({ isActive }) =>({
    display:"flex",
    width:"100%",
    gap:"clamp(15px, 8vw, 24px)",
    alignItems:"center",
    background: isActive?"#212121":"#F4F4F4",
    height:'clamp(50px, 10vw, 140px)',
    marginLeft:'clamp(50px, 7vw,100px)',
    padding:'10px 10px 10px clamp(50px, 7vw, 100px)',
    borderRadius:"12px",
    '@media (max-width: 650px)': {
        marginLeft:'clamp(40px, 8vw,80px)',
        paddingLeft:'clamp(40px, 8vw, 80px)',
    },
    '@media (max-width: 422px)': {
        fontSize:'clamp(19px, 4vw, 24px)'
    }
}));

export const ProfileUserNameTypo = styled(Typography)<{isActive:boolean}>(({ isActive }) =>({
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 'clamp(24px, 4vw, 42px)',
    color: isActive? "#FFFFFF":'#000000',
    width:"fit-content",
    margin:"0px",
    wordSpacing:"2px",
    '@media (max-width: 422px)': {
        fontSize:'clamp(19px, 4vw, 24px)'
    }
}))

export const ProfileStyledEditButton = styled(Button)<{isActive:boolean}>(({ isActive }) =>({
    textTransform: 'none',
    border: '1px',
    borderStyle:"solid",
    borderColor: isActive ? "#FFFFFF":"#000000",
    color: isActive? "#FFFFFF":'#000000',
    padding:'0px',
    "&:hover":{
        borderColor:isActive?"#FFFFFF":'#000000',
    }
}))

export const StyledFormControl = styled(FormControl)<{isRightAlignText?:boolean}>(({ isRightAlignText }) =>({
    display: 'flex',
    flexDirection: "column",
    gap: "4px",
    '& .MuiFormHelperText-root': {
      margin:"0px",
      fontWight:600,
      textAlign:isRightAlignText?'right':"left"
    },
}));

export const BackToMainPageButton = styled(Button) ({
    fontFamily:"Open Sans",
    textTransform:"none",
    borderRadius:"8px",
    background:"#202020",
    color:"#FFFFFF",
    padding:"6px 24px 6px 24px",
    '&:hover': {
      backgroundColor: "#202020",
    },
    '@media (max-width: 400px)': {
      width:'250px'
    },
});

export const StyledContainerWithScrollBar = styled(Box)({
    display: 'flex',
    flexDirection:"column",
    maxHeight: '65vh',
    overflowY: 'scroll',
    marginRight:"70px",
    paddingRight:"30px",
    gap:"24px",
    '@media (max-width: 600px)': {
      paddingRight: '30px',
      marginRight:"0px",
      width:'clamp(250px, 70vw, 500px)'
    },
    '@media (max-width: 350px)': {
      paddingRight: '30px',
      width:'clamp(250px, 50vw, 340px)'
    },
    '@media (max-height:1000px)': {
        maxHeight: '63vh',
    },
    '@media (max-height:862px)': {
      maxHeight: '61vh',
    },
    '@media (max-height: 823px)': {
      maxHeight: '55vh',
    },
    '@media (max-height: 650px)': {
      maxHeight: '48vh',
    },
    '@media (max-height: 570px)': {
        maxHeight: '44vh',
    },
    '@media (max-height: 519px)': {
        maxHeight: '38vh',
    },
    '@media (max-height: 475px)': {
        maxHeight: '32vh',
    },
    scrollbarColor: '#3E3E3E  #ECECEC',
});
export const StyledContainerWithLeftMargin = styled(Box)({
    display: 'flex',
    flexDirection:"column",
    paddingLeft: '150px',
    gap:"24px",
    marginBottom:"24px",
    '@media (max-width: 768px)': {
        paddingLeft: '100px',
    },
    '@media (max-width: 600px)': {
      padding:"0px",
      alignItems:"center",
    },
})


export const StyledDateContainer = styled(Box)({
  display: 'flex',
  gap: '12px',
  justifyContent: "space-between",
  '@media (max-width: 1370px)': {
      flexDirection: "column",
  },
  '@media (max-width: 1190px)': {
      flexDirection: "row",
  },
  '@media (max-width: 689px)': {
      flexDirection: "column",
  }
})