export const centerlogo =   require("../assets/CenterDAtailogo.svg").default;
export const Aibottumimg =   require("../assets/BottomAILogo.svg").default;
export const facebooklogo =   require("../assets/Facebook.svg").default;
export const instagramlogo =   require("../assets/Instagramlogo.svg").default;
export const twitterlogo =   require("../assets/Twitterlogo.svg").default;
export const tiktoklogo =   require("../assets/Ticktoklogo.svg").default;
export const magnifylicon =   require("../assets/Magnifylogo.svg").default;

export const bgwhiteAibottumimg =   require("../assets/bgwhiteAibottom.svg").default;
export const bgwhitefacebooklogo =   require("../assets/bgwhitefacebook.svg").default;
export const bgwhiteinstagramlogo =   require("../assets/bgwhiteinsta.svg").default;
export const bgwhitetwitterlogo =   require("../assets/bgwhitetwitter.svg").default;
export const bgwhitetiktoklogo =   require("../assets/bgwhiteticktok.svg").default;

export const samplevideo =   require("../assets/sample_video.mp4").default;
export const signatureimage =   require("../assets/gsignature.png").default;

export const signatureview =   require("../assets/singnatureview_.svg").default;

export const VolumeIcon=require("../assets/Volumeicon_.svg").default;
export const CopyICon=require("../assets/copypbutton.svg").default;
export const ReloadIcon=require("../assets/Reloadbutton.svg").default;
export const DislikeIcon=require("../assets/dislikebutton.svg").default;
export const Loader=require("../assets/Loader.svg").default;
export const buttondesign=require("../assets/buttondesignicon.svg").default;


export const sendicon=require("../assets/image_send.svg").default;

export const userEnagement = require("../assets/user_engagement_tool.png").default;
export const tailormade = require("../assets/tailormade.png").default;
export const servicesMain = require("../assets/serives_main_img.png").default;
export const realTime = require("../assets/real_time.png").default;
export const contentCreation = require("../assets/content_creation.png").default;
